import axios_base_config_site from '../../config/api-config';

const state = () => ({
  markers: [],
  customers: [],
  depots: [],
  checkedMarkers: [],
  location: {
    latitude: 0,
    longitude: 0,
  },
  markerToBeDeletedId: '',
  markerToBeUpdated: {},
  showDeleteAssetPrompt: false,
  showUpdateMarkerModal: false,
  markerFormAction: '',
});

const getters = {
  getAllMarkers: (state) => state.markers,
  getAllCustomers: (state) => state.customers,
  getLogisticsDepots: (state) => state.depots,
  getCheckedMarkers: (state) => state.checkedMarkers,
  getClickedLocation: (state) => state.location,
  getMarkerId: (state) => state.markerToBeDeletedId,
  getDeleteAssetPromptStatus: (state) => state.showDeleteAssetPrompt,
  getUpdateMarkerModalStatus: (state) => state.showUpdateMarkerModal,
  getAssetToBeUpdated: (state) => state.markerToBeUpdated,
  getMarkerFormAction: (state) => state.markerFormAction,
};

const mutations = {

  saveAllMarkers(state, markers) {
    state.markers = markers;
  },

  saveAllCustomers(state, customers) {
    state.customers = customers;
  },
  saveAllLogisticsDepots(state, depots) {
    state.depots = depots;
  },

  saveCheckedMarkers(state, checkedMarkers) {
    state.checkedMarkers = checkedMarkers;
  },

  saveClickedLocation(state, coordinate) {
    state.location = coordinate;
  },

  setMarkerId(state, markerId) {
    state.markerToBeDeletedId = markerId;
  },

  setDeleteAssetModalStatus(state, status) {
    state.showDeleteAssetPrompt = status;
  },

  setUpdateMarkerModalStatus(state, status) {
    state.showUpdateMarkerModal = status;
  },

  setAssetToBeUpdated(state, asset) {
    state.markerToBeUpdated = asset;
  },

  setMarkerFormAction(state, action) {
    state.markerFormAction = action;
  },
};

const actions = {
  async fetchMarkers({ commit }) {
    try {


      const resp = await axios_base_config_site.get('/api/marker-assets');

      const markers = resp.data;

      await commit('saveAllMarkers', markers);

      return markers;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the markers');
    }
  },

  async fetchFactories({ commit }) {
    try {

      const resp = await axios_base_config_site.get('/api/marker-assets/filterJSON?markerType=Factory');

      const factories = resp.data;

      await commit('saveAllMarkers', factories);

      return factories;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the factories');
    }
  },

  async fetchSingleFactory({ commit }, _id) {
    try {

      const resp = await axios_base_config_site.get(`/api/marker-assets/${_id}`);

      const factory = resp.data;


      await commit('saveAllMarkers', factory);

      return factory;
    } 
    catch (error) {
      console.log(error);
      console.log('error in fetching the factories');
    }
  },


  async fetchAps({ commit }) {
    try {

      const resp = await axios_base_config_site.get('/api/marker-assets/filterJSON?markerType=AP');

      const ap = resp.data;

      await commit('saveAllMarkers', ap);

      return ap;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },

  async addMarker(_, data) {
    try {

      const resp = await axios_base_config_site.post('/api/marker-assets', data);
      return resp;

      
    } catch (error) {
      console.log(error);
      console.log('error in adding a marker');
    }
  },

  // Customer factories
  async addCustomer(_, data) {
    try {
      const resp = await axios_base_config_site.post('/api/customers', data);
      return resp;

    } catch (error) {
      console.log(error);
      console.log('error in adding a Customer');
    }
  },

  async fetchCustomers({ commit }) {
    try {

      const resp = await axios_base_config_site.get('/api/customers/filterJSON');

      const customers = resp.data;

      await commit('saveAllCustomers', customers);

      return customers;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },

  async deleteCustomer(_, markerId) {
    try {
      
      const resp = await axios_base_config_site.delete(`/api/customers/${markerId}`);
      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in deleting a marker');
    }
  },

  async updateCustomer(_,  data) {
    try {
      const resp =  await axios_base_config_site.put(`/api/customers/${data.id}`, data);

      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in updating a marker');
    }
  },

  async fetchSingleCustomerFactory({ commit }, _id) {
    try {

      const resp = await axios_base_config_site.get(`/api/customers/${_id}`);

      const customers = resp.data;

      await commit('saveAllCustomers', customers);

      return customers;
    } 
    catch (error) {
      console.log(error);
      console.log('error in fetching the factories');
    }
  },

  //Logistics Depot Management
  async addLogisticsDepot(_, data) {
    try {
      const resp = await axios_base_config_site.post('/api/LogisticDepot', data);
      return resp;

    } catch (error) {
      console.log(error);
      console.log('error in adding a Customer');
    }
  },
  async fetchLogisticsDepots({ commit }) {
    try {

      const resp = await axios_base_config_site.get('/api/LogisticDepot');

      const depots = resp.data.features;

      const mappedDepots = depots.map((depot)=>depot.properties);
      // console.log(mappedDepots);

      await commit('saveAllLogisticsDepots', mappedDepots);

      return depots;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },
  async fetchLogisticsDepotsForDelete() {
    try {

      const resp = await axios_base_config_site.get('/api/LogisticDepot');
      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },
  async deleteLogisticsDepot(_, markerId) {
    try {
      const resp = await axios_base_config_site.delete(`/api/LogisticDepot/${markerId}`);
      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in deleting a marker');
    }
  },

  async updateLogisticsDepot(_,  data) {
    try {
      const resp =  await axios_base_config_site.put(`/api/LogisticDepot/${data.id}`, data);
      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in updating a marker');
    }
  },

  async fetchSingleLogisticsDepot({ commit }, _id) {
    try {
      const resp = await axios_base_config_site.get(`/api/LogisticDepot/${_id}`);
      console.log(resp)
      const depot = resp.data;

      await commit('saveAllLogisticsDepots', depot);

      return depot;
    } 
    catch (error) {
      console.log(error);
      console.log('error in fetching the factories');
    }
  },

  //marker-assets
  async updateMarker(_,  data) {
    try {
      const resp =  await axios_base_config_site.put(`/api/marker-assets/${data.id}`, data);

      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in updating a marker');
    }
  },

  async deleteMarker(_, markerId) {
    try {
      const resp = await axios_base_config_site.delete(`/api/marker-assets/${markerId}`);
      return resp;
    } catch (error) {
      console.log(error);
      console.log('error in deleting a marker');
    }
  },

  updateCheckedMarkers({ commit }, checkedMarkers) {
    commit('saveCheckedMarkers', checkedMarkers);
  },

  retrieveClickedLocation({ commit }, coordinate) {
    commit('saveClickedLocation', coordinate);
  },

  setDeleteAssetPromptStatus({ commit }, status) {
    commit('setDeleteAssetModalStatus', status);
  },

  setUpdateMarkerModalStatus({ commit }, status) {
    commit('setUpdateMarkerModalStatus', status);
  },

  setMarkerId({ commit }, payload) {
    const { markerId, status } = payload;
    commit('setMarkerId', markerId);
    commit('setDeleteAssetModalStatus', status);
  },

  setAssetToBeUpdated({ commit }, data) {
    commit('setAssetToBeUpdated', data);
  },

  setMarkerFormAction({ commit }, action) {
    commit('setMarkerFormAction', action);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};