import {logisticsDepotURL} from "@/config/api-config";

const logisticsDepotLayer = {
    url: logisticsDepotURL,
    title: "Logistics Depot",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-marker",
            size: 15,
            color: "#38a3a5",
            outline: {
                color: "rgba(255, 240, 204, 0.5)",
                width: 2,
            },
        },
    },
    popupTemplate: {
        title: "Depot name - {name}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "name",
                        label: "Depot Name",
                        visible: true,
                    },
                    {
                        fieldName: "latitude",
                        label: "Latitude",
                        visible: true,
                    },
                    {
                        fieldName: "longitude",
                        label: "Longitude",
                        visible: true,
                    },
                    {
                        fieldName: "gas",
                        label: "Gas",
                        visible: true,
                    },
                    {
                        fieldName: "status",
                        label: "Status",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default logisticsDepotLayer;

