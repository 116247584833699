import axios from 'axios';
import {BASE_URL_SALT} from "@/config/api-config";

const state = {
    inventoriesSummaryPerAgent: [],
    inventoriesInField: [],
    inventoriesSummaryPerAgentLoading: false,
    inventoriesSummaryPerAgentError: false,
};

const getters = {
    inventoriesSummaryPerAgent: (state) => state.inventoriesSummaryPerAgent,
    inventoriesInField: (state) => state.inventoriesInField,
    currentUser: (state) => state.currentUser,

};

const mutations = {

    setInventoriesSummaryPerAgent(state, data) {
        state.inventoriesSummaryPerAgent = data;
    },

    setInventoriesInField(state, data) {
        state.inventoriesInField = data;
    },

};

const actions = {

    async fetchInventorySummaryPerAgent({commit}) {
        try {
            const response = await axios.get(BASE_URL_SALT + "/inventories/inventory-at-mills-by-agents");
            commit('setInventoriesSummaryPerAgent', response.data);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async fetchInventoryInField({commit}) {
        try {
            const response = await axios.get(BASE_URL_SALT + "/inventories/inventory-at-mills");
            commit('setInventoriesInField', response.data);
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

