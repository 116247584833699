import {arcgisURL} from "@/config/api-config"



const buildingFootprintLayer = {
    title: "Roof Footprint",
    url: arcgisURL + "nigeria200m/FeatureServer",
    opacity: 0.5,
    copyright: "",
    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            outline: {
                color: "green",
                width: 2,
            },
        },
    },
    popupTemplate: {
        title: "Roof Footprint - {area_in_me} m²",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "area_in_me",
                        label: "area (m²)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "longitude",
                        label: "Longitude",
                        visible: true,
                    },
                    {
                        fieldName: "latitude",
                        label: "Latitude",
                        visible: true,
                    },
                    {
                        fieldName: "confidence",
                        label: "Confidence ",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default buildingFootprintLayer;

