const axios = require('axios');

// initial state
const state = () => ({
  all: {},
});

// getters
const getters = {
  getCassavaGeoJSON: (state) => state.all,
};

// actions
const actions = {
  async getCassavaData({ commit }, fileUrl) {
    try {
      const response = await axios.get(fileUrl);
      commit('setCassavaData', response.data);
      return response.data;
    } catch (e) {
      console.log('Error fetching Cassava data:', e.message);
    }
  },
};

// mutations
const mutations = {
  setCassavaData(state, data) {
    state.all = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
