import {wardTaggedMillRenderer} from "@/views/mills/data/rendererWardLayers";
import {arcgisURL} from "@/config/api-config";

const wardTaggedMillsLayer = {
    title: "Number of Tagged Mills",
    url: arcgisURL + "wards/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: wardTaggedMillRenderer,
    popupTemplate: {
        title: "{wardname}, {lganame} LGA",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "wardname",
                        label: "Ward Name",
                        visible: true,
                    },
                    {
                        fieldName: "comm",
                        label: "Name of Communities",
                        visible: true,
                    },
                    {
                        fieldName: "F_id_count",
                        label: "Number of Tagged Mills",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default wardTaggedMillsLayer;

