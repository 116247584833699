import Graphic from "@arcgis/core/Graphic";

export const showMillMarker = (mill, mapView) => {
  const totalPrice = mill.totalPrice;
  const tonsAmount = mill.tonsAmount;

  const point = {
    type: "point",
    longitude: mill.location.longitude,
    latitude: mill.location.latitude,
  };
  const simpleMarkerSymbol = {
    type: "simple-marker",
    color: [226, 119, 40], // orange
    outline: {
      color: [255, 255, 255], // white
      width: 1,
    },
  };
  //lgaSlugToName
  const attributes = {
    name: mill.name,
    size: mill.size,
    totalPrice: totalPrice,
    tonsAmount: tonsAmount,
    noOfTransactions: mill.noOfTransactions ? mill.noOfTransactions : 0,
    lga: mill.lga_slug ? mill.lga_slug : "Nil",
  };
  const popupTemplate = {
    title: "Mill Details",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "name",
            label: "Mill Owner",
            visible: true,
          },
          {
            fieldName: "size",
            label: "Mill Size",
            visible: true,
          },
          {
            fieldName: "totalPrice",
            label: "Total Amount (₦)",
            visible: true,
          },
          {
            fieldName: "tonsAmount",
            label: "Total Tonnes Bought",
            visible: true,
          },
          {
            fieldName: "noOfTransactions",
            label: "Total Transactions",
            visible: true,
          },
          {
            fieldName: "lga",
            label: "LGA",
            visible: true,
          },
        ],
      },
    ],
  };

  const pointGraphic = new Graphic({
    geometry: point,
    symbol: simpleMarkerSymbol,
    attributes: attributes,
    popupTemplate: popupTemplate,
  });

  mapView.graphics.add(pointGraphic);
};

export const getMatchByCommunity = (mills, prices) => {
  const pricesArray = Object.keys(prices);

  const mill = mills.find((mill) => {
    return Boolean(pricesArray.find((el) => el === mill.community_slug));
  });

  return mill?.community_slug;
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getMostOccurringLGA = (mills) => {
  const map = {};

  mills.forEach((mill) => {
    const lga = mill.lga_slug;
    if (lga !== undefined) {
      if (map[lga]) {
        map[lga] += 1;
      } else {
        map[lga] = 1;
      }
    }
  });

  const highestValue = Math.max(...Object.values(map));

  return getKeyByValue(map, highestValue);
};