import {arcgisURL} from "@/config/api-config"

const renderer = {
    type: "unique-value",
    legendOptions: {
      title: "Key Conservation Areas (HCV)"
    },
    field: "gridcode",
    uniqueValueInfos: [
      {
        value: 4,
        label: "HCV 4",
        symbol: {
          type: "simple-fill",
          color: "#f94144",
          width: "6px",
          style: "solid"
        }
      },
      {
        value: 3,
        label: "HCV 3",
        symbol: {
          type: "simple-fill",
          color: "#f3722c",
          width: "6px",
          style: "solid"
        }
      },
      {
        value: 2,
        label: "HCV 2",
        symbol: {
          type: "simple-fill",
          color: "#f8961e",
          width: "6px",
          style: "solid"
        }
      },
      {
      value: 1,
      label: "HCV 1",
      symbol: {
        type: "simple-fill",
        color: "#f9c74f",
        width: "6px",
        style: "solid"
      }
    },
    
    
    
    
  ]
};

const hcvaLayer = {
    title: "Key Conservation Areas (HCV)",
    url: arcgisURL + "/HCVA/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: renderer,
    popupTemplate: {
        title: "Key Conservation Areas (HCV)",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "hcv",
                        label: "High Conservation Value",
                        visible: true,
                    },
                ],
            },
        ],
    },
}
export default hcvaLayer;

