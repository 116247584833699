<template>
  <div v-if="loading">
    <b-spinner
      v-if="loading"
      style="width: 5rem; height: 5rem"
      label="Large Spinner"
      class="spinner"
      type="grow"
    >
    </b-spinner>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.spinner {
  position: absolute;
  top: 35%;
  left: 47%;
  z-index: 9999;
  color: #669933;
}
</style>
