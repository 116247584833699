
const tourSteps = [
    {
        target: "#v-step-0",
        header: {
            title: "Welcome!",
        },
        content: `Explore Site in easy steps <br><strong>Take a quick tour.</strong>`,
    },
    {
        target: '[data-v-step="1"]',
        content: "Switch between map views.",
    },
    {
        target: '[data-v-step="2"]',
        content: "Explore areas to setup your factory.",
    },
    {
        target: '[data-v-step="3"]',
        content: "Switch between commodities.",
    },
    {
        target: '[data-v-step="5"]',
        content: "Slide to explore yield data.",
    },
    {
        target: '[data-v-step="6"]',
        content: "Edit assets with pen tool.",
    },
    {
        target: '[data-v-step="7"]',
        content: "Filter tagged mills with properties",
    },
];


export default tourSteps;
