import {wardMillClusterRenderer} from "@/views/mills/data/rendererWardLayers"
import {arcgisURL} from "@/config/api-config";

const wardMillClusterLayer = {
    title: "Number of Clustered Mills",
    url: arcgisURL + "wards/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: wardMillClusterRenderer,
    popupTemplate: {
        title: "{wardname}, {lganame} LGA",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "wardname",
                        label: "Ward Name",
                        visible: true,
                    },
                    {
                        fieldName: "comm",
                        label: "Name of Communities",
                        visible: true,
                    },
                    {
                        fieldName: "millClust",
                        label: "Number of Clustered Mills",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default wardMillClusterLayer;

