import {factoryURL} from "@/config/api-config";
import { rf } from "../../data/featureLayersDetails";


const releafFactories = {
    url: factoryURL,
    title: "Releaf Assets",
    renderer: rf.renderer,
    popupTemplate: {
        title: "Releaf Assets - {name}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "name",
                        label: "Name",
                        visible: true,
                    },
                    {
                        fieldName: "type",
                        label: "Type",
                        visible: true,
                    },
                    {
                        fieldName: "rawMaterial",
                        label: "Material",
                        visible: true,
                    },
                    {
                        fieldName: "markerType",
                        label: "Marker Type",
                        visible: true,
                    },
                    {
                        fieldName: "status",
                        label: "Status",
                        visible: true,
                    },
                    {
                        fieldName: "latitude",
                        label: "Latitude",
                        visible: true,
                    },
                    {
                        fieldName: "Longitude",
                        label: "Longitude",
                        visible: true,
                    },
                ],
            },
        ],
    },
}
export default releafFactories;

