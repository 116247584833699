import leaflet from 'leaflet';
import 'leaflet.markercluster';

import store from '../store/index';

export const getMillsAggregatedPurchases = async () => {
  let mills = [];
  let loading;
  try {
    const globalMillsList = store.getters['mills/getAllMillsData'];

    let millsList = [];

    if (!globalMillsList.length) {
      loading = true;
      millsList = await store.dispatch('mills/fetchMills');

      if (millsList.length) {
        mills = millsList;
      }

      loading = false;
    } else {
      mills = globalMillsList;
      await store.dispatch('mills/fetchMills');
      loading = false;
    }

    return { mills, loading };
  } catch (error) {
    console.log(error.message);
    mills = [];
    loading = false;
    return { mills, loading };
  }
};

export class MillMarkers {
  constructor(mills, millLayer, map, isFilter) {
    this.mills = mills;
    this.millLayer = millLayer;
    this.map = map;
    this.isFilter = isFilter ? isFilter : false;
  }

  clearMarkers() {
    this.map.eachLayer((layer) => {
      if (layer instanceof leaflet.MarkerClusterGroup) {
        this.map.removeLayer(layer);
      }
    });
  }

  displayMarkers() {
    //create a marker cluster
    let clusters = leaflet.markerClusterGroup();

    if (this.isFilter) this.clearMarkers();

    //iterate over mills, add markers in the cluster
    this.mills.forEach((mill) => {
      const marker = new leaflet.Marker([
        mill.location.latitude,
        mill.location.longitude,
      ]);

      //add pop up to display meta data
      marker
        .bindPopup(
          `   <p>
          <span style="font-weight: bold">id</span>:
          ${mill.RTM}
        </p>
                <p>
                    <span style="font-weight: bold">Mill owner</span>:
                    ${mill.name}
                  </p>
                <p>
                    <span style="font-weight: bold">Total Amount Spent</span>:
                    &#8358;${
                      mill.totalPrice
                        ? mill.totalPrice.toFixed(0).toLocaleString()
                        : 'Nil'
                    }
                  </p>
                  <p>
                    <span style="font-weight: bold">Tons Bought</span>:
                    ${mill.tonsAmount ? mill.tonsAmount.toFixed(1) : 'Nil'}
                  </p>
                  <p>
                    <span style="font-weight: bold">Average Price Per Ton</span>:
                    ${mill.avgPricePerTon ? mill.avgPricePerTon : 'Nil'}
                  </p>
                  <p>
                    <span style="font-weight: bold">Number of Transactions</span>:
                    ${mill.noOfTransactions ? mill.noOfTransactions : 'Nil'}
                  </p>
                  <p>
                    <span style="font-weight: bold">Mill Size</span>:
                    ${mill.size}
                  </p>
                  <p>
                    <span style="font-weight: bold">Latitude</span>:
                    ${mill.location.latitude.toFixed(4)}
                  </p>
                  <p>
                    <span style="font-weight: bold">Longitude</span>:
                    ${mill.location.longitude.toFixed(4)}
                  </p>
                   <p>
                    <span style="font-weight: bold">LGA</span>: ${mill.lga_slug}
                  </p>
                  <p>
                    <span style="font-weight: bold">Number Of Pressers</span>: ${
                      mill.number_of_pressers
                    }
                  </p>
                  <p>
                  <span style="font-weight: bold">Cracker</span>: ${
                    mill.cracker
                  }
                </p>
                <p>
                <span style="font-weight: bold">Separator</span>: ${
                  mill.separator
                }
              </p>
              <p>
              <span style="font-weight: bold">Number Of Pots</span>: ${
                mill.number_of_pots
              }
            </p>
                `,
          {
            maxHeight: 350,
          }
        )
        .openPopup();

      clusters.addLayer(marker);

      if (this.isFilter) {
        clusters.addTo(this.map);
        this.millLayer.clearLayers();
      }

      this.millLayer.addLayer(clusters);
    });
  }
}

export class GeoJSON {
  constructor(geojsonList, oilPalmLayer, geojsonStyles) {
    this.geojsonList = geojsonList;
    this.oilPalmLayer = oilPalmLayer;
    this.geojsonStyles = geojsonStyles;
  }

  overlayGeoJSON() {
    this.geojsonList.forEach((geojson) => {
      leaflet
        .geoJSON(geojson, {
          // geojson styles
          style: this.geojsonStyles,

          //
          onEachFeature: function (feature, layer) {
            layer.on('mouseover', function () {
              layer.bindPopup(
                `<p>
            <span style="font-weight: bold">Age(yrs)</span>:
            ${
              feature.properties['Age']
                ? feature.properties['Age']
                : feature.properties['age']
                ? feature.properties['age']
                : 'Nil'
            }
         </p>
          <p>
            <span style="font-weight: bold">Area(ha)</span>:
            ${
              feature.properties['Area_ha']
                ? feature.properties['Area_ha']
                : 'Nil'
            }
         </p>
         <p>
            <span style="font-weight: bold">Bulk Density(g/mL)</span>:
            ${
              feature.properties['BulkDens']
                ? feature.properties['BulkDens']
                : 'Nil'
            }
         </p>
          `,
                { permanent: false, sticky: true }
              );
            });
          },
        })
        .addTo(this.oilPalmLayer);
    });
  }
}
