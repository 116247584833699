import Vue from 'vue';

// Import Components and Layouts
import App from './App.vue';
import DashboardLayout from "../src/views/admin/AdminDashboard.vue";

// Import Vue Plugins
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VuePaginate from 'vue-paginate';
import { Datetime } from 'vue-datetime';
import toastr from 'vue-toastr';
import VueTour from 'vue-tour';
import ElementUI from 'element-ui';

// Import CSS and Font Libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'leaflet/dist/leaflet.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-tour/dist/vue-tour.css';
import './index.css';

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import Router and Store
import router from './router';
import store from './store/index';

// Import Firebase and Leaflet
import firebase from './config/firebase';
import { Icon } from 'leaflet';

// Import JSON to Excel
import JsonExcel from 'vue-json-excel';

// Setup FontAwesome
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Setup Leaflet Icons
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Setup ElementUI Language
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

// Register Components, Layouts and Plugins
Vue.component('adminDashboardLayout', DashboardLayout);
Vue.component('downloadExcel', JsonExcel);
Vue.use(ElementUI);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VuePaginate);
Vue.use(Datetime);
Vue.use(toastr);
Vue.use(VueTour);

// Setup Axios
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = window.token;
axios.defaults.headers.post['Content-Type'] = 'application/json';

Vue.config.productionTip = false;

let app;

// Get up to date Firebase auth state before initializing Vue app
// Set auth state in Vuex store
firebase.auth().onAuthStateChanged((user) => {
    user
        ? store.dispatch('auth/setCurrentUser', {
            email: user.email,
            uid: user.uid,
        })
        : store.dispatch('auth/setCurrentUser', null);

    if (!app) {
        app = new Vue({
            router,
            store,
            components: { App },
            render: (h) => h(App),
        }).$mount('#app');
    }
});
