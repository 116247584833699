import {clusterConfig} from "@/views/mills/clusterConfig";
import {arcgisURL} from "@/config/api-config";

const millsDistanceLayer = {
    url: arcgisURL + "mills_distance/FeatureServer",
    title: "Mills Distance",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-marker",
            size: 15,
            color: "#bc6c25",
            outline: {
                color: "rgba(245, 239, 240, 0.5)",
                width: 2,
            },
        },
    },
    featureReduction: clusterConfig,
    popupTemplate: {
        title: "Mill Owner - {Location}",
        
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "Location",
                        label: "Mill Owner",
                        visible: true,
                    },
                    {
                        fieldName: "DIST_KM",
                        label: "Distance from Mill to C2 (KM)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}

export default millsDistanceLayer;



