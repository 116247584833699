import {customersURL} from "@/config/api-config";
import { cf } from "../../data/featureLayersDetails";

const customerFactoriesLayer = {
    url: customersURL,
    title: "Customer Factories",
    renderer: cf.renderer,
    popupTemplate: {
        title: "Customer Factories - {name}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "name",
                        label: "Factory Name",
                        visible: true,
                    },
                    {
                        fieldName: "phoneNumber",
                        label: "Phone Number",
                        visible: true,
                    },
                    {
                        fieldName: "latitude",
                        label: "Latitude",
                        visible: true,
                    },
                    {
                        fieldName: "longitude",
                        label: "Longitude",
                        visible: true,
                    },
                    {
                        fieldName: "rawMaterials",
                        label: "Raw Material",
                        visible: true,
                    },
                    {
                        fieldName: "type",
                        label: "Type",
                        visible: true,
                    },
                    {
                        fieldName: "status",
                        label: "Status",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default customerFactoriesLayer;

