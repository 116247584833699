<template>
  <div class="parent-nav">
    <div class="map-view-container" data-v-step="3">
      <MapViewDropdown
          :options="['Oil Palm', 'Cassava']"
          class="select"
          dropdownTitle="select map view"
          @onInput="handleSelectedView"
      />
    </div>

    <!-- sign out control -->
    <div class="sign-out-contol">
      <UserControl />
    </div>

    <!-- Delete Asset Prompt -->
    <!-- <div v-if="showDeleteAssetPrompt" class="delete-asset-prompt">
      <DeleteAssetPrompt
        @onCloseDeleteAssetForm="handleCloseDeleteAssetPrompt"
      />
    </div>
    <div class="overlay" v-if="showDeleteAssetPrompt"></div> -->

    <!-- Sign out Prompt -->
    <div v-if="showSignoutPrompt" class="sign-out-prompt">
      <SignoutPrompt @onCloseSignoutPrompt="handleCloseSignoutPrompt" />
    </div>
    <div class="overlay" v-if="showSignoutPrompt"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import DeleteAssetPrompt from "../components/map/DeleteAssetPrompt.vue";
import MapViewDropdown from "../components/map/MapViewDropdown.vue";
import UserControl from "../components/auth/UserControl.vue";
import SignoutPrompt from "../components/auth/SignoutPrompt.vue";

export default {
  name: "Home",
  components: {
    // DeleteAssetPrompt,
    MapViewDropdown,
    UserControl,
    SignoutPrompt,
  },
  data() {
    return {
      layer: [
        {
          id: "Map",
          name: "Oil Palm",
        },
        {
          id: "CassavaMap",
          name: "Cassava",
        },
      ],
      checkedMarkers: [],
      selectedLayer: "OilPalmMap",
      selected: "OilPalmMap",
      // showDeleteAssetPrompt: false,
      showSignoutPrompt: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/is_loggedIn",
    }),

    // deleteAssetPromptStatus() {
    //   return this.$store.getters["markers/getDeleteAssetPromptStatus"];
    // },

    signoutStatus() {
      return this.$store.getters["auth/signoutPrompt"];
    },
  },
  watch: {
    checkedMarkers(newValue) {
      // dispatch an action to the vuex store
      this.$store.dispatch("markers/updateCheckedMarkers", newValue);
    },

    // deleteAssetPromptStatus(newValue) {
    //   console.log(newValue);
    //   this.showDeleteAssetPrompt = newValue;
    // },

    signoutStatus(newValue) {
      this.showSignoutPrompt = newValue;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    handleSelectedView(selectedView) {
      if (selectedView === "Cassava") {
        this.$router.push("/map/cassava");
      } else if (selectedView === "Oil Palm") {
        this.$router.push("/map/oilpalm");
      }
    },

    // handleCloseDeleteAssetPrompt(isOpen) {
    //   this.showDeleteAssetPrompt = isOpen;
    //   this.$store.dispatch("markers/setDeleteAssetPromptStatus", isOpen);
    // },

    handleCloseSignoutPrompt(isOpen) {
      this.showSignoutPrompt = isOpen;
      this.$store.dispatch("auth/setSignoutPromptStatus", isOpen);
    },
  },
};
</script>

<style scoped>
.admin {
  position: absolute;
  top: 15px;
  right: 100px;
  z-index: 9980;
}

/* styles for map view dropdown */
.map-view-container {
  position: absolute;
  top: 10px;
  right: 680px;
  z-index: 9980;
}

/* styles for sign out control */
.sign-out-contol {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 9980;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 9990;
}

/* .delete-asset-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  max-width: 350px;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 9999;
} */

.sign-out-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  max-width: 350px;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
</style>