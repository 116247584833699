<template>
  <div>
    <!-- service area toggle -->
    <div
      class="saDraw"
      style="text-align: center; justify-content: center; align-items: center"
      v-b-tooltip.hover.right="'Service Area'"
      data-v-step="2"
    >
      <!-- <p style="text-align: center"><b>SA</b></p> -->
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRni2E8blq_JqUjzwLL5Xt6Lcxs1es-nNZ0aMXLvihdDNk9XZxW"
        width="25px"
        height="25px"
        style="
          margin-bottom: 8px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        "
      />
      <label class="switch">
        <input type="checkbox" @click="toggleServiceArea" />
        <span class="slider round"></span> </label
      ><br />

      <button @click="clearServiceArea">X</button>
    </div>

    <!-- service area table -->
    <div
      v-b-toggle.collapse-1
      class="saTable"
      v-b-tooltip.hover.right="'Service Area Table'"
    >
      <b-collapse id="collapse-1" class="mt-2">
        <div class="saTable-b" v-show="items">
          <p v-if="itemsa != 0" class="sa">
            <b>Service Area Table</b>
          </p>
          <table v-if="itemsa != 0">
            <tr>
              <th>Color Code</th>
              <th>Total Mills</th>
              <th>Logistics Cost (₦)</th>
              <th>Current P1 price (₦)</th>
              <th>Estimated profit/week (₦)</th>
              <th>Total Minigrids</th>
              <th>Total Acres</th>
              <th>No of Transactions</th>
              <th>Total Tonnes Bought</th>
              <th>Weekly FFB Yield</th>
              <th>Weekly P1 Yield</th>
            </tr>
          </table>

          <table v-for="item in itemsa" :key="item.id">
            <tr>
              <td class="td-box" :style="{ background: item.color }"></td>
              <td>{{ item.noOfMills }}</td>
              <td>{{ item.logisticsCost }}</td>
              <td>{{ item.currentP1Price }}</td>
              <td>{{ item.profit_week }}</td>
              <td>{{ item.minigridsTotal }}</td>
              <td>{{ item.totalAcres }}</td>
              <td>{{ item.totalNoOfTransactions }}</td>
              <td>{{ item.totalTonsBought }}</td>
              <td>{{ item.weeklyFFBYield }}</td>
              <td>{{ item.weeklyP1Yield }}</td>
            </tr>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceAreaToggle",

  data() {
    return {
      itemsa: [],
      items: [],
      saToggle: false,
    };
  },

  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    serviceAreaStore() {
      return this.$store.getters["serviceArea/getServiceAreaAttributes"];
    },
  },

  methods: {
    toggleServiceArea() {
      this.saToggle = !this.saToggle;
      this.$emit("onToggle", this.saToggle);
    },

    clearServiceArea() {
      this.view.graphics.removeAll();
      // this.itemsa = this.items; //doesnt clear the array
      this.itemsa.splice(0, this.itemsa.length); //clears the array
    },
  },
  watch: {
    serviceAreaStore(newValue) {
      if (newValue) {
        this.itemsa = newValue;
      }
    },
  },
};
</script>

<style scoped>
.saDraw {
  width: 2.1em;
  position: absolute;
  display: block;
  top: 344px;
  left: 14px;
  z-index: 9980;
  padding-top: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #669933;
}
input:focus + .slider {
  box-shadow: 0 0 1px #669933;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}
.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.saTable {
  height: 34.5px;
  width: 34.5px;
  cursor: pointer;
  position: absolute;
  display: block;
  top: 440px;
  left: 14px;
  z-index: 9980;
  background: url("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTfxG9wXMTUKwPcMvqoQs89kE0cLVLJN8nHNU_UQ7fHo-Gcll9B");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.saTable-b {
  padding: 20px;
  margin-top: 10px;
  margin-right: -950px;
  margin-left: 40px;
  z-index: 9980;
  background: #fff;
}
.sa {
  text-align: center;
}
th {
  width: 84px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 70%;
}
td {
  width: 84px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 80%;
}
</style>
