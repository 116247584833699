import {arcgisURL} from "@/config/api-config";

const stateBoundaryLayer = {
    title: "Nigeria States Boundaries",
    url: arcgisURL + "states/FeatureServer",
    opacity: 0.8,
    copyright: "",
    labelingInfo: [{  // autocasts as new LabelClass()
        symbol: {
            type: "text",
            color: "darkblue",
            font: {
                family: "Orbitron",
                size: 12,
                weight: "bold"
            }
        },
        labelExpressionInfo: {
            expression: "$feature.ADM1_EN"
        },
        labelPlacement: "center-along",
        repeatLabel: true,
        repeatDistanceLabel: 100
    }],
    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            // size: 15,
            // color: "#2a9d8f",
            color: "rgba(153, 153, 102, 0.4)",
            outline: {
                color: "rgba(0, 51, 0, 0.7)",
                // color: "darkgreen",
                width: 3,
            },
        },
    },
    popupTemplate: {
        title: "{ADM1_EN} State",
        // symbol: symbol,
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "ADM1_EN",
                        label: "State",
                        visible: true,
                    },
                ],
            },
        ],
    },
}
export default stateBoundaryLayer;

