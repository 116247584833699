import axios from 'axios';
import { getTokenFromCookie } from '../helpers/middleware/cross_sharing';


let baseURL;
if (process.env.VUE_APP_AXIOS_STATE === 'bare_metal') {
  baseURL = `https://api.v2.salt.releaf.ng/api/v2`;
} else {
  baseURL = "https://api.v2.salt.releaf.ng/api/v2";
}


const salt_api_config = axios.create({
  baseURL,
  headers: {
    Authorization: getTokenFromCookie(),
  },
});

export { salt_api_config };