<template>
  <div>
    <!-- Sidebar -->
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <!-- Sidebar - Brand -->
      <a
        class="sidebar-brand d-flex align-items-center justify-content-center"
        href=""
      >
        <div class="sidebar-brand-icon rotate-n-0">
          <!-- <i class="fas fa-laugh-wink"></i> -->
          <img src="/assets/img/header-logo.png" width="50" height="50" />
        </div>
        <div class="sidebar-brand-text mx-3">Admin</div>
      </a>
      <hr class="sidebar-divider my-0" />
      <li :class="`nav-item ${getActiveNav(['/'])}`">
        <router-link class="nav-link" to="/">
          <i class="fas fa-fw fa-globe"></i>
          <span>SITE</span>
        </router-link>
      </li>
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Pages Collapse Menu -->
      <!-- <hr class="sidebar-divider" /> -->

      <li class="nav-item">
        <router-link to="/map/admin/factories">
          <a class="nav-link">
            <i class="fa fa-industry"></i>
            <span>Factory</span></a
          >
        </router-link>
      </li>
      <hr class="sidebar-divider my-0" />
      <li class="nav-item">
        <router-link to="/map/admin/aps">
          <a class="nav-link">
            <i class="fa fa-microchip"></i>
            <span>AP</span></a
          >
        </router-link>
      </li>
      <hr class="sidebar-divider my-0" />
      <li class="nav-item">
        <router-link to="/map/admin/customers">
          <a class="nav-link">
            <i class="fa fa-user"></i>
            <span>Customers</span></a
          >
        </router-link>
      </li>
      <hr class="sidebar-divider my-0" />
      <li class="nav-item">
        <router-link to="/map/admin/logisticsDepot">
          <a class="nav-link">
            <i class="fa fa-car"></i>
            <span>Logistics Depot</span></a
          >
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block" />
    </ul>
    <!-- End of Sidebar -->
  </div>
</template>

<script>
export default {
  name: "SideNav",
  methods: {
    getActiveNav(paths = []) {
      const currentPath = this.$route.path;
      if (paths.includes(currentPath)) return "active";
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
