import {clusterConfig} from "@/views/mills/clusterConfig";
import {baseURL} from "@/config/keys";
import {cpotm} from "@/views/mills/data/featureLayersDetails";

const releafTaggedMillsLayer = {
    url: baseURL + "purchases/mills-purchase-aggregates?isGeoJSON=yes",
    title: "Confirmed Mills",
    renderer: cpotm.renderer,
    featureReduction: clusterConfig,
    popupTemplate: {
        title: "Confirmed Mills - {name}",
        expressionInfos: [
            {
                name: "sustainabilityScore",
                title: "Sustainability Score (%)",
                expression: `
                
                  function x(){
                      var oilPalm = $feature.oilPalmClusterHectares;
                      var deforested = $feature.deforestedLayerHectares;
                    //   var difference = (oilPalm - deforested)
                    var cluster = $feature.oilPalmClusterHectares - $feature.deforestedLayerHectares
                      var sustainable = cluster / $feature.oilPalmClusterHectares * 100;
                      return sustainable;
                  }
                  x();
  
                  `,
              },
          ],
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "name",
                        label: "Mill Owner",
                        visible: true,
                    },
                    {
                        fieldName: "expression/sustainabilityScore",
                        visible: true,
                        format: {
                            places: 1,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "RTM",
                        label: "RTM Number",
                        visible: true,
                    },
                    {
                        fieldName: "state_slug",
                        label: "State",
                        visible: true,
                    },
                    {
                        fieldName: "lga_slug",
                        label: "LGA",
                        visible: true,
                    },
                    {
                        fieldName: "cracker",
                        label: "Cracker",
                        visible: true,
                    },
                    {
                        fieldName: "separator",
                        label: "Separator",
                        visible: true,
                    },
                    {
                        fieldName: "lastDiscoveryDate",
                        label: "Last Discovery Date",
                        visible: true,
                    },
                    {
                        fieldName: "number_of_pots",
                        label: "Number Of Pots",
                        visible: true,
                    },
                    {
                        fieldName: "number_of_pressers",
                        label: "Number Of Pressers",
                        visible: true,
                    },
                    {
                        fieldName: "physically_tagged",
                        label: "Physically Tagged",
                        visible: true,
                    },
                    {
                        fieldName: "totalPrice",
                        label: "Total Amount Spent (₦)",
                        visible: true,
                    },
                    {
                        fieldName: "tonsAmount",
                        label: "Tonnes Bought",
                        visible: true,
                    },
                    {
                        fieldName: "avgPricePerTon",
                        label: "Average Price Per Tonne (₦)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "noOfTransactions",
                        label: "Number of Transactions",
                        visible: true,
                    },
                    {
                        fieldName: "size",
                        label: "Mill Size",
                        visible: true,
                    },
                    
                ],
            },
        ],
    },
}

export default releafTaggedMillsLayer;

export function releafTaggedMills(eventItem, daysSinceLastDiscoverySlider, Slider, tonnesBoughtTaggedMillsSlider, numberOfTxnSlider, gtDaysSinceLastDiscovery, ltDaysSinceLastDiscovery, gtTonnesBoughtTaggedMills, ltTonnesBoughtTaggedMills, gtNumberOfTxn, ltNumberOfTxn, cpoTaggedMillsFilterReset, sliderResetCPOtaggedMills, handleCPOTaggedReleafSummary) {
    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", ()=>{
        handleCPOTaggedReleafSummary()
        
    })
    
    eventItem.visible = false;
    daysSinceLastDiscoverySlider = new Slider({
        min: 0,
        max: 60,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });
    tonnesBoughtTaggedMillsSlider = new Slider({
        min: 0,
        max: 500,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });
    numberOfTxnSlider = new Slider({
        min: 0,
        max: 50,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });

    //sign function - DaysSinceLastDiscovery
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span")
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round"
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtDaysSinceLastDiscovery();
        } else {
            ltDaysSinceLastDiscovery();
        }
    });

    //sign function - TonnesBoughtTaggedMills
    let switch2 = document.createElement("label");
    let input2 = document.createElement("input");
    let span2 = document.createElement("span")
    switch2.className = "switch";
    input2.type = "checkbox";
    span2.className = "slider round"
    switch2.appendChild(input2);
    switch2.appendChild(span2);
    input2.checked = true;

    input2.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtTonnesBoughtTaggedMills();
        } else {
            ltTonnesBoughtTaggedMills();
        }
    });

    //sign function - numberOfTxn
    let switch3 = document.createElement("label");
    let input3 = document.createElement("input");
    let span3 = document.createElement("span")
    switch3.className = "switch";
    input3.type = "checkbox";
    span3.className = "slider round"
    switch3.appendChild(input3);
    switch3.appendChild(span3);
    input3.checked = true;

    input3.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtNumberOfTxn();
        } else {
            ltNumberOfTxn();
        }
    });

    //Reset button
    let resetButton = document.createElement('button');
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
        cpoTaggedMillsFilterReset();
        sliderResetCPOtaggedMills();
    })
    eventItem.panel = {
        content: [
            "legend",
            "Toggle off the switch to reverse the slider function to '< less than function'.",
            "&nbsp;",
            "Days Since P1 Last Discovered at Mill",
            "&nbsp;",
            "&nbsp;",
            "&nbsp;",
            switch1,
            "&nbsp;",
            daysSinceLastDiscoverySlider,
            "&nbsp;",
            "&nbsp;",
            "Tonnes Purchased",
            "&nbsp;",
            "&nbsp;",
            "&nbsp;",
            switch2,
            "&nbsp;",
            tonnesBoughtTaggedMillsSlider,
            "&nbsp;",
            "&nbsp;",
            "Number of Transactions",
            "&nbsp;",
            "&nbsp;",
            "&nbsp;",
            switch3,
            "&nbsp;",
            numberOfTxnSlider,
            "&nbsp;",
            "&nbsp;",
            resetButton,
            "&nbsp;",
            divSummaryButton
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
    };
    return {daysSinceLastDiscoverySlider, tonnesBoughtTaggedMillsSlider, numberOfTxnSlider};
}

