import Vue from "vue";
import Router from "vue-router";
import OilPalmMap from "../views/mills/OilPalmMap";
import OilPalmMap3D from "../views/mills/OilPalmMap3D.vue";
import ServiceArea from "../views/widgets/ServiceArea.vue";
import WeeklyYield from "../views/widgets/WeeklyYield.vue";
import ProfitPerWk from "../views/widgets/ProfitPerWk.vue";
import OilPalmClusters from "../views/widgets/OilPalmClusters.vue";
import ProtectedAreas from "../views/widgets/ProtectedAreas.vue";
import CassavaMap from "../views/cassava/CassavaMap";
import Login from "../components/auth/Login";
import ResetPassword from "../components/auth/ResetPassword";
import ForgotPassword from "../components/auth/ForgotPassword";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import Factory from "../components/admin-dashboard/factory/Factory.vue";
import Ap from "../components/admin-dashboard/ap/Aps.vue";
import Customers from "../components/admin-dashboard/customer/Customers.vue";
import LogisticDepot from "../components/admin-dashboard/logistics-depot/LogisticsDepot.vue";

import store from "../store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
        requiresGuest: true,
      },
    },

    {
      path: "/forgot",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/reset",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/map/cassava",
      name: "CassavaMap",
      component: CassavaMap,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/map/oilpalm",
      name: "Map",
      component: OilPalmMap,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/map/oilpalm3d",
      name: "Map3D",
      component: OilPalmMap3D,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/map/widgets/serviceArea",
      name: "ServiceArea",
      component: ServiceArea,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/map/widgets/oilPalmClusters",
      name: "OilPalmClusters",
      component: OilPalmClusters,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/map/widgets/protectedAreas",
      name: "ProtectedAreas",
      component: ProtectedAreas,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/map/widgets/weeklyYield",
      name: "WeeklyYield",
      component: WeeklyYield,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/map/widgets/profitPerWeek",
      name: "ProfitPerWk",
      component: ProfitPerWk,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/map/admin",
      name: "parentLayout",
      component: AdminDashboard,
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "factories",
          name: "Factory",
          component: Factory,
        },
        {
          path: "aps",
          name: "Aps",
          component: Ap,
        },
        {
          path: "customers",
          name: "Customers",
          component: Customers,
        },
        {
          path: "logisticsDepot",
          name: "logisticsDepot",
          component: LogisticDepot,
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);

  const user = store.getters["auth/currentUser"];

  if (requiresAuth && !user?.email) {
    router.push({ name: "Login" });
  } else if (requiresGuest && user?.email) {
    router.push({ name: "Map" });
  } else {
    next();
  }
});

export default router;
