<template>
  <div>
    <div class="container">
      <div class="center">
        <p>Do you want to logout?</p>
        <i class="fa fa-close close-icon" @click="closeSignoutPrompt"></i>
      </div>

      <div class="button-wrapper">
        <button class="signout-btn" @click="handleSignout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignoutPrompt',

  data() {
    return {
      showSignoutPrompt: null,
      loading: false,
    };
  },

  methods: {
    closeSignoutPrompt() {
      this.showSignoutPrompt = false;
      this.$emit('onCloseSignoutPrompt', this.showSignoutPrompt);
    },

    async handleSignout() {
      this.$store.dispatch('auth/setSignoutPromptStatus', false);

      await this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style scoped>
.container {
  background: #fff;
  border: 1px solid #ababab;
  width: 80%;
  padding: 10px 0px;
  border-radius: 5px;
  position: relative;
}

.center {
  margin: 10px auto;
  padding: 0px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

p {
  color: #333333;
  font-size: 15px;
  /* margin-bottom: 8px; */
  font-weight: 400;
  padding: 0px;
}

.close-icon {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 15px;
  font-weight: 200;
}

.button-wrapper {
  display: flex;
  /* margin-left: auto; */
  justify-content: center;
  align-items: center;
  /* padding-right: 5px; */
}

.signout-btn {
  color: #fff;
  background-color: #669933;
  width: 45%;
  border-radius: 30px;
  text-align: center;
  padding: 7px;
  font-size: 10px;
  /* margin-left: auto; */
}
</style>
