import {arcgisURL} from "@/config/api-config";

const oilPalmClusterLayer = {
    title: "Niger Delta Planted Oil Palm",
    url: arcgisURL + "palmclustersAllPrj/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            color: "#8FC54D",
            outline: {
                color: "rgba(10, 41, 37, 10%)",
                width: 0.2,
            },
        },
    },
    popupTemplate: {
        title: "Oil Palm Cluster - {ha_area_1} (ha)",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "haNow",
                        label: "Area Size (ha)",
                        visible: true,
                    },
                    {
                        fieldName: "tones",
                        label: "Tonnes yield (T)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "WeeklyP1Yield",
                        label: "Weekly P1 Yield (T)",
                        visible: true,
                    },
                    {
                        fieldName: "WeeklyFFBYield",
                        label: "Weekly FFB Yield (T)",
                        visible: true,
                    },
                    {
                        fieldName: "Ages",
                        label: "Age",
                        visible: true,
                    },
                    {
                        fieldName: "BulkDens",
                        label: "Bulk Density",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "layer",
                        label: "State",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default oilPalmClusterLayer;

