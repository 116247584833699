<template>
  <div>
    <div
      v-show="showCPOTaggedReleafSummary"
      class="summary-board summary-shadow"
    >
      <h1 class="text-center"><b>Summary Board</b></h1>
      <p @click="showContainerFx" class="close-x">X</p>

      <!-- Releaf Factory summary -->
      <div class="p1-summary mt-3">
        <p><b>CPO Mills Tagged by Releaf</b></p>
        <ul class="mt-2">
          <li>Total Number of Mills: {{ this.layersObject.totalNoMills }}</li>
          <p class="mt-2"><b>Breakdown by Location</b></p>
          <li>Mills in Abia: {{ this.layersObject.abia }}</li>
          <li>Mills in Akwa Ibom: {{ this.layersObject.akwaibom }}</li>
          <li>Mills in Cross River: {{ this.layersObject.crossriver }}</li>
          <li>Mills in Ebonyi: {{ this.layersObject.ebonyi }}</li>
          <li>Mills in Edo: {{ this.layersObject.edo }}</li>
          <li>Mills in Imo: {{ this.layersObject.imo }}</li>
          <li>Mills in Ondo: {{ this.layersObject.ondo }}</li>
          <li>Mills in Rivers: {{ this.layersObject.rivers }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { reverseGeocoding } from "../../config/api-config";
import { arcgisURL } from "@/config/api-config";
import { APIKeys } from "../../config/keys";

export default {
  name: "FpsClientLayersSummy",
  props: {
    showCPOTaggedReleafSummary: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      layersObject: {},
    };
  },

  mounted() {
    this.loadEsri();
  },

  computed: {},

  methods: {
    showContainerFx() {
      this.showCPOTaggedReleafSummary = !this.showCPOTaggedReleafSummary;
    },
    loadEsri() {
      loadModules([
        "esri/config",
        "esri/layers/FeatureLayer",
        "esri/rest/locator",
      ]).then(([esriConfig, FeatureLayer, locator]) => {
        esriConfig.apiKey = APIKeys;

        this.layersObject = {
          totalNoMills: 0,
          lastDiscovery: 0,
        };

        const cpoMillTaggedByReleaf = new FeatureLayer({
          url: arcgisURL + "taggedMillsClus/FeatureServer",
        });

        var toObjectLayer = this.layersObject;
        let query = cpoMillTaggedByReleaf.createQuery();

        cpoMillTaggedByReleaf
          .queryFeatures(query)
          .then(function (response) {
            let layerAttributesArray = response.features.map(
              (feature) => feature.attributes
            );
            //Total number of mills
            toObjectLayer.totalNoMills = layerAttributesArray.length;

            return layerAttributesArray; // Return the array for further processing if needed
          })
          .catch(function (error) {
            console.error("Error occurred during queryFeatures:", error);
          });

        cpoMillTaggedByReleaf
          .queryFeatures(query)
          .then(function (response) {
            let coordinate = response.features.map((feature) => {
              let long = feature.attributes.location_1;
              let lat = feature.attributes.location_l;
              let longLat = [long, lat];
              return longLat;
            });

            let reverseGeocoder = coordinate.map((address) => {
              const serviceUrl = reverseGeocoding;
              const params = {
                location: address,
              };
              return locator
                .locationToAddress(serviceUrl, params)
                .then((response) => {
                  let region = response.attributes.Region;
                  return region;
                });
            });

            Promise.all(reverseGeocoder).then((addressArray) => {
              const uniqueuniqueRegions = {};

              for (const element of addressArray) {
                if (uniqueuniqueRegions[element]) {
                  uniqueuniqueRegions[element] += 1;
                } else {
                  uniqueuniqueRegions[element] = 1;
                }
              }
              toObjectLayer.abia = uniqueuniqueRegions.Abia;
              toObjectLayer.akwaibom = uniqueuniqueRegions["Akwa Ibom"];
              toObjectLayer.crossriver = uniqueuniqueRegions["Cross River"];
              toObjectLayer.ebonyi = uniqueuniqueRegions.Ebonyi;
              toObjectLayer.edo = uniqueuniqueRegions.Edo;
              toObjectLayer.imo = uniqueuniqueRegions.Imo;
              toObjectLayer.ondo = uniqueuniqueRegions.Ondo;
              toObjectLayer.rivers = uniqueuniqueRegions.Rivers;
            });
          })
          .catch(function (error) {
            console.error("Error occurred during queryFeatures:", error);
          });
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.close-x {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.summary-board {
  padding: 40px;
  width: 500px;
  height: 300px;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  bottom: 100px;
  left: 70px;
}

.summary-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

ol,
ul {
  list-style-type: circle;
}
</style>