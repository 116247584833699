class LayersLGA {
  constructor() {
    this.less35 = {
      type: "simple-fill", 
      color: "#f7f2ed",
      style: "solid",
      outline: {
        width: 0.2,
        color: "#1a1a1a",
      },
    };

    this.less36 = {
      type: "simple-fill", 
      color: "#2a9d8f",
      style: "solid",
      outline: {
        width: 3,
        color: "#1a1a1a",
      },
    };

    this.less37 = {
      type: "simple-fill", 
      color: "#e9c46a",
      style: "solid",
      outline: {
        width: 3,
        color: "#1a1a1a",
      },
    };

    this.less38 = {
      type: "simple-fill", 
      color: "#f4a261",
      style: "solid",
      outline: {
        width: 3,
        color: "#1a1a1a",
      },
    };

    this.above38 = {
      type: "simple-fill", 
      color: "#e76f51",
      style: "solid",
      outline: {
        width: 3,
        color: "#1a1a1a",
      },
    };

    this.renderer = {
      type: "class-breaks",
      field: "avgPricePe",
      legendOptions: {
        title: "Legend for Average P1 Price",
      },
      classBreakInfos: [
        {
          minValue: 0,
          maxValue: 34999,
          symbol: this.less35,
          label: "< 35k", // label for symbol in legend
        },
        {
          minValue: 35000,
          maxValue: 36000,
          symbol: this.less36,
          label: "35k - 36k", // label for symbol in legend
        },
        {
          minValue: 36001,
          maxValue: 37000,
          symbol: this.less37,
          label: "36k - 37k", // label for symbol in legend
        },
        {
          minValue: 37001,
          maxValue: 38000,
          symbol: this.less38,
          label: "37k - 38k", // label for symbol in legend
        },
        {
          minValue: 38001,
          maxValue: 72500,
          symbol: this.above38,
          label: "38k and Above", // label for symbol in legend
        },
      ],
    };
  }
}
export const fld = new LayersLGA();

//currentlayers polygon
class curerntLayerPol {
    constructor() {

        this.less36 = {
            type: "simple-fill", 
            color: "rgba(42, 157, 143, 0.5)",
            style: "solid",
            outline: {
                width: 1,
                color: "#1a1a1a",
            },
        };

        this.less37 = {
            type: "simple-fill", 
            color: "rgba(233, 196, 106, 0.5)",
            style: "solid",
            outline: {
                width: 1,
                color: "#1a1a1a",
            },
        };

        this.less38 = {
            type: "simple-fill", 
            color: "rgba(244, 162, 97, 0.5)",
            style: "solid",
            outline: {
                width: 1,
                color: "#1a1a1a",
            },
        };

        this.above38 = {
            type: "simple-fill", 
            color: "rgba(231, 111, 81, 0.5)",
            style: "solid",
            outline: {
                width: 1,
                color: "#1a1a1a",
            },
        };

        this.renderer = {
            type: "class-breaks",
            field: "price",
            legendOptions: {
                title: "Current Price of P1",
            },
            classBreakInfos: [
                {
                    minValue: 20000,
                    maxValue: 25000,
                    symbol: this.less36,
                    label: "20k - 25k", // label for symbol in legend
                },
                {
                    minValue: 25001,
                    maxValue: 30000,
                    symbol: this.less37,
                    label: "25k - 30k", // label for symbol in legend
                },
                {
                    minValue: 30001,
                    maxValue: 35000,
                    symbol: this.less38,
                    label: "30k - 35k", // label for symbol in legend
                },
                {
                    minValue: 35001,
                    maxValue: 72500,
                    symbol: this.above38,
                    label: "35k and Above", // label for symbol in legend
                },
            ],
        };
    }
}
export const clp = new curerntLayerPol();

//LiveInventory
class LiveInventory {
    constructor() {
        this.renderer = {
            type: "class-breaks",
            field: "daysSincePurchase",
            legendOptions: {
                title: "Days ago"
            },
            classBreakInfos: [
                {
                    minValue: 0,
                    maxValue: 3,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#70dbdb",
                        outline: {
                            color: "#252422",
                            width: 1,
                        },
                    },
                    label: "3 days and below"
                },
                {
                    minValue: 3,
                    maxValue: 7,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#a6db70",
                        outline: {
                            color: "#252422",
                            width: 1,
                        },
                    },
                    label: "Less than 7days"
                },
                {
                    minValue: 7,
                    maxValue: 14,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#dbc170",
                        outline: {
                            color: "#252422",
                            width: 1,
                        },
                    },
                    label: "Less thank 14 days"
                },
                {
                    minValue: 21,
                    maxValue: 1000,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#db7070",
                        outline: {
                            color: "#252422",
                            width: 1,
                        },
                    },
                    label: "21 days and above"
                }
            ],
            visualVariables: [
                {
                    type: "size", // indicates this is a size visual variable
                    field: "tonnesPurchased", // total population in poverty
                    outline: {
                        color: "rgba(128, 0, 0, 1)",
                        width: 2,
                    }, legendOptions: {
                        title: "Tonnage bought",
                    },
                    stops: [
                        {
                            value: 2.5, // features where < 15% of the pop is in poverty
                            size: 15, // will be assigned a marker with this size in pts
                            label: "less than 2.5T" // label to display in the legend
                        },
                        {
                            value: 4.1, // features where < 15% of the pop is in poverty
                            size: 25, // will be assigned a marker with this size in pts
                            label: "2.5T - 4.1T" // label to display in the legend
                        },
                        {
                            value: 6.1, // features where < 15% of the pop is in poverty
                            size: 35, // will be assigned a marker with this size in pts
                            label: "4.1T - <6.1T" // label to display in the legend
                        },
                        {
                            value: 10, // features where > 35% of the pop is in poverty
                            size: 40, // will be assigned a marker with this size in pts
                            label: "6.1T - 10T" // label to display in the legend
                        },
                        {
                            value: 10.1, // features where > 35% of the pop is in poverty
                            size: 40, // will be assigned a marker with this size in pts
                            label: "More than 10T" // label to display in the legend
                        }
                    ]
                }
            ]

        };

    }
}
export const liveInventory = new LiveInventory();


//cpotm tagged mills 
class cpotmTaggedMills {
  constructor() {

        this.renderer = {
            type: "class-breaks",
            field: "daysFromLastDiscovery",
            legendOptions: {
                title: "Days since last discovery"
            },
            // defaultSymbol: {
            //     type: "simple-marker", //simple-marker
            //     color: "#4d4d4d",
            //     outline: {
            //         color: "rgba(241, 229, 218, 0.5)",
            //         width: 2,
            //     },
            // },
            // defaultLabel: "no data",
            classBreakInfos: [
                {
                    minValue: -2,
                    maxValue: -1,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#4d4d4d",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "no data"
                },
                {
                    minValue: 0,
                    maxValue: 7,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#118ab2",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "7 days and below"
                },
                {
                    minValue: 8,
                    maxValue: 14,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "orange",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "8 - 14 days"
                },
                {
                    minValue: 15,
                    maxValue: 21,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "blue",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "15 - 21 days"
                },
                {
                    minValue: 22,
                    maxValue: 1000,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#38b000",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "22 and above days"
                }
            ]
        };

  }
}
export const cpotm = new cpotmTaggedMills();


//tagged mills same distance
class cpoMillsCluster {
    constructor() {
        this.renderer = {
            type: "class-breaks",
            field: "totalTons",
            legendOptions: {
                title: "Tonnes Bought"
            },
            classBreakInfos: [
                {
                    minValue: 0,
                    maxValue: 5,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#335c67",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "below 5 Tonnes"
                },
                {
                    minValue: 6,
                    maxValue: 20,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#e09f3e",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "6 - 20 Tonnes"
                },
                {
                    minValue: 21,
                    maxValue: 50,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#9e2a2b",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "21 - 50 Tonnes"
                },
                {
                    minValue: 51,
                    maxValue: 1000,
                    symbol: {
                        type: "simple-marker", //simple-marker
                        color: "#540b0e",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "Above 50 Tones"
                }
            ],
        };
    }
}
export const tmsd = new cpoMillsCluster();

//current price point
class LayersCurrentPrice {
    constructor() {
        this.renderer = {
            type: "simple",
            symbol: {
                type: "simple-marker",
                size: 15,
                color: "#cea47e",
                outline: {
                    color: "rgba(241, 229, 218, 0.5)",
                    width: 2,
                },
            },
            label: "Current Price Layer", // label for the legend
            visualVariables: [
                {
                    type: "color", // indicates this is a color visual variable
                    field: "price", // total population in poverty
                    stops: [
                        {
                            value: 20000, // features where < 10% of the pop in poverty
                            color: "green", // will be assigned this color (beige)
                            label: "Less than 20k" // label to display in the legend
                        },
                        {
                            value: 35000, // features where > 30% of the pop in poverty
                            color: "yellow", // will be assigned this color (purple)
                            label: "35k" // label to display in the legend
                        },
                        {
                            value: 80000, // features where > 30% of the pop in poverty
                            color: "red", // will be assigned this color (purple)
                            label: "80k and above" // label to display in the legend
                        }
                    ]
                },
            ]
        };
    }
}

export const cp = new LayersCurrentPrice();

//future price
class LayersFuturePrice {
    constructor() {
        this.renderer = {
            type: "simple",
            symbol: {
                type: "simple-marker",
                size: 20,
                color: "#cea47e",
                outline: {
                    color: "rgba(241, 229, 218, 0.5)",
                    width: 2,
                },
            },
            label: "Future Price Layer", // label for the legend
            visualVariables: [
                {
                    type: "color", // indicates this is a color visual variable
                    field: "futurePrice", // total population in poverty
                    stops: [
                        {
                            value: 48000, // features where < 10% of the pop in poverty
                            color: "green", // will be assigned this color (beige)
                            label: "Less than 48k" // label to display in the legend
                        },
                        {
                            value: 50000, // features where > 30% of the pop in poverty
                            color: "yellow", // will be assigned this color (purple)
                            label: "50k" // label to display in the legend
                        },
                        {
                            value: 80000, // features where > 30% of the pop in poverty
                            color: "red", // will be assigned this color (purple)
                            label: "80k and above" // label to display in the legend
                        }
                    ]
                },


            ]
        };
    }
}
export const fp = new LayersFuturePrice();

//releaf factories
class releafFactories {
    constructor() {
        this.renderer = {
            type: "unique-value",
            field: "type",  // contains values referenced in uniqueValueInfos
            legendOptions: {
                title: "Factory Types",
            },
            uniqueValueInfos: [
                {
                    value: "Refinery",  // features labeled as "high"
                    symbol: {
                        type: "simple-marker",
                        size: 15,
                        color: "#674EA7",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    
                } 
                , {
                    value: "Crushing Mill",  // features labeled as "low"
                    symbol: {
                        type: "simple-marker",
                        size: 15,
                        color: "#FF0000",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                },
                {
                    value: "Deshelling Mill",  // features labeled as "medium"
                    symbol: {
                        type: "simple-marker",
                        size: 15,
                        color: "#980000",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    // label: "Kraken II"
                },
                {
                    value: "Kraken II",  // features labeled as "medium"
                    symbol: {
                        type: "simple-marker",
                        size: 15,
                        color: "blue",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "Kraken II"
                },
            ]
        };
    }
}
export const rf = new releafFactories();

//customers factories
class customerFactories {
    constructor() {
        this.renderer = {
            type: "unique-value", //unique-value  // autocasts as new UniqueValueRenderer()
            field: "type",  // contains values referenced in uniqueValueInfos
            legendOptions: {
                title: "Factory Types",
            },
            symbol: {
                type: "simple-marker",
                style: "square",
                size: 15,
                color: "purple",
                outline: {
                    color: "rgba(241, 229, 218, 0.5)",
                    width: 2,
                },
            },
            uniqueValueInfos: [
                {
                    value: "Refinery",  // features labeled as "high"
                    symbol: {
                        type: "simple-marker",
                        style: "square",
                        size: 15,
                        color: "#674EA7",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                }, 
                {
                    value: "Crushing Mill",  // features labeled as "low"
                    symbol: {
                        type: "simple-marker",
                        style: "square",
                        size: 15,
                        color: "#FF0000",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                },
                {
                    value: "Kraken II",  // features labeled as "medium"
                    symbol: {
                        type: "simple-marker",
                        style: "square",
                        size: 15,
                        color: "#980000",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "Kraken II"
                },
                {
                    value: "Cement",  // features labeled as "medium"
                    symbol: {
                        type: "simple-marker",
                        style: "square",
                        size: 15,
                        color: "#2a9d8f",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "Cement"
                },
                {
                    value: "Other Industries",  // features labeled as "medium"
                    symbol: {
                        type: "simple-marker",
                        style: "square",
                        size: 15,
                        color: "#a8dadc",
                        outline: {
                            color: "rgba(241, 229, 218, 0.5)",
                            width: 2,
                        },
                    },
                    label: "Other Industries"
                },
            ]
        };
    }
}
export const cf = new customerFactories();


