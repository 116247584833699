import {wardP1SourcedRenderer} from "@/views/mills/data/rendererWardLayers"
import {arcgisURL} from "@/config/api-config";

const wardTonnesSourcedLayer = {
    title: "Tonnes of P1 Sourced",
    url: arcgisURL + "wardsTSourced/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: wardP1SourcedRenderer,
    popupTemplate: {
        title: "{wardname}, {lganame} LGA",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "wardname",
                        label: "Ward Name",
                        visible: true,
                    },
                    {
                        fieldName: "comm",
                        label: "Name of Communities",
                        visible: true,
                    },
                    {
                        fieldName: "F_id_count",
                        label: "Number of Tagged Mills",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "tonSOurc_1",
                        label: "Tonnes of P1 Sourced (T)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default wardTonnesSourcedLayer;

