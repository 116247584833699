import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';


const firebaseConfig = {
  apiKey: "AIzaSyBW2U_8xztPOGbt0cpEFLOl6QhREkYaXaQ",
  authDomain: "fps-engine.firebaseapp.com",
  databaseURL: "https://fps-engine-default-rtdb.firebaseio.com",
  projectId: "fps-engine",
  storageBucket: "fps-engine.appspot.com",
  messagingSenderId: "443347113311",
  appId: "1:443347113311:web:82195973250682e8fe92ea",
  measurementId: "G-802R5PHDV4"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const catchFirebaseAuthError = (errorType) => {
  console.log(errorType);
  const errorArray = [];
  switch (errorType) {
    case 'auth/invalid-email':
      errorArray.push({ message: 'Invalid email', field: '' });
      break;
    case 'auth/user-not-found':
      errorArray.push({
        message: 'No account with that email was found',
        field: '',
      });
      break;
    case 'auth/wrong-password':
      errorArray.push({
        message: 'Email or password was incorrect',
        field: '',
      });
      break;
    case 'auth/network-request-failed':
      errorArray.push({
        message: 'Network error. Please check your connection',
        field: '',
      });
      break;
  }

  return errorArray;
};

export default firebase;
