<template>
  <div class="container">
    <div class="menu-header">
      <h4 class="caption">Add New Factory</h4>
      <i class="fa fa-close close-icon" @click="closeAddMarkerDialogueBox"></i>
    </div>

    <form @submit="handleSelectedMarker">
      <div class="input-group">
        <input
          type="radio"
          id="factory"
          name="marker"
          value="Factory Marker"
          @change="onChange($event)"
        />
        <label for="factory">Releaf Factory</label>
      </div>

      <div class="input-group">
        <input
          type="radio"
          id="warehouse"
          name="marker"
          value="AP/Warehouse Marker"
          @change="onChange($event)"
        />
        <label for="warehouse">Releaf AP/Warehouse</label>
      </div>

      <div class="input-group">
        <input
          type="radio"
          id="customer"
          name="marker"
          value="Customer Factory"
          @change="onChange($event)"
        />
        <label for="customer">Customer Factory</label>
      </div>

      <div class="input-group">
        <input
          type="radio"
          id="logisticsDepot"
          name="marker"
          value="Logistics Depot"
          @change="onChange($event)"
        />
        <label for="logisticsDepot">Logistics Depot</label>
      </div>

      <div class="button-wrapper">
        <button
          :class="validate ? 'disable' : 'continue-btn'"
          :disabled="validate"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SelectMarkerForm",
  data() {
    return {
      showAddMarkerMenu: null,
      selectedMarkerType: "",
    };
  },

  computed: {
    validate() {
      return this.selectedMarkerType === "";
    },
  },

  methods: {
    onChange(event) {
      this.selectedMarkerType = event.target.value;
    },

    closeAddMarkerDialogueBox() {
      this.showAddMarkerMenu = false;
      this.$emit("onCloseSelectForm", this.showAddMarkerMenu);
    },

    handleSelectedMarker(e) {
      e.preventDefault();
      //emit to another component
      this.$emit("onSelectMarkerType", this.selectedMarkerType);
      this.showAddMarkerMenu = false;
      this.$emit("onCloseSelectForm", this.showAddMarkerMenu);

      // this.$store.dispatch('markers/getAssetToBeUpdated', {});
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0px;
  background: #fff;
}

.menu-header {
  display: flex;
  position: relative;
  padding: 0.7rem 0.5rem;
}

.menu-header::before {
  content: "";
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  height: 1px;
  width: 93%; /* or 100px */
  border-bottom: 1px solid rgb(214, 210, 210);
}

.close-icon {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}

.caption {
  font-weight: 900;
}

.input-group {
  display: flex;
  align-items: center;
  padding: 7px;
  margin-bottom: 0px;
}

input {
  display: block;
  margin-right: 10px;
}

label {
  margin-bottom: 0px;
  font-size: 10px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.button-wrapper .continue-btn {
  background: #669933;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  width: 70%;
  padding: 10px;
  font-size: 10px;
}

.disable:disabled {
  background: #669933;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  width: 70%;
  padding: 10px;
  font-size: 10px;
  opacity: 0.8;
}
</style>
