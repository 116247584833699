export function searchActions (rAssets, RFTaggedMills, APIKeys) {
  const searchComponents = [
        {
          layer: rAssets,
          searchFields: ["name"],
          suggestionTemplate: "{name}, type: {type}",
          exactMatch: false,
          outFields: ["*"],
          name: "Factories",
          placeholder: "example: C1",
        },
        {
          layer: RFTaggedMills,
          searchFields: ["name"],
          suggestionTemplate: "{name}, type: {type}",
          exactMatch: false,
          outFields: ["*"],
          name: "Mill Ownwer",
          placeholder: "example: James Ufort",
        },
        {
          layer: RFTaggedMills,
          searchFields: ["RTM"],
          suggestionTemplate: "{RTM}, type: {type}",
          exactMatch: false,
          outFields: ["*"],
          name: "RTM Number",
          placeholder: "example: RTM-0001",
        },
        {
          name: "ArcGIS World Geocoding Service",
          placeholder: "example: Nuuk, GRL",
          apiKey: APIKeys,
          singleLineFieldName: "SingleLine",
          url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        },
    ]
    return {
      searchComponents, rAssets, RFTaggedMills, APIKeys
    }
}