import { salt_api_config } from "../../config/salt-api-config";
import { showMillMarker } from "../../utils/mills";

const state = () => ({
  mills: [],
  filteredMills: [],
  currentCommunityP1Prices: {},
  isFilterReset: false,
});

const getters = {
  getAllMillsData: (state) => state.mills,
  getCurrentCommunityP1Prices: (state) => state.currentCommunityP1Prices,
  getFilteredMills: (state) => state.filteredMills,
  getFilterStatus: (state) => state.isFilterReset,
};

const mutations = {
  saveAllMills(state, mills) {
    state.mills = mills;
  },

  saveFilteredMills(state, mills) {
    state.filteredMills = mills;
  },

  saveCurrentP1Prices(state, prices) {
    state.currentCommunityP1Prices = prices;
  },

  saveFilterStatus(state, status) {
    state.isFilterReset = status;
    console.log(state);
  },
};

const actions = {
  async fetchMills({ commit }) {
    try {
      const resp = await salt_api_config.get("/purchases/mills-purchase-aggregates");

      let mills = resp.data;

      mills = mills.filter((mill) => {
        if (Boolean(mill.location)) {
          return mill;
        }
      });


      if (mills.length) await commit("saveAllMills", mills);


      return mills;
    } catch (error) {
      console.log(error);
      console.log("error in fetching the mills data");
    }
  },

  async fetchCurrentP1Prices({ commit }, query) {
    try {
      const resp = await salt_api_config.get(`/purchases/lga/current-p1-price?${query}`);

      const price = resp.data;

      if (Object.keys(price).length)
        await commit("saveCurrentP1Prices", price);
        return price;

    } catch (error) {
      console.log(error);
      console.log("error in fetching the prices data");
    }
  },

  renderedFilteredMills(_, params) {
    if (params.mills.length === 0) params.view.graphics.removeAll();
    params.mills.forEach((mill) => {
      showMillMarker(mill, params.view);
    });
  },

  updateFilteredMills({ commit }, data) {
    commit("saveFilteredMills", data);
  },

  updateFilterStatus({ commit }, status) {
    commit("saveFilterStatus", status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};