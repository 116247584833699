<template>
  <div>
    <div class="container" v-if="coordForm">
      <div class="">
        <p>Input the coordinate values below:</p>
        <i class="fa fa-close close-icon" @click="closeCoordinateForm"></i>

        <form @submit="handleSelectedMarker">
          <div class="input-wrapper">
            <div class="input-group">
              <label for="latitude">Lat:</label>
              <input
                type="text"
                id="latitude"
                name="latitude"
                v-model="latitude"
              />
            </div>

            <div class="input-group">
              <label for="longitude">Long:</label>
              <input
                type="text"
                id="longitude"
                name="longitude"
                v-model="longitude"
              />
            </div>
          </div>
          <div class="button-wrapper">
            <button
              :class="isFormEmpty ? 'disable' : 'continue-btn'"
              :disabled="isFormEmpty"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoordinateForm",

  data() {
    return {
      latitude: "",
      longitude: "",
      showCoordinateForm: null,
      coordForm: true,
    };
  },

  methods: {
    handleSelectedMarker(e) {
      e.preventDefault();
      this.showCoordinateForm = false;
      this.$emit("onOpenSelectMarkerForm", this.showCoordinateForm);
      this.$store.dispatch("markers/retrieveClickedLocation", {
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
      });
    },

    closeCoordinateForm() {
      this.coordForm ? (this.coordForm = false) : (this.coordForm = true);
    },
  },

  computed: {
    updatedXY() {
      return this.$store.getters["serviceArea/getServiceAreaXY"];
    },
    // clickedLocation() {
    //   return this.$store.getters["markers/getClickedLocation"];
    // },

    isFormEmpty() {
      const bol = Boolean(this.latitude) && Boolean(this.longitude);
      return !bol;
    },
  },

  watch: {
    updatedXY(newValue) {
      console.log(newValue);
      // update input field with the clicked map location
      this.latitude = newValue.y.toFixed(4).toString();
      this.longitude = newValue.x.toFixed(4).toString();
    },
  },
};
</script>

<style scoped>
.container {
  background: #fff;
  border: 1px solid #ababab;
  width: 20rem;
  padding: 5px;
  border-radius: 5px;
  position: fixed;
  display: block;
  top: 440px;
  left: 60px;
  z-index: 9980;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.center {
  margin: 2px auto;
}

p {
  color: #333333;
  font-size: 10px;
  margin-bottom: 8px;
}

.close-icon {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 100%;
}

.input-wrapper {
  /* border: 1px solid blue; */
  display: flex;
  padding: 1px;
  margin-bottom: 10px;
  width: 100%;
}

.input-group {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  flex: 70%;
}

.input-group input {
  width: 75%;
  height: 100%;
  display: block;
  outline: none;
  border: 1px solid #ababab;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 5px;
  text-align: center;
}

label {
  display: inline;
  margin-bottom: 0px;
  color: #333333;
  /* margin-left: 2px; */
}

.button-wrapper {
  display: flex;
  margin-left: auto;
  padding-right: 5px;
}

.button-wrapper .continue-btn {
  color: #fff;
  background-color: #669933;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
}

.disable:disabled {
  color: #fff;
  background-color: #669933;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  opacity: 0.8;
}
</style>
