<template>
  <div>
    <TopNav />
    <div id="map" class="map_container"></div>
    <b-spinner
      v-if="loading"
      style="width: 5rem; height: 5rem"
      label="Large Spinner"
      class="spinner"
      type="grow"
    ></b-spinner>
    <div class="overlay" v-if="loading"></div>
  </div>
</template>

<script>
import leaflet from "leaflet";
import "leaflet.markercluster";
import "leaflet-control-geocoder";
import { mapState } from "vuex";
import * as EV from "esri-leaflet-vector";
import TopNav from "../../layouts/TopNav.vue";

export default {
  name: "CassavaMap",
  components: { TopNav },
  data() {
    return {
      map: null,
      geojson: null,
      loading: false,
      info: leaflet.control({ position: "topleft" }),
      legend: leaflet.control({ position: "bottomleft" }),
      cassavaGeoJSON: {},
    };
  },

  mounted() {
    this.displayMap();
    this.fetchCassavaData();
  },

  computed: mapState({
    products: (state) => state.cassava.all,
  }),

  methods: {
    loadStatesGeoJSON(fileUrl) {
      fetch(fileUrl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
        });
    },
    getColor(d) {
      return d > 3000
        ? "#800026"
        : d > 2500
        ? "#BD0026"
        : d > 2000
        ? "#E31A1C"
        : d > 1000
        ? "#FC4E2A"
        : d > 500
        ? "#FD8D3C"
        : d > 200
        ? "#FEB24C"
        : d > 100
        ? "#FED976"
        : "#FFEDA0";
    },

    style(feature) {
      return {
        fillColor: this.getColor(feature.properties.ANNUAL_MT_PRODUCED),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
      };
    },

    highlightFeature(e) {
      var layer = e.target;

      layer.setStyle({
        weight: 5,
        color: "#666",
        dashArray: "",
        fillOpacity: 0.7,
      });

      if (
        !leaflet.Browser.ie &&
        !leaflet.Browser.opera &&
        !leaflet.Browser.edge
      ) {
        layer.bringToFront();
      }

      this.info.update(
        layer.feature.properties,
        layer.feature.geometry.coordinates[0]
      );
    },

    resetHighlight(e) {
      this.geojson.resetStyle(e.target);
      this.info.update();
    },

    zoomToFeature(e) {
      this.map.fitBounds(e.target.getBounds());
    },

    onEachFeature(feature, layer) {
      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
        click: this.zoomToFeature,
      });
    },

    async created() {
      await this.$store.dispatch(
        "cassava/getCassavaData",
        "https://firebasestorage.googleapis.com/v0/b/fps-engine.appspot.com/o/geojsons%2Fng-state-cassava.json?alt=media&token=ad302fc7-958e-43c0-bca8-f5f0479e29ae"
      );
    },

    async fetchCassavaData() {
      try {
        const globalCassavaGeoJSON =
          this.$store.getters["cassava/getCassavaGeoJSON"];

        let geoJSON = {};

        if (Object.keys(globalCassavaGeoJSON).length === 0) {
          this.loading = true;

          geoJSON = await this.$store.dispatch(
            "cassava/getCassavaData",
            "https://firebasestorage.googleapis.com/v0/b/fps-engine.appspot.com/o/geojsons%2Fng-state-cassava.json?alt=media&token=ad302fc7-958e-43c0-bca8-f5f0479e29ae"
          );

          this.cassavaGeoJSON = geoJSON;
          this.loading = false;
        } else {
          this.cassavaGeoJSON = globalCassavaGeoJSON;
          this.loading = false;
        }
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    displayGeoJSON(data) {
      console.log(data);
      this.geojson = leaflet
        .geoJson(data, {
          style: this.style,
          onEachFeature: this.onEachFeature,
        })
        .addTo(this.map);
    },

    displayMap() {
      const apiKey =
        "AAPK00d89424430b4f1c88e6fb759d6f5ea4g_gQ3f2qYDbvz9SeM0V6UFLt1h5G25Z_UD-OoPDNaM9juBoJHQhLzw2rAeUIlPlr";

      const mapboxAccessToken =
        "pk.eyJ1IjoiaWNhbGlzdHVzZGV2IiwiYSI6ImNreWhldTFjdDB0YTMyb3AwbHFneW10dGsifQ.r90D3JjX7kes7A1RcUEEFA";

      const grayscale = leaflet.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
          mapboxAccessToken,
        {
          id: "mapbox/light-v9",
          attribution: "",
          tileSize: 512,
          zoomOffset: -1,
        }
      );

      const gray = EV.vectorBasemapLayer("ArcGIS:LightGray", {
        apikey: apiKey,
      });

      this.map = leaflet.map("map", {
        center: [8.0765, 7.3986],
        zoom: 6.3,
        zoomControl: false,
        maxZoom: 15,
        layers: [gray, grayscale],
      });

      leaflet.control.zoom({ position: "bottomright" }).addTo(this.map);

      leaflet.control.scale().addTo(this.map);

      leaflet.Control.geocoder({ position: "topleft" }).addTo(this.map);

      this.info.onAdd = function () {
        this._div = leaflet.DomUtil.create("div", "info"); // create a div with a class "info"
        this.update();
        return this._div;
      };

      // method that we will use to update the control based on feature properties passed
      this.info.update = function (props) {
        this._div.innerHTML =
          "<h4>State Detail</h4>" +
          (props
            ? "<b>STATE</b><br>" +
              props.NAME_1 +
              "<br><br> <b>ANNUAL HA PLANTED(x1000)</b><br>" +
              props.ANNUAL_HA_PLANTED +
              "<br><br> <b>ANNUAL MT PRODUCED(x100)</b><br>" +
              props.ANNUAL_MT_PRODUCED +
              "<br><br> <b>MEAN YIELD(MT/HA)</b><br>" +
              props.MEAN_YIELD +
              "<br><br> <b>RANK</b><br>" +
              props.RANK
            : "Hover over a state");
      };
      this.info.addTo(this.map);

      this.legend.onAdd = () => {
        let div = leaflet.DomUtil.create("div", "info legend"),
          grades = [0, 100, 200, 500, 1000, 2000, 2500, 3000];

        // loop through our density intervals and generate a label with a colored square for each interval
        for (var i = 0; i < grades.length; i++) {
          div.innerHTML +=
            '<i style="background:' +
            this.getColor(grades[i] + 1) +
            '"></i> ' +
            grades[i] +
            (grades[i + 1] ? "&ndash;" + grades[i + 1] + "<br>" : "+");
        }

        return div;
      };

      this.legend.addTo(this.map);
    },
  },
  watch: {
    cassavaGeoJSON(newValue) {
      this.displayGeoJSON(newValue);
    },
  },
};
</script>

<style>
/* styling to make the map take the full screen */
#map {
  height: 100vh;
  width: 100%;
}

.spinner {
  position: absolute;
  top: 35%;
  left: 47%;
  z-index: 9999;
  color: #669933;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.651);
  backdrop-filter: blur(3px);
  z-index: 9990;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
