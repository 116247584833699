// import {clusterConfig} from "@/views/mills/clusterConfig";
import {baseURL} from "@/config/keys";
import {liveInventory} from "@/views/mills/data/featureLayersDetails";
import DispatchCalculator from "@/utils/dispatch/dispatch-calculator";
import store from "@/store";

const p1AtField = {
    url: baseURL+ "inventories/inventory-at-mills?isGeoJSON=yes",
    title: "P1 Purchases (Field)",
    renderer: liveInventory.renderer,
    popupTemplate: {
        title: "P1 at - {millName}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "tonnesPurchased",
                        label: "Tonnes Purchased",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "pricePerTonne",
                        label: "Price per Tonne",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "amountPaid",
                        label: "Amount Paid",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "estimatedLogisticCostPerTonne",
                        label: "Estimated Logistic CostPer Tonne",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "estimatedLandedCostPerTonne",
                        label: "Estimated Landed Cost Per Tonne",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },

                    {
                        fieldName: "recommendedPickupVehicle",
                        label: "Recommended Pickup Vehicle",
                        visible: true,
                    },
                    {
                        fieldName: "Pickup Date",
                        label: "Pickup Date",
                        visible: true,
                    },
                    {
                        fieldName: "daysSincePurchase",
                        label: "Days Since Purchase",
                        visible: true,
                    },
                    {
                        fieldName: "agentName",
                        label: "Agent Name",
                        visible: true,
                    },
                    {
                        fieldName: "latitude",
                        label: "Latitude",
                        visible: true,
                    },
                    {
                        fieldName: "longitude",
                        label: "Longitude",
                        visible: true,
                    },
                    {
                        fieldName: "purchaseDate",
                        label: "Purchase Date",
                        visible: true,
                    },

                ],
            },
        ],
    },
}
export default p1AtField;

export function showP1InField(eventItem, sliderTonnesAtField, Slider, sliderDaysSincePurchase, gtForDaySinceLastPurchase, ltForDaySinceLastPurchase, gtForTonnesPurchased, ltForTonnesPurchased, pickupVehicleCheckboxes, agentsDropdownList, p1AtFieldReset, sliderResetP1AtField, handleShowP1Summary) {
    eventItem.visible = false;
    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", ()=>{
        handleShowP1Summary()

    })

    let dispatchCalculator = new DispatchCalculator();
    eventItem.visible = true;
    sliderTonnesAtField = new Slider({
        min: 0,
        max: 15,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });

    sliderDaysSincePurchase = new Slider({
        min: 0,
        max: 20,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });

    //sign function - ForsliderDaysSincePurchase
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span")
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round"
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtForDaySinceLastPurchase();
        } else {
            ltForDaySinceLastPurchase();
        }
    })

    //sign function - ForTonnesPurchased
    let switch2 = document.createElement("label");
    let input2 = document.createElement("input");
    let span2 = document.createElement("span")
    switch2.className = "switch";
    input2.type = "checkbox";
    span2.className = "slider round"
    switch2.appendChild(input2);
    switch2.appendChild(span2);
    input2.checked = true;


    input2.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtForTonnesPurchased();
        } else {
            ltForTonnesPurchased();
        }
    });

    // Create a dropdown list (agentDropdownList element)
    let agentDropdownList = document.createElement('select');
    agentDropdownList.style.border = '1px solid #000'; // Add a border to the dropdown list

    let summaryContainer = document.createElement('summaryContainer');
    summaryContainer.innerHTML = ``;
    let agentSummary;
    let unmovedStock;
    agentDropdownList.addEventListener('change', async (event) => {
        const selectedIndex = event.target.selectedIndex - 1; // Subtract 1 to account for the default option

        const agentsSummary = store.state.inventory.inventoriesSummaryPerAgent;
        const unmovedStockUnfiltered = store.state.inventory.inventoriesInField;

        agentSummary = await agentsSummary.find((summary) => summary.agentName === agents[selectedIndex]);
        unmovedStock = await unmovedStockUnfiltered.filter((stock) => stock.agentName === agents[selectedIndex]);

        if (selectedIndex >= 0) {
            agentsDropdownList(selectedIndex);

            if (agentSummary) {

                summaryContainer.innerHTML = `
      <h3><strong>AGENT SUMMARY</strong></h3>
      <br>
      <p><strong>Unmoved Pile:</strong> ${agentSummary.totalTransactions}</p>
      <p><strong>Total Weight:</strong> ${agentSummary.tonnesPurchased}</p>
      <p><strong>Average Days in Field:</strong> ${agentSummary.daysSincePurchaseAverage} days</p>  
    `;
            } else {
                summaryContainer.textContent = "No unmoved Pile"
            }
        }
    });


    // Add a default option to the dropdown list
    let defaultOption = document.createElement('option');
    defaultOption.textContent = 'Filter by Agent';
    defaultOption.selected = true;
    defaultOption.disabled = true;
    agentDropdownList.appendChild(defaultOption);

    const agents = store.state.auth.agents;

    // console.log("Agents", agents)

    agents.forEach((label, index) => {
        let option = document.createElement('option');
        option.value = index;
        option.textContent = label;
        agentDropdownList.appendChild(option);
    });



    // Create checkbox with labels
    let vehicleCheckboxGroup = document.createElement('div');
    let checkboxLabels = ['Ford', 'Ford2', 'Dyna2', 'MB1', 'MB2'];

    let tripContainer = document.createElement('summaryContainer');
    tripContainer.innerHTML = ``;

    let tripContainer2 = document.createElement('summaryContainer');
    tripContainer2.innerHTML = ``;

    checkboxLabels.forEach((label, index) => {
        let optionWrapper = document.createElement('div');
        optionWrapper.className = 'checkbox-option';

        let checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.name = 'vehicleCheckboxGroup';
        checkbox.id = `checkbox${index}`;
        optionWrapper.appendChild(checkbox);

        let vehicleCheckboxLabel = document.createElement('label');
        vehicleCheckboxLabel.htmlFor = `checkbox${index}`;
        vehicleCheckboxLabel.textContent = label;
        vehicleCheckboxLabel.className = 'checkbox-label';
        vehicleCheckboxLabel.style.paddingLeft = '10px';
        optionWrapper.appendChild(vehicleCheckboxLabel);

        vehicleCheckboxGroup.appendChild(optionWrapper);
    });

    let selectedVehicleNames;

    vehicleCheckboxGroup.addEventListener('change', () => {

        const selectedCheckboxes = Array.from(document.querySelectorAll('input[name="vehicleCheckboxGroup"]:checked'));
        selectedVehicleNames = selectedCheckboxes.map(checkbox => checkbox.nextElementSibling.textContent);

        const tonnes = agentSummary ? agentSummary.tonnesPurchased : 0;

        let {
            miniTrips,
            costPerTon,
            trips,
            groupedTrip
        } = dispatchCalculator.calculateTrips(selectedVehicleNames, tonnes, unmovedStock);

        let tripSummary = dispatchCalculator.calculateTripSummary(selectedVehicleNames, tonnes)

        displayTripSummary(tripContainer, tripSummary);
        displayTripTimeline(tripContainer2, trips, costPerTon, groupedTrip);
        pickupVehicleCheckboxes();
    });

    const tonneInputBox = document.createElement('input');

    // Set the background color
    tonneInputBox.style.backgroundColor = 'white';
    tonneInputBox.style.color = 'black';
    tonneInputBox.placeholder = 'Enter tonnage';

    // Append the input element to the document body or any other parent element
    document.body.appendChild(tonneInputBox);

    //Reset button
    let resetButton = document.createElement('button');
    resetButton.className = "resetButton";
    resetButton.innerText = "Reset";
    resetButton.addEventListener("click", () => {
        p1AtFieldReset();
        sliderResetP1AtField()
    })

    //Logistics Summary
    let logisticSummaryButton = document.createElement('button');
    logisticSummaryButton.className = "resetButton";
    logisticSummaryButton.innerText = "Fetch Logistics Summary by Agent";
    logisticSummaryButton.addEventListener("click", () => {
        // p1AtFieldReset();
        // sliderResetP1AtField()
        const inputValue = tonneInputBox.value;

        let {
            miniTrips,
            costPerTon,
            trips,
            groupedTrip
        } = dispatchCalculator.calculateTrips(selectedVehicleNames, inputValue, unmovedStock);

        trips = dispatchCalculator.calculateTripSummary(selectedVehicleNames, inputValue)
        displayTripSummary(tripContainer, trips);

        displayTripTimeline(tripContainer2, trips, costPerTon, groupedTrip);

    })

    if (eventItem.layer.type != "group") {
        eventItem.panel = {
            content: [
                "legend",
                "Days since purchase",
                "&nbsp;",
                "Toggle off the switch to reverse the slider function to '< less than function'.",
                "&nbsp;",
                switch1,
                "&nbsp;",
                "&nbsp;",
                sliderDaysSincePurchase,
                "&nbsp;",
                "&nbsp;",
                "&nbsp;",
                "Tonnes at Field",
                "&nbsp;",
                switch2,
                "&nbsp;",
                "&nbsp;",
                sliderTonnesAtField,
                "&nbsp;",
                "&nbsp;",
                agentDropdownList,
                "&nbsp;",
                summaryContainer,
                "&nbsp;",
                "&nbsp;",
                "Pickup Vehicle",
                "&nbsp;",
                vehicleCheckboxGroup,
                "&nbsp;",
                "&nbsp;",
                tripContainer,
                "&nbsp;",
                "&nbsp;",
                tripContainer2,
                "&nbsp;",
                "&nbsp;",
                tonneInputBox,
                "&nbsp;",
                "&nbsp;",
                logisticSummaryButton,
                "&nbsp;",
                resetButton,
                "&nbsp;",
                divSummaryButton


            ],
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
        };
    }
    return {sliderTonnesAtField, sliderDaysSincePurchase};
}

function displayTripSummary(tripContainer, tripsData) {
    tripContainer.innerHTML = `
  <h3><strong>TRIP SUMMARY</strong></h3>
  <p><i>This Summary is for ALL unmoved piles by the selected agent</i></p>
  <br>

`;

    for (const vehicle in tripsData.trips) {
        const numberOfTrips = tripsData.trips[vehicle];
        const item = document.createElement("p");
        item.textContent = `${vehicle} => ${numberOfTrips} trips`;
        tripContainer.appendChild(item);
    }
}


function displayTripTimeline(tripContainer2, totalCost, costPerTon, groupedTrip) {
    tripContainer2.innerHTML = `
    <h3><strong>TRIP TIMELINE</strong></h3>
    <br>
    `;

    console.log("groupedTrip", groupedTrip)

    for (const [index, miniTrips] of groupedTrip.entries()) {

        console.log("Mini Trips", miniTrips)

        const vehicleName = miniTrips[0].vehicle;
        const vehicleHeading = document.createElement('h3');
        vehicleHeading.innerHTML = `<strong>${vehicleName} Timeline</strong>`;
        tripContainer2.appendChild(vehicleHeading);

        let currentHour = 8; // start work at 8am
        let currentMinute = 0;

        const ulElement = document.createElement("ul");
        tripContainer2.appendChild(ulElement);

        for (const trip of miniTrips) {
            const startHour = currentHour;
            const startMinute = currentMinute;

            currentHour += Math.floor(trip.time);
            currentMinute += Math.round((trip.time - Math.floor(trip.time)) * 60);

            if (currentMinute >= 60) {
                currentHour += Math.floor(currentMinute / 60);
                currentMinute = currentMinute % 60;
            }

            const endHour = currentHour;
            const endMinute = currentMinute;

            // If endHour is 20 or more, break the loop
            if (endHour >= 20) {
                break;
            }

            const listItem = document.createElement("li");
            const loadingItem = document.createElement("li");
            const driveTimeItem = document.createElement("li");
            const tripPickup = document.createElement("li");
            const truckLoad = document.createElement("li");

            const breakElement = document.createElement("br");
            listItem.style.listStyleType = "disc";
            loadingItem.style.fontSize = '0.8rem';
            driveTimeItem.style.fontSize = '0.8rem';
            truckLoad.style.fontSize = '0.8rem';
            tripPickup.style.fontSize = '0.8rem';
            listItem.textContent = `${startHour}:${startMinute < 10 ? '0' : ''}${startMinute} to ${endHour}:${endMinute < 10 ? '0' : ''}${endMinute} ${trip.route}`;
            driveTimeItem.textContent = `Driving Time =>  ${trip.driveTime.toFixed(2)} hr(s)`;
            tripPickup.textContent = `Pickup Quantity =>  ${trip.tripPickup.toFixed(2)} ton(s)`;
            truckLoad.textContent = `Truck Load =>  ${trip.truckLoad.toFixed(2)} ton(s)`;

            if ('loadingTime' in trip) {
                loadingItem.textContent = `Loading Time=>  ${trip.loadingTime} hr(s)`;
            } else {
                loadingItem.textContent = `Offloading Time =>  ${trip.offloadingTime} hr(s)`;
            }

            ulElement.appendChild(listItem);
            ulElement.appendChild(driveTimeItem);
            ulElement.appendChild(loadingItem)
            ulElement.appendChild(tripPickup);
            ulElement.appendChild(truckLoad);
            ulElement.appendChild(breakElement);

        }

        // Add two line breaks
        const breakElement1 = document.createElement("br");
        const breakElement2 = document.createElement("br");

        tripContainer2.appendChild(breakElement1);

        const costPerTonItem = document.createElement("p");
        const totalCostItem = document.createElement("p");
        costPerTonItem.textContent = `COST PER TON: ${ costPerTon[index].toFixed(2) }`;
        totalCostItem.textContent = `TOTAL COST: ${ totalCost[miniTrips[0].vehicle].cost.toFixed(2) }`;
        tripContainer2.appendChild(costPerTonItem);
        tripContainer2.appendChild(totalCostItem);
        tripContainer2.appendChild(breakElement2);

    }
}
