export const miniGridsData = [
  {
    SiteName: 'Abia',
    State: 'Cross Rivers',
    connOnground: '255',
    latitude: 8.915000134,
    longitude: 5.93861634,
  },
  {
    SiteName: 'ABOBENE',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 9.04682,
    longitude: 6.128022,
  },
  {
    SiteName: 'Agbaragba',
    State: 'Cross Rivers',
    connOnground: '211',
    latitude: 8.55,
    longitude: 6.035,
  },
  {
    SiteName: 'Agoi Ibami',
    State: 'Cross Rivers',
    connOnground: '934',
    latitude: 8.176398643,
    longitude: 5.724210236,
  },
  {
    SiteName: 'Alangkel',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 6.654,
    longitude: 8.797,
  },
  {
    SiteName: 'Aleibiri',
    State: 'Bayelsa',
    connOnground: '1202',
    latitude: 5.8717529,
    longitude: 5.0698457,
  },
  {
    SiteName: 'Alladim',
    State: 'Cross Rivers',
    connOnground: '305',
    latitude: 6.556,
    longitude: 8.719,
  },
  {
    SiteName: 'Amaku Igbodo',
    State: 'Rivers',
    connOnground: '250',
    latitude: 5.145,
    longitude: 7.119,
  },
  {
    SiteName: 'ARARAGHA 1 & 2',
    State: 'Cross Rivers',
    connOnground: '300',
    latitude: 8.39496,
    longitude: 6.13756,
  },
  {
    SiteName: 'Biajua',
    State: 'Cross Rivers',
    connOnground: '350',
    latitude: 9.005938,
    longitude: 6.003698,
  },
  {
    SiteName: 'Egbe Mbube',
    State: 'Cross Rivers',
    connOnground: '450',
    latitude: 6.548,
    longitude: 8.92,
  },
  {
    SiteName: 'Idema',
    State: 'Bayelsa',
    connOnground: '346',
    latitude: 6.460436111,
    longitude: 4.629602778,
  },
  {
    SiteName: 'Ijegu',
    State: 'Cross Rivers',
    connOnground: '230',
    latitude: 8.738771,
    longitude: 6.778137,
  },
  {
    SiteName: 'Ikibiri',
    State: 'Bayelsa',
    connOnground: '373',
    latitude: 6.2000906,
    longitude: 4.9131879,
  },
  {
    SiteName: 'Isu',
    State: 'Rivers',
    connOnground: '806',
    latitude: 7.016636111,
    longitude: 5.1418,
  },
  {
    SiteName: 'Itekpa',
    State: 'Cross Rivers',
    connOnground: '170',
    latitude: 8.63462,
    longitude: 6.73168,
  },
  {
    SiteName: 'Mgbumgangfom',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 6.36,
    longitude: 8.583,
  },
  {
    SiteName: 'Njemetop',
    State: 'Cross Rivers',
    connOnground: '160',
    latitude: 8.5147,
    longitude: 6.2086,
  },
  {
    SiteName: 'Ogbia Main Town',
    State: 'Bayelsa',
    connOnground: '1490',
    latitude: 6.31395,
    longitude: 4.685508333,
  },
  {
    SiteName: 'Ojerim',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 6.577,
    longitude: 8.953,
  },
  {
    SiteName: 'Okosura',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 6.038,
    longitude: 8.533,
  },
  {
    SiteName: 'Ologi',
    State: 'Bayelsa',
    connOnground: '180',
    latitude: 6.230247222,
    longitude: 4.794433333,
  },
  {
    SiteName: 'Opume',
    State: 'Bayelsa',
    connOnground: '112',
    latitude: 6.347816667,
    longitude: 4.659763889,
  },
  {
    SiteName: 'OSAKAN',
    State: 'Cross Rivers',
    connOnground: '250',
    latitude: 6.028,
    longitude: 8.383,
  },
  {
    SiteName: 'Toro Ndoro',
    State: 'Bayelsa',
    connOnground: '250',
    latitude: 5.597998,
    longitude: 5.0387828,
  },
  {
    SiteName: 'Emakalakala',
    State: 'Bayelsa',
    connOnground: '150',
    latitude: 6.340080556,
    longitude: 4.648713889,
  },
  {
    SiteName: 'Okoroba',
    State: 'Bayelsa',
    connOnground: '400',
    latitude: 8.8,
    longitude: 5.766667,
  },
  {
    SiteName: 'Ekowe',
    State: 'Bayelsa',
    connOnground: '560',
    latitude: 6.0861715,
    longitude: 4.700051,
  },
  {
    SiteName: 'Anuka/Akarban/Anyinka',
    State: 'Cross River',
    connOnground: '180',
    latitude: 8.944647,
    longitude: 6.780046,
  },
  {
    SiteName: 'Biakpan',
    State: 'Cross River',
    connOnground: '780',
    latitude: 7.933333,
    longitude: 5.583333,
  },
  {
    SiteName: 'Orwu',
    State: 'Rivers',
    connOnground: '200',
    latitude: 6.999294444,
    longitude: 5.216466667,
  },
  {
    SiteName: 'Umuoye',
    State: 'Rivers',
    connOnground: '300',
    latitude: 7.084605556,
    longitude: 5.363691667,
  },
  {
    SiteName: 'Onyenokpon',
    State: 'Cross River',
    connOnground: '200',
    latitude: 8.467241667,
    longitude: 5.980005556,
  },
];
