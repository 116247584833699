<template>
  <div class="custom-header__parent">
    <div class="wrapper">
      <h5>{{ moduleName }}</h5>

    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",

    data() {
      return {
        moduleName: this.$store.getters["layout/getClickedMenuItem"],
      };
    },

    computed: {
      clickedModule() {
        return this.$store.getters["layout/getClickedMenuItem"];
      },
    },

    watch: {
      clickedModule(newValue) {
        this.moduleName = newValue;
      },
    },
  };
</script>

<style scoped>
  .custom-header__parent {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 16px rgba(171, 171, 171, 0.18);
  }

  .wrapper {
    width: 94%;
    height: 95%;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #232a34;
  }

  .user-info__container {
    display: flex;
    align-items: center;
    width: 20%;
    height: 70%;
  }

  .user-info {
    /* width: 70%; */
    flex: 1;
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .name {
    margin-left: auto;
    height: 8px;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 27px;
    color: #669933;
  }

  .designation {
    margin-left: auto;
    height: inherit;
    height: 6px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #333333;
  }

  .user-avatar__container {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }
</style>
