import {wardAverageP1SourcedRenderer} from "@/views/mills/data/rendererWardLayers";
import {arcgisURL} from "@/config/api-config";

const wardAverageP1SourcedLayer = {
    title: "Average P1 Sourced",
    url: arcgisURL + "wardsTSourced/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: wardAverageP1SourcedRenderer,
    popupTemplate: {
        title: "{wardname}, {lganame} LGA",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "wardname",
                        label: "Ward Name",
                        visible: true,
                    },
                    {
                        fieldName: "comm",
                        label: "Name of Communities",
                        visible: true,
                    },
                    {
                        fieldName: "aveP1Pur",
                        label: "Average P1 Purchased",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default wardAverageP1SourcedLayer;

