<template>
  <div class="container">
    <div class="avatar-container">
      <i class="fa fa-user avatar-icon"></i>
    </div>
    <div class="info-container">
      <p class="user-email">{{ currentUserEmail }}</p>

      <p class="admin">
        Admin <i class="fa fa-sort-down down" @click="handleClick"></i>
      </p>
    </div>
    <div
      class="logout"
      v-if="showLogout"
      @click="handleLogout"
      @mouseleave="closeOptions"
    >
      Logout
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserControl',

  data() {
    return {
      showLogout: false,
      currentUserEmail: this.$store.getters['auth/currentUser'].email,
    };
  },

  methods: {
    handleClick() {
      this.showLogout = !this.showLogout;
    },

    handleLogout() {
      this.$store.dispatch('auth/setSignoutPromptStatus', true);
      this.showLogout = false;
    },

    closeOptions() {
      this.showLogout = false;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 120%;
  height: 32px;
  /* border: 1px solid red; */
  padding: 0px;
}

.avatar-container {
  flex: 20%;
  border: 1px solid #fff;
  background: #fff;
  padding: 0 3px;
  display: table;
}

.avatar-icon {
  font-size: 25px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 60%;
  height: 100%;
  font-size: 10px;
  justify-content: center;
  padding-left: 4px;
}

.logout {
  position: absolute;
  top: 35px;
  left: 30px;
  background: #fff;
  font-size: 10px;
  padding: 10px;
  width: 80%;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.47);
}

.user-email {
  font-weight: 600;
}

.admin {
  font-size: 8px;
  position: relative;
}

.down {
  position: absolute;
  left: 32px;
  cursor: pointer;
}
</style>
