<template>
  <div>
    <div v-show="showP1Summary" class="summary-board summary-shadow">
      <h1 class="text-center"><b>Summary Board</b></h1>
      <p @click="showContainerFx" class="close-x">X</p>

      <!-- P1 summary -->
      <div class="p1-summary">
        <p><b>P1 at Field Layer</b></p>
        <ul>
          <li>Total Tonnage: {{ this.layersObject.p1AtField }}</li>
          <li>
            Agents with Piles:
            <i
              ><small>{{ this.layersObject.agentsWithPiles }}</small></i
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
export default {
  name: "FpsClientLayersSummy",
  props: {
    showP1Summary: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      layersObject: {},
    };
  },

  mounted() {
    this.loadEsri();
  },

  computed: {},

  methods: {
    showContainerFx() {
      this.showP1Summary = !this.showP1Summary;
    },
    loadEsri() {
      loadModules(["esri/request"]).then(([esriRequest]) => {
        this.layersObject = {
          numberOfActiveLayers: [],
          p1AtField: 0,
          agentsWithPiles: "",
          millsWithPiles: "",
        };

        var toObjectLayer = this.layersObject;
        esriRequest(
          "https://api.v2.salt.releaf.ng/api/v2/inventories/inventory-at-mills?isGeoJSON=yes",

          {
            responseType: "json",
          }
        )
          .then((response) => {
            //Calculating tones ammount
            let p1AtFieldArray = response.data.features.map(
              (field) => field.properties.tonnesPurchased
            );
            let sum = 0;
            for (let i = 0; i < p1AtFieldArray.length; i++) {
              sum += p1AtFieldArray[i];
            }

            toObjectLayer.p1AtField = sum.toLocaleString("US-en");

            //Agents with Piles
            p1AtFieldArray = response.data.features.map((field) => {
              let properties = field.properties;
              let propertiesArray = [];
              propertiesArray.push(properties);

              for (let i = 0; i < propertiesArray.length; i++) {
                const result = propertiesArray[i];
                if (result.daysSincePurchase >= 7) {
                  return result.agentName;
                }
              }
            });
            const uniqueAgentName = [...new Set(p1AtFieldArray)];

            const uniqueAgentNameFiltered = uniqueAgentName.filter(
              (element) => element !== undefined
            );
            const stringListP1AtFieldArray = uniqueAgentNameFiltered
              .map(String)
              .join(", ");
            toObjectLayer.agentsWithPiles = stringListP1AtFieldArray;
          })
          .catch((error) => {
            console.error("Error loading layer metadata:", error);
          });
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.close-x {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.summary-board {
  padding: 40px;
  width: 500px;
  height: 300px;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  bottom: 100px;
  left: 70px;
}

.summary-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

ol,
ul {
  list-style-type: circle;
}
</style>