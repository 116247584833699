import {baseURL} from "@/config/keys";
import {p1AggRenderer} from "../../data/p1AggregateRenderer";

const p1Aggregates = {
    url:baseURL+ "inventories/total-inventory-at-mills?isGeoJSON=yes",
    title: "P1 Mill Aggregation",
    renderer: p1AggRenderer,
    
    popupTemplate: {
        title: "P1 at - {millName}",
        expressionInfos: [
            {
                name: "noOfTxn",
                title: "Total number of transactions",
                expression: `
                  function calcNoOfTxn(){
                    var averageNoOfTxn = $feature.amountPaid / $feature.pricePerTonne
                    return averageNoOfTxn
                  }
                  calcNoOfTxn();
                `
            },
        ],
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "millName",
                        label: "Mill Ownwer",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "tonnesPurchased",
                        label: "Total Amount of P1 Available for Pickup",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "daysSincePurchase",
                        label: "Average days since purchase",
                        visible: true,
                    },
                    {
                        fieldName: "totalTransactions",
                        visible: true,
                        label: "Total number of transactions"
                    },
                    {
                        fieldName: "agents",
                        label: "Names of agents with piles",
                        visible: true,
                    },

                ],
            },
        ],
    },
}

export default p1Aggregates;

