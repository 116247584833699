export const p1AggRenderer = {
    type: "class-breaks",
    field: "daysSincePurchase",
    legendOptions: {
        title: "Days ago"
    },
    classBreakInfos: [
        {
            minValue: 0,
            maxValue: 3,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#70dbdb",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "3 days and below"
        },
        {
            minValue: 3,
            maxValue: 7,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#a6db70",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "Less than 7days"
        },
        {
            minValue: 7,
            maxValue: 14,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#dbc170",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "Less thank 14 days"
        },
        {
            minValue: 21,
            maxValue: 1000,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#db7070",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "21 days and above"
        }
    ],
    visualVariables: [
        {
            type: "size", // indicates this is a size visual variable
            field: "tonnesPurchased", // total population in poverty
            outline: {
                color: "rgba(128, 0, 0, 1)",
                width: 2,
            }, legendOptions: {
                title: "Tonnage bought",
            },
            stops: [
                {
                    value: 10, // features where < 15% of the pop is in poverty
                    size: 15, // will be assigned a marker with this size in pts
                    label: "10T and below" // label to display in the legend
                },
                {
                    value: 20, // features where < 15% of the pop is in poverty
                    size: 25, // will be assigned a marker with this size in pts
                    label: "10T - 20T" // label to display in the legend
                },
                {
                    value: 30, // features where < 15% of the pop is in poverty
                    size: 35, // will be assigned a marker with this size in pts
                    label: "20T - 30T" // label to display in the legend
                },
                {
                    value: 1000, // features where > 35% of the pop is in poverty
                    size: 45, // will be assigned a marker with this size in pts
                    label: "30T and above" // label to display in the legend
                },
            ]
        }
    ]
}