<template>
  <div>
    <div class="light-overlay" v-if="loading"></div>
  </div>
</template>

<script>
export default {
  name: 'LightOverlay',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.light-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.651);
  backdrop-filter: blur(3px);
  z-index: 9990;
}
</style>
