import {clusterConfig} from "@/views/mills/clusterConfig";
import {arcgisURL} from "@/config/api-config";

const miniGridLayer = {
    title: "Minigrids",
    url: arcgisURL + "Renewvia_pipeline_development_sites/FeatureServer",
    copyright: "",
    featureReduction: clusterConfig,
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-marker",
            size: 15,
            color: "#b5838d",
            outline: {
                color: "rgba(245, 239, 240, 0.5)",
                width: 2,
            },
        },
    },
    popupTemplate: {
        title: "Minigrids - {Site_Name}, {State}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "State",
                        label: "State",
                        visible: true,
                    },
                    {
                        fieldName: "Site_Name",
                        label: "Site Name",
                        visible: true,
                    },
                    {
                        fieldName: "F_of_Connections_on_ground",
                        label: "No of Ground Connections",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default miniGridLayer;

