import {arcgisURL} from "@/config/api-config";

const protectedConservedArea = {
    title: "Potential Deforestation Encroachment ",
    url: arcgisURL + "conserved/FeatureServer",
    opacity: 0.5,
    copyright: "",

    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            color: "rgba(255, 0, 84, 1)",
            outline: {
                color: "rgba(10, 41, 37, 10%)",
                width: 0.2,
            },
        },
    },
    popupTemplate: {
        title: "Oil Palm Cluster - {hectare} (ha)",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "layer",
                        label: "State",
                        visible: true,
                    },
                    {
                        fieldName: "hectare",
                        label: "Area Size (ha)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}

export default protectedConservedArea;