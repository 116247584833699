import axios from "axios";
import {getTokenFromCookie} from "@/helpers/middleware/cross_sharing";


let fpsBaseURL;
if (
    window.location.hostname === 'fps.releaf.ng' ||
    window.location.hostname === 'www.fps.releaf.ng'
) {
    fpsBaseURL = "https://api.fps.releaf.ng";
} else {
    fpsBaseURL = "https://api.fps.releaf.ng"
}
const axios_base_config_site = axios.create({
    baseURL: fpsBaseURL,
    headers: {
        Authorization: getTokenFromCookie(),
    },
});

export default axios_base_config_site;

export const reverseGeocoding = "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";
export const factoryURL = "https://api.fps.releaf.ng/api/marker-assets/filter?markerType=Factory";
export const apURL = "https://api.fps.releaf.ng/api/marker-assets/filter?markerType=AP";
export const customersURL = "https://api.fps.releaf.ng/api/customers";
export const logisticsDepotURL = "https://api.fps.releaf.ng/api/LogisticDepot"
export const arcgisURL = "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/";
export const oilPalmClusterURL = "https://firebasestorage.googleapis.com/v0/b/fps-engine.appspot.com/o/geojsons%2Fclusters.json?alt=media&token=0cf28a0a-a83a-4238-93c1-17c6306ec5fb";
export const oilPalmCustomerURL = "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/oilPalmPF/FeatureServer"

export const ID_SERVICE_BASE_URL = "https://api.id.releaf.ng/api"

export const BASE_URL_SALT = "https://api.v2.salt.releaf.ng/api/v2";