<template>
  <a href="/map/admin/factories"
    ><div class="admin" v-b-tooltip.hover.right="'SITE Dashboard'"></div
  ></a>
</template>
<script>
export default {
  name: "Admin",
  data() {
    return {};
  },
  computed: {},
};
</script>
<style scoped>
.admin {
  width: 34px;
  height: 35px;
  background-image: url("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSYQDXmYQniu4CA_vOv06j2O1SAfZkXo1cKFsIZptnypBSSH7vW");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  position: absolute;
  top: 484px;
  left: 14px;
  z-index: 9980;
  cursor: pointer;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.admin button {
  padding: 5px;
}
</style>