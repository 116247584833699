import {
  computeMinigridSummary,
  computeOilPalmPFSummary,
  getMillsSummary,
  computeTotalAcres,
  profitPerWeek,
} from "../../../utils/serviceArea";
import { roundToTwoDP } from "../../../helpers";
import { miniGridsData } from "../../../data/miniGrids";
import { oilPalmPFData } from "../../../data/oilPalmPF";

import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import ServiceAreaParameters from "@arcgis/core/rest/support/ServiceAreaParameters";

const createServiceAreaParams = (params) => {
  const featureSet = new FeatureSet({
    features: [params.locationGraphic],
  });

  const taskParameters = new ServiceAreaParameters({
    facilities: featureSet,
    defaultBreaks: params.driveTimeCutoffs,
    trimOuterPolygon: true,
    outSpatialReference: params.outSpatialReference,
  });

  return taskParameters;
};

const saColors = [
  "rgba(0, 0, 255,.30)",
  "rgba(158, 42, 43,.30)",
  "rgba(0, 204, 0,.30)",
  "rgba(109, 89, 122,.30)",
  "rgba(230, 230, 0,.30)",
];

const state = () => ({
  serviceAreas: [],
  serviceAreaCount: 0,
  serviceAreaLGA: '',
});

const getters = {
  getServiceAreaAttributes: (state) => state.serviceAreas,
};



const mutations = {
  setServiceAreaAttributes(state, attribute) {
    state.serviceAreas.push(attribute);
  },
  incrementServiceAreaCount(state) {
    state.serviceAreaCount += 1;
  },
  setServiceAreaLGA(state, StartingArea){
    state.serviceAreaLGA = StartingArea
  }
};

const actions = {
  setServiceAreaLGA({commit}, serviceAreaLGA){
    commit('setServiceAreaLGA', serviceAreaLGA)
  },
  
  async drawServiceArea({ rootGetters, commit, state }, params) {
    
    if (state.serviceAreaCount > 4) {
      alert("Maximum service exceeded");
      return;
    }
    const logisticsCostTime = roundToTwoDP(params.divideServiceAreaValue * 83);
    const mills = rootGetters["mills/getAllMillsData"];
    const oilpalmClusters = rootGetters["oilpalmClusters/getOilPalmClusters"];

    const driveTimeCutoffs = [0, 0, 0]; //Minutes;
    driveTimeCutoffs[0] = Math.round(params.divideServiceAreaValue / 3);
    driveTimeCutoffs[1] = Math.round(driveTimeCutoffs[0] * 2);
    driveTimeCutoffs[2] = Math.round(driveTimeCutoffs[0] + driveTimeCutoffs[1]);

    const paramsObj = {
      driveTimeCutoffs: driveTimeCutoffs,
      outSpatialReference: params.outSpatialReference,
      locationGraphic: params.locationGraphic,
    };

    createServiceAreaParams(paramsObj);

    const serviceAreaSummary = {
      StartingArea: '',
      noOfMills: 0,
      totalTonsBought: 0,
      totalNoOfTransactions: 0,
      logisticsCost: logisticsCostTime,
      avgPricePerTonne: 0,
      totalAcres: 0,
      weeklyP1Yield: 0,
      weeklyFFBYield: 0,
      minigridsTotal: 0,
      oilPalmPFTotal: 0,
      color: "",
      profit_week: 0,
    };

    
    const serviceAreaUrl =
      "https://route-api.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World/solveServiceArea";

    try {
      const { serviceAreaPolygons } = await params.serviceArea.solve(
        serviceAreaUrl,
        createServiceAreaParams(paramsObj)
        
      );

      const graphic = serviceAreaPolygons[0];
      

      // console.log(graphic)
      const width = graphic.geometry.extent.width;

      const millsSummary = await getMillsSummary(
        mills,
        params.serviceAreaStartingPoint,
        width
      );

      const totalAcres = computeTotalAcres(
        oilpalmClusters,
        params.serviceAreaStartingPoint,
        width
      );

      const minigridSummary = computeMinigridSummary(
        miniGridsData,
        params.serviceAreaStartingPoint,
        width
      );

      const oilPalmPFSummary = computeOilPalmPFSummary(
        oilPalmPFData,
        params.serviceAreaStartingPoint,
        width
      );

      serviceAreaSummary.noOfMills = millsSummary.count;
      serviceAreaSummary.totalTonsBought = roundToTwoDP(
        millsSummary.totalTonsBought
      );
      serviceAreaSummary.totalNoOfTransactions =
        millsSummary.totalNoOfTransactions;
      serviceAreaSummary.avgPricePerTonne = isNaN(
        millsSummary.totalPrice / millsSummary.totalTonsBought
      )
        ? 0
        : roundToTwoDP(millsSummary.totalPrice / millsSummary.totalTonsBought);
      serviceAreaSummary.currentP1Price = millsSummary.currentP1Price;
      serviceAreaSummary.totalAcres = roundToTwoDP(totalAcres);

      //Implementation for Weekly P1 and FFB Yields
      serviceAreaSummary.weeklyP1Yield = roundToTwoDP(
        totalAcres * 0.024
      ).toLocaleString("en-US");
      serviceAreaSummary.weeklyFFBYield = roundToTwoDP(
        totalAcres * 0.08
      ).toLocaleString("en-US");

      serviceAreaSummary.minigridsTotal = minigridSummary.count;
      serviceAreaSummary.oilPalmPFTotal = oilPalmPFSummary.count;
      serviceAreaSummary.profit_week = profitPerWeek(
        serviceAreaSummary.totalAcres,
        serviceAreaSummary.currentP1Price,
        serviceAreaSummary.logisticsCost
      );

      graphic.symbol = {
        type: "simple-fill",
        color: saColors[state.serviceAreaCount],
      };

      graphic.attributes = {
        totalMills: serviceAreaSummary.noOfMills,
        totalTonsBought: serviceAreaSummary.totalTonsBought,
        totalNoOfTransactions: serviceAreaSummary.totalNoOfTransactions,
        logisticsCost: serviceAreaSummary.logisticsCost,
        avgPricePerTonne:
          serviceAreaSummary.avgPricePerTonne.toLocaleString("en-US"),
        totalAcres: serviceAreaSummary.totalAcres,
        weeklyP1Yield: serviceAreaSummary.weeklyP1Yield,
        weeklyFFBYield: serviceAreaSummary.weeklyFFBYield,
        minigridsTotal: serviceAreaSummary.minigridsTotal,
        oilPalmPFTotal: serviceAreaSummary.oilPalmPFTotal,
        currentP1Price: serviceAreaSummary.currentP1Price,
        profit_week: serviceAreaSummary.profit_week,
      };

      serviceAreaSummary.color = saColors[state.serviceAreaCount];

      saColors[state.serviceAreaCount];
  
      params.view.graphics.add(graphic, 0);

      commit("setServiceAreaAttributes", serviceAreaSummary);
      commit("incrementServiceAreaCount");
    } catch (error) {
      console.log(error);
      alert("Minimium input is 2 minutes, Maximium input is 399 minutes");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};