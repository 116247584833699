export const oilpalmSusRenderer = {
    type: "class-breaks",
    field: "sustainability",
    legendOptions: {
        title: "Sustainability Score"
    },
    classBreakInfos: [
        
        {
            minValue: 0,
            maxValue: 59,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#ffff3f",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "< 59"
        },
        {
            minValue: 60,
            maxValue: 69,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#d4d700",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "60 - 69"
        },
        {
            minValue: 70,
            maxValue: 79,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#aacc00",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "70 - 79"
        },
        {
            minValue: 80,
            maxValue: 89,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#55a630",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "80 - 89"
        },
        {
            minValue: 90,
            maxValue: 100,
            symbol: {
                type: "simple-marker", //simple-marker
                color: "#007f5f",
                outline: {
                    color: "#252422",
                    width: 1,
                },
            },
            label: "90 +"
        },
    ],
    
}