import {
  computeMinigridSummary,
  computeOilPalmPFSummary,
  getMillsSummary,
  computeTotalAcres,
  profitPerWeek,
} from "../../utils/serviceArea";
import { roundToTwoDP } from "../../helpers";
import { miniGridsData } from "../../data/miniGrids";
import { oilPalmPFData } from "../../data/oilPalmPF";

import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import ServiceAreaParameters from "@arcgis/core/rest/support/ServiceAreaParameters";
import axios from 'axios';

const createServiceAreaParams = (params) => {
  const featureSet = new FeatureSet({
    features: [params.locationGraphic],
  });

  const taskParameters = new ServiceAreaParameters({
    facilities: featureSet,
    defaultBreaks: params.driveTimeCutoffs,
    trimOuterPolygon: true,
    outSpatialReference: params.outSpatialReference,
  });

  return taskParameters;
};

const saColors = [
  "rgba(0, 0, 255,.60)",
  "rgba(158, 42, 43,.60)",
  "rgba(0, 204, 0,.60)",
  "rgba(109, 89, 122,.60)",
  "rgba(230, 230, 0,.60)",
];

const state = () => ({
  serviceAreas: [],
  serviceAreaCount: 0,
  serviceAreaLGA: '',
  serviceAreaXY: {},
  futurePrice: {},
  currentPrice: {},
});

const getters = {
  getServiceAreaAttributes: (state) => state.serviceAreas,
  getServiceAreaXY: (state) => state.serviceAreaXY,
  getFuturePrice: (state) => state.futurePrice,
  getCurrentPrice: (state) => state.currentPrice,
  getMapLayers: (state) => state.mapLayers,
  getMapView: (state) => state.mapView,
  getServiceAreaCount:(state) => state.serviceAreaCount
};

const mutations = {
  setServiceAreaAttributes(state, attribute) {
    state.serviceAreas.push(attribute);
  },
  incrementServiceAreaCount(state) {
    state.serviceAreaCount += 1;
  },
  setServiceAreaLGA(state, StartingArea){
    state.serviceAreaLGA = StartingArea
  },
  setServiceAreaXY(state, StartingArea){
    state.serviceAreaXY = StartingArea
  },
  saveFuturePrice(state, payload) {
    state.futurePrice = payload
  },
  saveCurrentPrice(state, payload) {
    state.currentPrice = payload
  }
};

const actions = {
  setServiceAreaLGA({commit}, serviceAreaLGA){
    commit('setServiceAreaLGA', serviceAreaLGA)
  },

  setServiceAreaXY({commit}, serviceAreaXY){
    commit('setServiceAreaXY', serviceAreaXY)
  },

  async setFuturePrice({commit}, date) {
    console.log(date)
    try {
      const response = await axios.get(`https://api.fps.releaf.ng/api/future-price/date?date=${date}`);
      const futurePriceJson = JSON.stringify(response.data)
      await commit('saveFuturePrice', futurePriceJson);
      return futurePriceJson;
      } catch (error) {
          console.log(error);
      }
  },

  async setCurrentPrice({commit}) {
    try {
      const response = await axios.get("https://api.fps.releaf.ng/api/current-price");
      const currentPriceJson = JSON.stringify(response.data)
      await commit('saveCurrentPrice', currentPriceJson);
      } catch (error) {
          console.log(error);
      }
  },

  async drawServiceArea({ rootGetters, commit, state }, params) {

      const logisticsCostTime = roundToTwoDP(params.divideServiceAreaValue * 83);
      const mills = rootGetters["mills/getAllMillsData"];
      const oilpalmClusters = rootGetters["oilpalmClusters/getOilPalmClusters"];

      const driveTimeCutoffs = [0, 0, 0]; //Minutes;
      driveTimeCutoffs[0] = Math.round(params.divideServiceAreaValue / 3);
      driveTimeCutoffs[1] = Math.round(driveTimeCutoffs[0] * 2);
      driveTimeCutoffs[2] = Math.round(driveTimeCutoffs[0] + driveTimeCutoffs[1]);

      const paramsObj = {
        driveTimeCutoffs: driveTimeCutoffs,
        outSpatialReference: params.outSpatialReference,
        locationGraphic: params.locationGraphic,
      };

      createServiceAreaParams(paramsObj);

      const serviceAreaSummary = {
        StartingArea: '',
        noOfMills: 0,
        totalTonsBought: 0,
        totalNoOfTransactions: 0,
        logisticsCost: logisticsCostTime,
        avgPricePerTonne: 0,
        totalAcres: 0,
        weeklyP1Yield: 0,
        weeklyFFBYield: 0,
        minigridsTotal: 0,
        oilPalmPFTotal: 0,
        color: "",
        profit_week: 0,
      };

      const serviceAreaUrl =
          "https://route-api.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World/solveServiceArea";

      try {
        const { serviceAreaPolygons } = await params.serviceArea.solve(
            serviceAreaUrl,
            createServiceAreaParams(paramsObj)
        );

        const graphic = serviceAreaPolygons[0];
        const width = graphic.geometry.extent.width;

        // Setup the graphic first without waiting for computations
        graphic.symbol = {
          type: "simple-fill",
          color: saColors[state.serviceAreaCount],
        };

        params.view.graphics.add(graphic, 0);

        params.closeModal();


        // Run promises in parallel
        const [
          millsSummary,
          totalAcres,
          minigridSummary,
          oilPalmPFSummary
        ] = await Promise.all([
          getMillsSummary(mills, params.serviceAreaStartingPoint, width),
          computeTotalAcres(oilpalmClusters, params.serviceAreaStartingPoint, width),
          computeMinigridSummary(miniGridsData, params.serviceAreaStartingPoint, width),
          computeOilPalmPFSummary(oilPalmPFData, params.serviceAreaStartingPoint, width)
        ]);

        serviceAreaSummary.noOfMills = millsSummary.count;
        serviceAreaSummary.totalTonsBought = roundToTwoDP(
            millsSummary.totalTonsBought
        ).toLocaleString("en-US");
        serviceAreaSummary.totalNoOfTransactions =
            millsSummary.totalNoOfTransactions;
        serviceAreaSummary.avgPricePerTonne = isNaN(
            millsSummary.totalPrice / millsSummary.totalTonsBought
        )
            ? 0
            : roundToTwoDP(millsSummary.totalPrice / millsSummary.totalTonsBought);
        serviceAreaSummary.currentP1Price = roundToTwoDP(millsSummary.currentP1Price).toLocaleString("en-US");
        serviceAreaSummary.totalAcres = roundToTwoDP(totalAcres).toLocaleString("en-US");
        serviceAreaSummary.logisticsCost = logisticsCostTime.toLocaleString("en-US");

        //Implementation for Weekly P1 and FFB Yields
        serviceAreaSummary.weeklyP1Yield = roundToTwoDP(
            totalAcres * 0.024
        ).toLocaleString("en-US");
        serviceAreaSummary.weeklyFFBYield = roundToTwoDP(
            totalAcres * 0.08
        ).toLocaleString("en-US");

        serviceAreaSummary.minigridsTotal = minigridSummary.count;
        serviceAreaSummary.oilPalmPFTotal = oilPalmPFSummary.count;
        serviceAreaSummary.profit_week = profitPerWeek(
            // serviceAreaSummary.totalAcres, is now a local string and therefore cannot be used here
            totalAcres,
            // serviceAreaSummary.currentP1Price, is now a local string and therefore cannot be used here
            millsSummary.currentP1Price,
            // serviceAreaSummary.logisticsCost, is now a local string and therefore cannot be used here
            logisticsCostTime
        );

        graphic.symbol = {
          type: "simple-fill",
          color: saColors[state.serviceAreaCount],
        };

        graphic.attributes = {
          totalMills: serviceAreaSummary.noOfMills,
          totalTonsBought: serviceAreaSummary.totalTonsBought,
          totalNoOfTransactions: serviceAreaSummary.totalNoOfTransactions,
          logisticsCost: serviceAreaSummary.logisticsCost,
          avgPricePerTonne:
              serviceAreaSummary.avgPricePerTonne.toLocaleString("en-US"),
          totalAcres: serviceAreaSummary.totalAcres,
          weeklyP1Yield: serviceAreaSummary.weeklyP1Yield,
          weeklyFFBYield: serviceAreaSummary.weeklyFFBYield,
          minigridsTotal: serviceAreaSummary.minigridsTotal,
          oilPalmPFTotal: serviceAreaSummary.oilPalmPFTotal,
          currentP1Price: serviceAreaSummary.currentP1Price,
          profit_week: serviceAreaSummary.profit_week,
        };

        console.log(graphic.attributes)

        serviceAreaSummary.color = saColors[state.serviceAreaCount];

        saColors[state.serviceAreaCount];
        const lgaName = state.serviceAreaLGA.split(',')[0]

        graphic.popupTemplate = {
          title: `Service Area Summary for ${lgaName}`,
          content: [
            {
              type: 'fields',
              fieldInfos: [
                {
                  fieldName: 'profit_week',
                  label: 'Estimated profit/Month (₦)',
                  visible: true,
                },
                {
                  fieldName: 'currentP1Price',
                  label: 'Current Price Per Tonne (₦)',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                  visible: true,
                },
                {
                  fieldName: 'avgPricePerTonne',
                  label: 'Average Historic Price Per Tonne (₦)',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                  visible: true,
                },
                {
                  fieldName: 'totalNoOfTransactions',
                  label: 'Total Transactions',
                  visible: true,
                },
                {
                  fieldName: 'totalTonsBought',
                  label: 'Total Tonnes Bought',
                  visible: true,
                },
                {
                  fieldName: 'totalMills',
                  label: 'Total Mills',
                  visible: true,
                },
                {
                  fieldName: 'logisticsCost',
                  label: 'Logistics Cost/T',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                  visible: true,
                },
                {
                  fieldName: 'totalAcres',
                  label: 'Total Acres (ha)',
                  visible: true,
                },
                {
                  fieldName: 'minigridsTotal',
                  label: 'Total Minigrids',
                  visible: true,
                },
                {
                  fieldName: 'oilPalmPFTotal',
                  label: 'Total Oil Palm Processing Facilities',
                  visible: true,
                },
                {
                  fieldName: 'weeklyP1Yield',
                  label: 'Weekly P1 Yield (Tonnes)',
                  visible: true,
                },
                {
                  fieldName: 'weeklyFFBYield',
                  label: 'Weekly FFB Yield (Tonnes)',
                  visible: true,
                },
              ],
            },
          ],
        };

        params.view.graphics.add(graphic, 0);
        params.closeModal();

        commit("setServiceAreaAttributes", serviceAreaSummary);
        commit("incrementServiceAreaCount");
      } catch (error) {
        console.log(error);
        alert("Minimum input is 2 minutes, Maximum input is 399 minutes");
        params.closeModal();
      }

  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};