<template>
  <div>
    <div class="login-page">
      <div class="inner-login">
        <div class="l-form">
          <div >
            <img src="../../../public/assets/img/farm.png" class="side_login">
        </div>
        <form class="form" @submit="logIn">
          <h2 class="form__title">Login</h2>
          <div class="form__div">
            <!-- <i class="fas fa-envelope icon"></i> -->
            <input
              id="email"
              type="email"
              v-model="email"
              class="form__input"
              placeholder=" "
            />
            <i class="fa fa-envelope lock" aria-hidden="true"></i>
            <label for="email" class="form__label">Email Address</label>
          </div>
          <div class="form__div">
            <!-- <i class="fas fa-lock icon"></i> -->
            <input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              class="form__input"
              placeholder=" "
            />
            <i class="fa fa-lock lock" aria-hidden="true"></i>
            <label for="password" class="form__label">Password</label>
            <i
              :class="showPassword ? 'fa fa-eye-slash icon' : 'fa fa-eye icon'"
              @click="togglePasswordState"
            ></i>
          </div>
          <a href="/reset" class="form__div">
            <p>Forgot Password?</p>
          </a>
          <div class="">
            <ul style="list-style: none">
              <li
                style="color: #cf6363; font-size: 12px"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ !error.field ? '' : `${error.field}:` }}
                {{ error.message }}
              </li>
            </ul>
          </div>
          <!-- <input type="submit" class="form__button" value="Login" /> -->
          <button
            type="submit"
            :class="isFormEmpty ? 'disable' : 'form__button'"
            :disabled="isFormEmpty"
          >
            Login
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        <p class="login_footer">&copy; Copyright {{currentYear}}. Releaf NG</p>
        </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase, { catchFirebaseAuthError } from '../../config/firebase';
import { id_auth_axios } from '../../config/id-service-api-config';
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      errors: [],
      showPassword: false,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isFormEmpty() {
      const isEmpty = Boolean(this.email) && Boolean(this.password);
      return !isEmpty;
    },
  },
  methods: {
    async logIn(e) {
      e.preventDefault();
      this.loading = true;
      this.errors = [];
      let errors = [];
      if (!this.email)
        errors.push({ message: 'Email is required', field: 'email' });
      if (!this.password)
        errors.push({ message: 'Password is required', field: 'password' });
      if (errors.length > 0) {
        this.errors = errors;
        this.loading = false;
        return;
      }
      try {
        const fireBaseRes = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        let authRes;
        if (fireBaseRes) {
          authRes = await id_auth_axios.post('/signin', {
            email: this.email,
            password: this.password,
          });
          if (authRes.data.data) {
            this.loading = false;
            const userId = authRes.data.data.user['_id'];
            console.log(userId);
            const token = authRes.data.data['token'];
            //set token in cookie
            document.cookie = `token=${token};path=/;max-age=36000`;
            this.$router.push({ name: 'Map' });
          }
        }
      } catch (error) {
        this.loading = false;
        errors = catchFirebaseAuthError(error.code);
        console.log(errors);
        if (errors.length > 0) {
          this.errors = errors;
          this.loading = false;
          return;
        }
        console.log(error);
      }
    },
    togglePasswordState() {
      this.showPassword = !this.showPassword;
      console.log(this.showPassword);
    },
  },
};
</script>
<style scoped>
h2 {
  margin: 0;
  font-weight: 900;
}
.icon {
  display: block;
  position: absolute;
  z-index: 2;
  top: 23px;
  right: 15px;
  font-size: 12px;
  width: 10px;
  height: 10px;
}
.lock{
  display: block;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 10px;
  font-size: 12px;
  width: 10px;
  height: 10px;
}
  /* ==== Background-color ==== */
.login-page{
  background: linear-gradient(0.4deg, #669933 0.21%, rgba(102, 153, 51, 0.88) 51.13%, rgba(102, 153, 51, 0) 99.51%);
}
.side_login{
    height: 555px;
    border-radius: 0px;
    padding-top: 5px;
}
.login_footer{
  padding-top: 80px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/*===== FORM =====*/
.l-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form {
  width: 360px;
  padding: 4rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(92, 99, 105, 0.2);
  height: 550px;
  background: #FFFFFF;
  border-radius: 0px 8px 8px 0px;
}
.form__title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 3rem;
}
.form__div {
  position: relative;
  height: 48px;
  margin-bottom: 1.5rem;
  display: flex;
}
div:last-of-type {
  margin-bottom: 0.3rem;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
}
.form__div p {
  margin-left: auto;
  font-size: 15px;
}
.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0.7rem;
  border: 1.5px solid #669933;
  border-radius: 0.5rem;
  outline: none;
  padding: 1.7rem;
  background: none;
  text-align: left;
  z-index: 1;
}
.form__label {
  position: absolute;
  left: 2rem;
  top: 1rem;
  padding: 0 0.25rem;
  background-color: #fff;
  color: #669933;
  font-size: 0.8rem;
  transition: 0.3s;
}
.form__button {
  display: block;
  /* margin-left: auto; */
  padding: 1.1rem 2rem;
  outline: none;
  border: none;
  background-color: #669933;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  text-align: center;
}
.disable:disabled {
  display: block;
  /* margin-left: auto; */
  padding: 1.1rem 2rem;
  outline: none;
  border: none;
  background-color: #669933;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  text-align: center;
  opacity: 0.8;
}
.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}
/*Input focus move up label*/
.form__input:focus + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: #669933;
  font-size: 0.75rem;
  font-weight: 300;
  z-index: 10;
}
/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus) + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 10;
}
/*Input focus*/
.form__input:focus {
  border: 1.5px solid #669933;
}
@media (max-width: 450px) {
  .side_login { display: none !important; }
}
</style>