class DispatchCalculator {
    constructor() {
        this.factoryLocation = {
            latitude: 5.4447948,
            longitude: 8.1537226
        };
        this.vehicleCapacities = {
            Ford: 2.5,
            Ford2: 2.8,
            Dyna2: 6,
            MB1: 10,
            MB2: 15,
        };
        this.offloadingTime = {
            Ford: 0.333,
            Ford2: 0.333,
            Dyna2: 0.4167,
            MB1: 0.1667,
            MB2: 1.25,
        };
        this.fuelEconomy = {
            Ford: 0.2,
            Ford2: 0.17,
            Dyna2: 0.37,
            MB1: 0.46,
            MB2: 1.25,
        };
        this.fuelCost = {
            Ford: 600,
            Ford2: 600,
            Dyna2: 740,
            MB1: 740,
            MB2: 740,
        };
        this.loadingCost = {
            Ford: 2500,
            Ford2: 1500,
            Dyna2: 2000,
            MB1: 4000,
            MB2: 6000,
        };
        this.loadingTimePerTonne = {
            Ford: 0.6,
            Ford2: 0.4634,
            Dyna2: 0.2917,
            MB1: 0.4211,
            MB2: 0.3666,
        };

    }


    calculateTrips(vehicleNames, totalTonnes, stockLocations) {
        const originalStockLocations = JSON.parse(JSON.stringify(stockLocations));

        let minitrips = [];//contains list of object of distance, time and fuel Cost
        const totalVehicleCapacity = vehicleNames.reduce((total, name) => {
            const capacity = this.vehicleCapacities[name];
            return total + capacity;
        }, 0);

        //Sort stockLocations by tonnes
        stockLocations.sort((a, b) => b.tonnesPurchased - a.tonnesPurchased);

        const tripsPerVehicle = {};
        const vehicleSpeed = 60; // km/h
        vehicleNames.forEach((name) => {
            const capacity = this.vehicleCapacities[name];
            let vehicleCurrentLoad = 0;
            let currentLocation = this.factoryLocation;
            let vehicleTonnes = Math.ceil(totalTonnes * (capacity / totalVehicleCapacity)); // Tonnes for each vehicle

            let totalTruckLoad = 0; // Initialize total truck load before starting trips
            //Calculate distance, time,truckLoad and fuel cost for each location
            stockLocations.forEach((location, index) => {
                while (vehicleTonnes > 0 && location.tonnesPurchased > 0) {
                    let tripPickup = Math.min(vehicleTonnes, location.tonnesPurchased, capacity - vehicleCurrentLoad);
                    totalTruckLoad += tripPickup; // Add current trip load to total truck load

                    vehicleTonnes -= tripPickup;
                    location.tonnesPurchased -= tripPickup;
                    vehicleCurrentLoad += tripPickup;
                    let loadingTime = this.loadingTimePerTonne[name] * tripPickup;
                    const distance = this.calculateDistance(currentLocation, location); // in km
                    const time = distance / vehicleSpeed + loadingTime; // in hours
                    const fuelConsumption = distance * this.fuelEconomy[name]; // in liters
                    const fuelCost = fuelConsumption * this.fuelCost[name]; // in local currency

                    minitrips.push({
                        vehicle: name,
                        distance: distance,
                        time: time,
                        driveTime: time - loadingTime,
                        fuelCost: fuelCost,
                        route: `Move to ${location.millName} Mill`,
                        loadingTime: loadingTime.toFixed(2),
                        tripPickup: tripPickup,
                        truckLoad: totalTruckLoad

                    });

                    if (vehicleCurrentLoad >= capacity) {
                        // If vehicle is filled up, return to factory
                        const returnDistance = this.calculateDistance(location, this.factoryLocation);
                        const returnTime = returnDistance / vehicleSpeed + this.offloadingTime[name];//return time + offloading time

                        const returnFuelConsumption = returnDistance * this.fuelEconomy[name];
                        const returnFuelCost = returnFuelConsumption * this.fuelCost[name];
                        totalTruckLoad = 0; // Reset total truck load

                        minitrips.push({
                            vehicle: name,
                            distance: returnDistance,
                            time: returnTime,
                            driveTime: returnTime - this.offloadingTime[name],
                            fuelCost: returnFuelCost,
                            route: "From " + location.millName + " Mill to " + "Factory(C2)",
                            offloadingTime: this.offloadingTime[name],
                            tripPickup: 0,
                            truckLoad: totalTruckLoad

                        });

                        vehicleCurrentLoad = 0;
                        currentLocation = this.factoryLocation;
                    } else if (index < stockLocations.length - 1) {
                        // If vehicle is not filled up, continue to the next location
                        currentLocation = location;
                    } else {
                        // If vehicle is not filled up and there are no more locations, return to factory
                        const returnDistance = this.calculateDistance(location, this.factoryLocation);
                        const returnTime = returnDistance / vehicleSpeed + this.offloadingTime[name];
                        const returnFuelConsumption = returnDistance * this.fuelEconomy[name];
                        const returnFuelCost = returnFuelConsumption * this.fuelCost[name];
                        totalTruckLoad = 0; // Reset total truck load

                        minitrips.push({
                            vehicle: name,
                            distance: returnDistance,
                            time: returnTime,
                            driveTime: returnTime - this.offloadingTime[name],
                            fuelCost: returnFuelCost,
                            route: "From " + location.millName + " Mill to " + "Factory(C2)",
                            tripPickup: 0,
                            offloadingTime: this.offloadingTime[name],
                            truckLoad: totalTruckLoad


                        });

                        vehicleCurrentLoad = 0;
                        currentLocation = this.factoryLocation;
                    }
                }
            });

            const costForVehicle = this.loadingCost[name] + minitrips.reduce((total, trip) => total + trip.fuelCost, 0);
            const tripsForVehicle = Math.ceil((totalTonnes * capacity) / totalVehicleCapacity);
            tripsPerVehicle[name] = {
                trips: tripsForVehicle,
                cost: costForVehicle
            };
        });

        const totalCost = Object.values(tripsPerVehicle).reduce((total, vehicle) => {
            return total + vehicle.cost;
        }, 0);

        stockLocations.forEach((location, index) => {
            location.tonnesPurchased = originalStockLocations[index].tonnesPurchased;
        });


        let groupedTrip = this.groupTripByVehicles(minitrips)

        return {
            costPerTon: this.calculateCostPerTone(groupedTrip),
            trips: tripsPerVehicle,
            miniTrips: minitrips,
            groupedTrip: groupedTrip
        };
    }

    calculateCostPerTone(groupedTrip) {
        let costPerToneArray = [];

        for (let i = 0; i < groupedTrip.length; i++) {
            let totalFuelCost = 0;
            let totalTruckLoad = 0;
            let vehicleName = groupedTrip[i][0].vehicle;

            for (let j = 0; j < groupedTrip[i].length; j++) {
                totalFuelCost += groupedTrip[i][j].fuelCost;
                totalTruckLoad += groupedTrip[i][j].truckLoad;
            }

            let costPerTone = (totalFuelCost + this.loadingCost[vehicleName]) / totalTruckLoad;
            costPerToneArray.push(costPerTone);
        }

        return costPerToneArray;
    }

    groupTripByVehicles(arr) {
        const grouped = arr.reduce((acc, obj) => {
            if (!acc[obj.vehicle]) {
                acc[obj.vehicle] = [];
            }
            acc[obj.vehicle].push(obj);
            return acc;
        }, {});

        return Object.values(grouped);
    }

    calculateTripSummary(vehicleNames, tonnes) {
        const totalVehicleCapacity = vehicleNames.reduce((total, name) => {
            const capacity = this.vehicleCapacities[name];
            return total + capacity;
        }, 0);

        const totalTrips = Math.ceil(tonnes / totalVehicleCapacity);

        const tripsPerVehicle = {};
        vehicleNames.forEach((name) => {
            const capacity = this.vehicleCapacities[name];
            const tripsForVehicle = Math.min(totalTrips, Math.ceil(tonnes / capacity));
            tripsPerVehicle[name] = tripsForVehicle;
            tonnes -= tripsForVehicle * capacity;
        });

        return {
            totalTrips,
            trips: tripsPerVehicle,
        };
    }

    // Add a helper function to calculate the Haversine distance
    calculateDistance(loc1, loc2) {
        const R = 6371; // Radius of the earth in km
        const lat1 = loc1.latitude;
        const lon1 = loc1.longitude;
        const lat2 = loc2.latitude;
        const lon2 = loc2.longitude;

        const dLat = this.deg2rad(lat2 - lat1);
        const dLon = this.deg2rad(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        return distance;
    }

// Add a helper function to convert degrees to radians
    deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

}

module.exports = DispatchCalculator;
