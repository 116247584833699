<template>
  <div>
    <button class="close-modal" @click="closeModal">&times;</button>
    <h2 class="form-title">Add New {{ modalTitle }}</h2>

    <form class="form" @submit="handleSubmit">
      <div class="input__container">
        <label for="name" class="form__label"
          >{{ getMarkerTypeName }} Name</label
        >
        <input
          id="name"
          type="text"
          class="form__input"
          placeholder=" "
          v-model="addMarkerForm.name"
        />
      </div>

      <!-- For customer factory -->
      <div v-if="modalTitle == 'Customer Factory'" class="form__div">
        <div class="input__container">
          <label for="phoneNumber" class="form__label"
            >{{ getMarkerTypeName }} Phone number(s)</label
          >
          <input
            id="phoneNumber"
            type="text"
            class="form__input"
            placeholder=" "
            v-model="addMarkerForm.phoneNumber"
          />
        </div>
        <p>Select Raw Material</p>
        <b-form-checkbox-group
          dropdownTitle="Raw Material"
          v-model="selected"
          :options="options"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
          class="select"
          value-field="item"
          text-field="name"
        ></b-form-checkbox-group>
      </div>

      <div v-if="modalTitle === 'Factory Marker'" class="form__div">
        <SelectDropdown
          :options="[
            'FFB Mill',
            'Crushing Mill',
            'Deshelling Mill',
            'Refinery',
            'Kraken II',
          ]"
          class="select"
          dropdownTitle="Type"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
        />
      </div>

      <div v-if="modalTitle === 'Customer Factory'" class="form__div">
        <SelectDropdown
          :options="[
            'Crushing Mill',
            'Deshelling Mill',
            'Refinery',
            'Kraken II',
            'Cement',
            'Other Industries'
          ]"
          class="select"
          dropdownTitle="Type"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
        />
      </div>

      <!-- For Releaf Factory -->
      <div v-if="modalTitle == 'Factory Marker'" class="form__div">
        <SelectDropdown
          :options="['FFB', 'P1', 'P2', 'PKO']"
          class="select"
          dropdownTitle="Raw Material"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
        />
      </div>

      <!-- For AP/Warehouse Marker -->
      <div v-if="modalTitle == 'AP/Warehouse Marker'" class="form__div">
        <SelectDropdown
          :options="['FFB', 'P1', 'P2', 'PKO']"
          class="select"
          dropdownTitle="Raw Material"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
        />
      </div>

      <!-- For Logistics Depot -->
      <div v-if="modalTitle == 'Logistics Depot'" class="input__container">
        <input
          id="cost"
          type="number"
          class="form__input"
          placeholder=" "
          v-model="customerPayLoad.costPMS"
        />
        <label for="cost" class="form__label"> PMS Cost</label>
      </div>

      <!-- For Logistics Depot -->
      <div v-if="modalTitle == 'Logistics Depot'" class="input__container">
        <input
          id="cost"
          type="number"
          class="form__input"
          placeholder=" "
          v-model="customerPayLoad.costAGO"
        />
        <label for="cost" class="form__label"> AGO Cost</label>
      </div>

      <div class="form__div">
        <SelectDropdown
          :options="['Active', 'Inactive', 'Underconstruction']"
          class="select"
          dropdownTitle="Status"
          @onInput="handleSelect"
          :reset="reset"
          @onReset="handleReset"
        />
      </div>

      <div class="btn-container">
        <button
          type="submit"
          :class="isFormEmpty ? 'dis' : 'add-marker-btn'"
          :disabled="isFormEmpty"
        >
          Add<span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import SelectDropdown from "../map/SelectDropdown.vue";

export default {
  name: "AddMarkerForm",

  components: {
    SelectDropdown,
  },

  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    markerFormAction: {
      type: String,
      required: false,
    },
    markerToBeUpdated: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      selected: [],
      options: [
        { item: "FFB", name: "FFB" },
        { item: "P1", name: "P1" },
        { item: "P2", name: "P2" },
        { item: "PKO", name: "PKO" },
        { item: "AGO", name: "PMS" },
      ],
      reset: false,
      showAddMarkerModal: false,
      loading: false,
      addMarkerForm: {
        name: "",
        rawMaterial: "",
        type: "",
        status: "..",
        createdBy: "admin",
      },
      logisticsPayload: {
        name: "",
        gas: [],
        status: "..",
        createdBy: "admin",
      },
      customerPayLoad: {
        name: "",
        phoneNumber: "",
        costPMS: "",
        costAGO: "",
        latitude: 0,
        longitude: 0,
        rawMaterials: ["string"],
        factoryType: "",
        status: "",
        createdBy: "admin",
      },
      APMarkerForm: {
        name: "",
        status: "",
        createdBy: "admin",
      },
    };
  },

  mounted() {},

  methods: {
    closeModal() {
      this.showAddMarkerModal = false;
      this.$emit("onCloseModal", this.showAddMarkerModal);
    },

    resetForm() {
      this.reset = true;
      this.addMarkerForm = {};
    },

    handleReset(value) {
      this.reset = value;
    },

    async getMarkers() {
      try {
        await this.$store.dispatch("markers/fetchMarkers");
      } catch (error) {
        console.log(error.message);
      }
    },

    handleSelect(obj) {
      console.log(obj);
      this.addMarkerForm = {
        ...this.addMarkerForm,
        ...obj,
      };
    },

    async handleSubmit(e) {
      e.preventDefault();

      this.loading = true;

      let markerType = "";

      if (this.modalTitle === "Factory") {
        markerType = "AP";
      } else if (this.modalTitle === "Factory Marker") {
        markerType = "Factory";
      } else if (this.modalTitle === "Customer Factory") {
        markerType = "Customer";
      } else {
        markerType = "Logistics";
        console.log(this.modalTitle);
      }

      let pms = {
        name: "PMS",
        cost: this.customerPayLoad.costPMS
          ? this.customerPayLoad.costPMS
          : "Nil",
      };
      let ago = {
        name: "AGO",
        cost: this.customerPayLoad.costAGO
          ? this.customerPayLoad.costAGO
          : "Nil",
      };

      this.logisticsPayload.gas = [pms, ago];

      const { name, rawMaterial, type, status, createdBy } = this.addMarkerForm;

      // console.log(name);

      const clickedPoint = this.$store.getters["markers/getClickedLocation"];

      // console.log("I'm clicked", this.modalTitle, markerType);

      try {
        // dispatch action to call add marker endpoint
        let resp;
        if (markerType === "Customer") {
          this.customerPayLoad.name = this.addMarkerForm.name;
          this.customerPayLoad.phoneNumber = this.addMarkerForm.phoneNumber;
          this.customerPayLoad.latitude = clickedPoint.latitude;
          this.customerPayLoad.longitude = clickedPoint.longitude;
          this.customerPayLoad.factoryType = this.addMarkerForm.type;
          this.customerPayLoad.rawMaterials = this.selected;
          this.customerPayLoad.status = this.addMarkerForm.status;

          resp = await this.$store.dispatch(
            "markers/addCustomer",
            this.customerPayLoad
          );
        } else if (markerType === "Logistics") {
          this.logisticsPayload.name = this.addMarkerForm.name;
          this.logisticsPayload.gas;
          this.logisticsPayload.latitude = clickedPoint.latitude;
          this.logisticsPayload.longitude = clickedPoint.longitude;
          this.logisticsPayload.status = this.addMarkerForm.status;

          resp = await this.$store.dispatch(
            "markers/addLogisticsDepot",
            this.logisticsPayload
          );
        } else {
          resp = await this.$store.dispatch("markers/addMarker", {
            name,
            latitude: clickedPoint.latitude,
            longitude: clickedPoint.longitude,
            rawMaterial,
            markerType,
            type,
            status,
            createdBy,
          });
        }

        if (resp.status === 201) {
          this.loading = false;

          this.getMarkers();

          this.$toastr.s("Marker Added Successfully");
        }

        this.resetForm();
      } catch (error) {
        this.loading = false;
        console.log(error.message);
      }
      this.closeModal();
    },
  },

  computed: {
    getMarkerTypeName() {
      return this.modalTitle.split(" ")[0];
    },

    isFormEmpty() {
      let isEmpty;

      if (this.modalTitle === "Factory Marker") {
        isEmpty = Object.values(this.addMarkerForm).some((el) => !el?.length);
        console.log("is empty", isEmpty);
        console.log(this.addMarkerForm);
      } else {
        isEmpty =
          !this.addMarkerForm["name"]?.length ||
          !this.addMarkerForm["status"]?.length;
      }

      return isEmpty;
    },
  },

  watch: {},
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 9990;
}

.form {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0rem;
}

.form-title {
  font-weight: 600;
}

.close-modal {
  position: absolute;
  top: -3px;
  right: 0.2rem;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  border: none;
  background: none;
}

.input__container {
  position: relative;
  margin-bottom: 3rem;
  width: 100%;
  border: 1px solid #fff;
}

.form__div {
  position: relative;
  margin-bottom: 0.8rem;
  width: 100%;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 11%;
  font-size: 0.7rem;
  border: 1.5px solid #669933;
  border-radius: 5px;
  outline: none;
  padding: 1rem;
  background: none;
  text-align: left;
  z-index: 1;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 0.6rem;
  padding: 0 0.25rem;
  background-color: #fff;
  color: rgb(196, 196, 196);
  font-size: 0.8rem;
  transition: 0.3s;
}

.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

/*Input focus move up label*/
.form__input:focus + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: #000;
  font-size: 0.75rem;
  font-weight: 300;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus) + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 10;
}

/*Input focus*/
.form__input:focus {
  border: 1.5px solid #669933;
}

.btn-container {
  display: flex;
}

.add-marker-btn {
  margin-left: auto;
  color: #fff;
  background-color: #669933;
  width: 20%;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 13px;
}

.dis:disabled {
  margin-left: auto;
  color: #fff;
  background-color: #669933;
  width: 20%;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 13px;
  opacity: 0.7;
}

/* background: #669933 */
</style>
