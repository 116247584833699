import axios from 'axios';

const state = () => ({
  oilpalmClusters: [],
});

const getters = {
  getOilPalmClusters: (state) => state.oilpalmClusters,
};

const mutations = {
  saveOilpalmClusters(state, data) {
    state.oilpalmClusters = data;
  },
};

const actions = {
  async getOilPalmClusters({ commit }, url) {
    try {
      const response = await axios.get(url);
      commit('saveOilpalmClusters', response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
