<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: "app",

  computed: {
    layout() {
      return "default-layout";
    },
  },
};
</script>

<style>
@import "../public/global/global.css";
@import "../public/assets/leaflet/MarkerCluster.css";
@import "../public/assets/leaflet/MarkerClusterDefault.css";
</style>
