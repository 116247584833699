import {arcgisURL} from "@/config/api-config";
import {highCarbonStocksRenderer} from "@/views/mills/data/hcsRenderer";

const highCarbonStockLayer = {
    title: "High Carbon Stock Areas",
    url: arcgisURL + "hcsPol/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: highCarbonStocksRenderer,
    popupTemplate: {
        title: "High Carbon Stock Areas",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "gridcode",
                        label: "Stocks Value",
                        visible: true,
                    },
                ],
            },
        ],
    },
}
export default highCarbonStockLayer;

