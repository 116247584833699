import {arcgisURL} from "@/config/api-config";
import { oilpalmSusRenderer } from "../../data/oilpalmSustainabilityR";
const clusterConfig = {
    type: "cluster",
    clusterRadius: "100px",
    popupTemplate: {
      title: "Cluster summary",
      content:
        "This cluster represents <b>{cluster_count}</b> points. Click on the zoom button to expand",
      fieldInfos: [
        {
          fieldName: "cluster_count",
          format: {
            places: 0,
            digitSeparator: true,
          },
        },
      ],
    },
    clusterMinSize: "27px",
    clusterMaxSize: "60px",
    labelingInfo: [
      {
        deconflictionStrategy: "none",
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')",
        },
        symbol: {
          type: "text",
          color: "black",
          font: {
            weight: "bold",
            family: "Noto Sans",
            size: "12px",
          },
        },
        labelPlacement: "center-center",
      },
    ],
  };

  




const oilpalmSustainability = {
    url: arcgisURL + "10kmscore/FeatureServer",
    title: "Mill Sustainability Scores",
    renderer: oilpalmSusRenderer,
    featureReduction: clusterConfig,
    popupTemplate: {
        title: "{name} - Sustainability of OilPalm",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "sustainability",
                        label: "Sustainability Score (%)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "name",
                        label: "Mill Owner",
                        visible: true,
                    },
                    {
                        fieldName: "RTM",
                        label: "RTM Number",
                        visible: true,
                    },
                    {
                        fieldName: "state_slug",
                        label: "State",
                        visible: true,
                    },
                    {
                        fieldName: "lga_slug",
                        label: "LGA",
                        visible: true,
                    },
                    {
                        fieldName: "size",
                        label: "Mill Size",
                        visible: true,
                    },
                    
                ],
            },
        ],
    },
}
export default oilpalmSustainability;

