//renderer for ward tagged mill
export const wardTaggedMillRenderer = {
    type: "class-breaks",
    field: "F_id_count",
    legendOptions: {
      title: "Number of Tagged"
    },
    classBreakInfos: [
      {
        minValue: 0,
        maxValue: 5,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#e0e1dd",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "< 5"
      },
      {
        minValue: 6,
        maxValue: 10,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#778da9",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "6 - 10"
      },
      {
        minValue: 11,
        maxValue: 20,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#415a77",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "11 - 20"
      },
      {
        minValue: 21,
        maxValue: 40,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#1b263b",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "21 - 40"
      },
      {
        minValue: 41,
        maxValue: 4000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#0d1b2a",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "40+"
      }
    ]
};

//renderer for ward tonnes sourced
export const wardP1SourcedRenderer = {
    type: "class-breaks",
    field: "tonSourced",
    legendOptions: {
      title: "Tonnes of P1 Sourced"
    },
    classBreakInfos: [
      {
        minValue: 0,
        maxValue: 20,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#e0e1dd",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "< 20T"
      },
      {
        minValue: 21,
        maxValue: 49,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#778da9",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "21 - <50T"
      },
      {
        minValue: 50,
        maxValue: 99,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#415a77",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "50 - <100T"
      },
      {
        minValue: 100,
        maxValue: 249,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#1b263b",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "100 - <250"
      },
      {
        minValue: 250,
        maxValue: 400000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#0d1b2a",
            outline: {
                color: "grey",
                width: 0.2,
            },
          },
        label: "250+"
      }
    ]
};

//renderer for ward Mill CLuster
export const wardMillClusterRenderer = {
    type: "class-breaks",
    field: "millClust",
    legendOptions: {
      title: "Number of Mill Clusters"
    },
    classBreakInfos: [
      {
        minValue: 0,
        maxValue: 2,
        symbol: {
          type: "simple-fill", //simple-marker
          color: "#e0e1dd",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
        label: "< 3"
      },
      {
        minValue: 3,
        maxValue: 6,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#778da9",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "3 - 6"
      },
      {
        minValue: 7,
        maxValue: 12,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#415a77",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "7 - 12"
      },
      {
        minValue: 13,
        maxValue: 24900,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#1b263b",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "13+"
      },
    ]
};

//renderer for ward Mill CLuster
export const wardAverageP1SourcedRenderer = {
  type: "class-breaks",
  field: "aveP1Pur",
  legendOptions: {
    title: "Average P1 Purchased"
  },
  defaultSymbol: {
    type: "simple-fill",
    color: "#f3f3f1",
    style: "solid",
    outline: {
      color: "grey",
      width: 0.2,
    },
  },
  defaultLabel: "no data",
  classBreakInfos: [
    {
      minValue: 0.1,
      maxValue: 0.9,
      symbol: {
          type: "simple-fill", //simple-marker
          color: "#e0e1dd",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
      label: "< 1T"
    },
    {
      minValue: 1,
      maxValue: 4,
      symbol: {
          type: "simple-fill", //simple-marker
          color: "#778da9",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
      label: "1 - <5T"
    },
    {
      minValue: 5,
      maxValue: 9,
      symbol: {
          type: "simple-fill", //simple-marker
          color: "#415a77",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
      label: "5 - <10T"
    },
    {
      minValue: 10,
      maxValue: 29,
      symbol: {
          type: "simple-fill", //simple-marker
          color: "#1b263b",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
      label: "10 - 29T"
    },
    {
      minValue: 30,
      maxValue: 290000,
      symbol: {
          type: "simple-fill", //simple-marker
          color: "#0d1b2a",
          outline: {
            color: "grey",
            width: 0.2,
          },
        },
      label: "30T+"
    },
  ]
};