<template>
  <div class="" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
      tabindex="1"
    >
      <p>{{ selected ? selected : dropdownTitle }}</p>
    </div>
    <div
      class="items"
      :class="{ selectHide: !open }"
      @mouseleave="closeOptions"
    >
      <div
        v-for="(option, i) of dropdownOptions"
        :key="i"
        @click="handleSelect(option, dropdownTitle)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapViewDropdown',

  props: {
    options: {
      type: Array,
      required: true,
    },
    dropdownTitle: {
      type: String,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: 'Oil Palm',
      open: false,
      dropdownOptions: this.options,
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },

  methods: {
    handleSelect(option) {
      this.isReset = false;
      this.selected = option;
      this.open = false;

      // emit selected option to the parent
      this.$emit('onInput', this.selected);
    },

    closeOptions() {
      this.open = false;
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff;
  border-radius: 6px;
  border: 1.5px solid #669933;
  color: #669933;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  width: 240%;
  line-height: 35px;
  font-size: 0.8rem;
  position: relative;
}

p {
  width: 95%;
}

input {
  border: none;
  outline: none;
  width: 95%;
}

.selected.open {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: '';
  top: 17px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #669933 transparent transparent transparent;
}

.selected:focus p {
  /* position: absolute; */
  top: -0.5rem;
  left: 0.8rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 10;
}

.items {
  color: rgb(196, 196, 196);
  border-radius: 0px 0px 6px 6px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 240%;
  height: auto;
  border-right: 1px solid #669933;
  border-left: 1px solid #669933;
  border-bottom: 1px solid #669933;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0.4rem;
}

.items div {
  color: #000;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  border-bottom: 1px solid #669933;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.items div:last-of-type {
  border-bottom: 0px;
}

.items div:hover {
  background-color: #fff;
}

.selectHide {
  display: none;
}
</style>
