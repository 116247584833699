import {showCPOMillClusters} from "@/views/mills/widgets/layer-list/cpo-mills-cluster";
import {showP1InField} from "@/views/mills/widgets/layer-list/P1-in-field";
import {releafTaggedMills} from "@/views/mills/widgets/layer-list/releaf-tagged-mills";

export function layerListActions(event, weeklyP1YieldSlider, Slider, oilPalmClusterSlider, p1LGASummarySlider, buidingFootprintSlider, daysSinceLastDiscoverySlider, tonnesBoughtTaggedMillsSlider, numberOfTxnSlider, aveP1PerMillSlider, sliderTonnesAtField, sliderDaysSincePurchase, pickupVehicleCheckboxes, agentsDropdownList, customerFactoryTypeFilter, customerFactoryTypeFilterReset, releafFactoryTypeFilter, releafFactoryTypeFilterReset, p1AtFieldReset, cpoTaggedMillsFilterReset, gtForDaySinceLastPurchase, ltForDaySinceLastPurchase, gtForTonnesPurchased, ltForTonnesPurchased, customerFactoryStatusFilter, releafFactoryStatusFilter, gtDaysSinceLastDiscovery, ltDaysSinceLastDiscovery, gtTonnesBoughtTaggedMills, ltTonnesBoughtTaggedMills, gtNumberOfTxn, ltNumberOfTxn, gtAveP1PerMill, ltAveP1PerMill, gtBuidingFootprint, ltBuidingFootprint, gtWeeklyP1Yield, ltWeeklyP1Yield, gtOilPalmCluster, ltOilPalmCluster, gtP1LGASummary, ltP1LGASummary, oilPalmClusterReset, handleShowP1Summary, handleReleafFactorySummary, handleCustomerFactorySummary, handleCPOTaggedReleafSummary, handleOilpalmClusterSummary, handlesNgnLGASummary, handlesWardsSummary, handleClusteredMillSummary, handleDeforestedOilpalmClusterSummary) {
    const eventItem = event.item;

    if (eventItem.title === "Community transaction summary") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Oil Palm Customers") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Mills Distance") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Logistics Depot") {
        eventItem.visible = false;
    }
    if (eventItem.title === "P1 LMV Pickup") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Akwa Ibom Plantations") {
        eventItem.visible = false;
    }
    if (eventItem.title === "P1 Mill Aggregation") {
        eventItem.visible = false;
    }
    if (eventItem.title === "LGA transaction summary") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Minigrids") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Deforestation Protected Areas") {
        eventItem.visible = false;
    }
    if (eventItem.title === "P1 Aggregation Points") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Community Price Projections") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Active P1 Purchasing Communities") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Current P1 price in communities") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Nigeria Ward Layer") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Nigeria States Boundaries") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Nigeria LGA Layer") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Key Conservation Areas (HCV)") {
        eventItem.visible = false;
    }
    if (eventItem.title === "High Carbon Stock Areas") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Industrial Parks") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Forest Cover Loss") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Forest Cover Gain") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Infrastructure") {
        eventItem.visible = false;
    }
    if (eventItem.title === "Mill Sustainability Scores") {
        eventItem.visible = false;
    }

    if (eventItem.layer.type != "group") {
        // don't show legend twice
        eventItem.panel = {
            content: "legend",
            open: false,
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
        };
        
    }

    //Function for Deforested Layers
    if (eventItem.title === "Potential Deforestation Encroachment ") {
        eventItem.visible = false;
        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handleDeforestedOilpalmClusterSummary()
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }

    //Function for Nigeria LGA Boundaries
    if (eventItem.title === "Nigeria LGA Boundaries") {
        eventItem.visible = false;

        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handlesNgnLGASummary()
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }

    //Function for Nigeria Ward Boundaries
    if (eventItem.title === "Nigeria Ward Boundaries") {
        eventItem.visible = false;

        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handlesWardsSummary()
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }

    //Function for Releaf Factory filter
    if (eventItem.title === "Releaf Assets") {
        eventItem.visible = false;

        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handleReleafFactorySummary()
            
        })

        // Create radio button for releaf factory type filter 
        let radioGroupFactoryType = document.createElement('div');
        let factoryTypeRadioLabels = ['Refinery', 'Crushing Mill', 'Deshelling Mill', 'Kraken II'];
        factoryTypeRadioLabels.forEach((label, index) => {
            let optionWrapper = document.createElement('div');
            optionWrapper.className = 'radio-option';

            let checkbox = document.createElement('input');
            checkbox.className = "radioButton";
            checkbox.type = 'checkbox';
            checkbox.name = 'checkboxGroupFactoryType';
            checkbox.value = label;
            checkbox.addEventListener('change', () => {

                var selectedOptions = [];
                var checkboxes = document.getElementsByName("checkboxGroupFactoryType");

                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        selectedOptions.push(checkboxes[i].value);
                    }
                }
                releafFactoryTypeFilter(selectedOptions);
            });


            optionWrapper.appendChild(checkbox);

            let radioLabel = document.createElement('label');
            radioLabel.htmlFor = `radio${index}`;
            radioLabel.textContent = label;
            radioLabel.className = 'radio-label';
            optionWrapper.appendChild(radioLabel);

            radioGroupFactoryType.appendChild(optionWrapper);


        });

        // Create radio button for releaf factory status filter      
        let radioGroupFactoryStatus = document.createElement('div');
        let factoryStatusRadioLabels = ['Active', 'Inactive', 'Underconstruction'];
        factoryStatusRadioLabels.forEach((label, index) => {
            let optionWrapper = document.createElement('div');
            optionWrapper.className = 'radio-option';

            let radio = document.createElement('input');
            radio.className = "radioButton";
            radio.type = 'radio';
            radio.name = 'radioGroupFactoryStatus';
            radio.id = `radio${index}`;
            radio.addEventListener('change', () => {
                releafFactoryStatusFilter(index);
            });
            optionWrapper.appendChild(radio);

            let radioLabel = document.createElement('label');
            radioLabel.htmlFor = `radio${index}`;
            radioLabel.textContent = label;
            radioLabel.className = 'radio-label';
            optionWrapper.appendChild(radioLabel);

            radioGroupFactoryStatus.appendChild(optionWrapper);
        });

        //Reset button
        let resetButton = document.createElement('button');
        resetButton.className = "resetButton";
        resetButton.innerText = "Reset";
        resetButton.addEventListener("click", () => {
            const radioButtons = [...document.querySelectorAll(".radioButton")]
            radioButtons.forEach((radioButton) => {
                radioButton.checked = false;
                releafFactoryTypeFilterReset();
            })
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "Factory Type Filter",
                    "&nbsp;",
                    radioGroupFactoryType, // Add radio button with 3 labels to the panel
                    "&nbsp;",
                    "Factory Status Filter",
                    "&nbsp;",
                    radioGroupFactoryStatus,
                    "&nbsp;",
                    resetButton,
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }


    // Function for Customer Factory type filters
    if (eventItem.title === "Customer Factories") {
        eventItem.visible = false;

        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handleCustomerFactorySummary()
            
        })

        // Create checkbox button for customer factory type       
        let checkboxGroupFactoryType = document.createElement('div');
        let factoryTypeCheckboxLabels = ['Refinery', 'Crushing Mill', 'Deshelling Mill', 'Kraken II'];
        factoryTypeCheckboxLabels.forEach((label, index) => {
            let optionWrapper = document.createElement('div');
            optionWrapper.className = 'radio-option';

            let checkbox = document.createElement('input');
            checkbox.className = "radioButton";
            checkbox.type = 'checkbox';
            checkbox.name = 'checkboxGroupFactoryType';
            checkbox.value = label;
            checkbox.addEventListener('change', () => {
                var selectedOptions = [];
                var checkboxes = document.getElementsByName("checkboxGroupFactoryType");

                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        selectedOptions.push(checkboxes[i].value);
                    }
                }
                customerFactoryTypeFilter(selectedOptions);
            });

            optionWrapper.appendChild(checkbox);
            let radioLabel = document.createElement('label');
            radioLabel.htmlFor = `radio${index}`;
            radioLabel.textContent = label;
            radioLabel.className = 'radio-label';
            optionWrapper.appendChild(radioLabel);

            checkboxGroupFactoryType.appendChild(optionWrapper);
        });

        // Create radio button for customer factory status  filter     
        let radioGroupFactoryStatus = document.createElement('div');
        let factoryStatusRadioLabels = ['Active', 'Inactive', 'Underconstruction'];
        factoryStatusRadioLabels.forEach((label, index) => {
            let optionWrapper = document.createElement('div');
            optionWrapper.className = 'radio-option';

            let radio = document.createElement('input');
            radio.className = "radioButton";
            radio.type = 'radio';
            radio.name = 'radioGroupFactoryStatus';
            radio.id = `radio${index}`;
            radio.addEventListener('change', () => {
                customerFactoryStatusFilter(index);
            });
            optionWrapper.appendChild(radio);

            let radioLabel = document.createElement('label');
            radioLabel.htmlFor = `radio${index}`;
            radioLabel.textContent = label;
            radioLabel.className = 'radio-label';
            optionWrapper.appendChild(radioLabel);

            radioGroupFactoryStatus.appendChild(optionWrapper);
        });

        //Reset button
        let resetButton = document.createElement('button');
        resetButton.className = "resetButton";
        resetButton.innerText = "Reset";
        resetButton.addEventListener("click", () => {
            const radioButtons = [...document.querySelectorAll(".radioButton")]
            radioButtons.forEach((radioButton) => {
                radioButton.checked = false;
                customerFactoryTypeFilterReset();
            })
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "Factory Type Filter",
                    "&nbsp;",
                    checkboxGroupFactoryType, // Add radio button with 3 labels to the panel
                    "&nbsp;",
                    "Factory Status Filter",
                    "&nbsp;",
                    radioGroupFactoryStatus,
                    "&nbsp;",
                    resetButton,
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }

    //Function for P1 at Field with slider
    if (eventItem.title === "P1 Purchases (Field)") {
        const __ret = showP1InField(eventItem, sliderTonnesAtField, Slider, sliderDaysSincePurchase, gtForDaySinceLastPurchase, ltForDaySinceLastPurchase, gtForTonnesPurchased, ltForTonnesPurchased, pickupVehicleCheckboxes, agentsDropdownList, p1AtFieldReset, sliderResetP1AtField, handleShowP1Summary);
        sliderTonnesAtField = __ret.sliderTonnesAtField;
        sliderDaysSincePurchase = __ret.sliderDaysSincePurchase;
    }if (eventItem.title === "P1 Purchases (Field)") {
        eventItem.visible = false;
    }
    function sliderResetP1AtField() {
        sliderDaysSincePurchase.values = [0];
        sliderTonnesAtField.values = [0];
    }

    //layer list for oilpalmcluster with slider
    if (eventItem.title === "Niger Delta Planted Oil Palm") {

        const divSummaryButton = document.createElement("div");
        divSummaryButton.className = "btn-summary";
        divSummaryButton.innerText = "Show Layer Summary";
        divSummaryButton.addEventListener("click", ()=>{
            handleOilpalmClusterSummary()
            
        })
        
        eventItem.visible = true;
        weeklyP1YieldSlider = new Slider({
            min: 0,
            max: 300,
            values: [0],
            precision: 0,
            visibleElements: {
                labels: true,
                rangeLabels: true,
            },
        });

        oilPalmClusterSlider = new Slider({
            min: 0,
            max: 20,
            values: [0],
            precision: 0,
            visibleElements: {
                labels: true,
                rangeLabels: true,
            },
        });

        //sign function - weeklyP1YieldSlider
        let switch1 = document.createElement("label");
        let input1 = document.createElement("input");
        let span1 = document.createElement("span")
        switch1.className = "switch";
        input1.type = "checkbox";
        span1.className = "slider round"
        switch1.appendChild(input1);
        switch1.appendChild(span1);
        input1.checked = true;

        input1.addEventListener("change", (event) => {
            if (event.target.checked) {
                gtWeeklyP1Yield();
            } else {
                ltWeeklyP1Yield();
            }
        })

        //sign function - oilPalmClusterSlider
        let switch2 = document.createElement("label");
        let input2 = document.createElement("input");
        let span2 = document.createElement("span")
        switch2.className = "switch";
        input2.type = "checkbox";
        span2.className = "slider round"
        switch2.appendChild(input2);
        switch2.appendChild(span2);
        input2.checked = true;

        input1.addEventListener("change", (event) => {
            if (event.target.checked) {
                gtOilPalmCluster();
            } else {
                ltOilPalmCluster();
            }
        })

        //Reset button
        let resetButton = document.createElement('button');
        resetButton.className = "resetButton";
        resetButton.innerText = "Reset";
        resetButton.addEventListener("click", () => {
            oilPalmClusterReset();
            slidersResetOilPalmCluster()
        })

        if (eventItem.layer.type != "group") {
            eventItem.panel = {
                content: [
                    "legend",
                    "Toggle off the switch to reverse the slider function to '< less than function'.",
                    "&nbsp;",
                    "Weekly P1 Yield (T)",
                    "&nbsp;",
                    switch1,
                    "&nbsp;",
                    weeklyP1YieldSlider,
                    "&nbsp;",
                    "&nbsp;",
                    "Palm Cluster by Age",
                    "&nbsp;",
                    switch2,
                    "&nbsp;",
                    oilPalmClusterSlider,
                    "&nbsp;",
                    resetButton,
                    "&nbsp;",
                    divSummaryButton
                ],
                className: "esri-icon-sliders-horizontal",
                title: "Layer Properties",
            };
        }
    }

    function slidersResetOilPalmCluster() {
        weeklyP1YieldSlider.values = [0];
        oilPalmClusterSlider.values = [0];
    }

    //LGA Summary Layer with slider
    if (eventItem.title === "P1 LGA Summary") {
        eventItem.visible = false;
        p1LGASummarySlider = new Slider({
            min: 0,
            max: 73000,
            values: [0],
            precision: 0,
            visibleElements: {
                labels: true,
                rangeLabels: true,
            },
        });

        //sign function - ForsliderDaysSincePurchase
        let switch1 = document.createElement("label");
        let input1 = document.createElement("input");
        let span1 = document.createElement("span")
        switch1.className = "switch";
        input1.type = "checkbox";
        span1.className = "slider round"
        switch1.appendChild(input1);
        switch1.appendChild(span1);
        input1.checked = true;

        input1.addEventListener("change", (event) => {
            if (event.target.checked) {
                gtP1LGASummary();
            } else {
                ltP1LGASummary();
            }
        })
        eventItem.panel = {
            content: [
                "legend",
                "Toggle off the switch to reverse the slider function to '< less than function'.",
                "&nbsp;",
                "Weekly P1 Yield (T)",
                "&nbsp;",
                switch1,
                "&nbsp;",
                p1LGASummarySlider,
            ],
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
        };
    }

    //Building footprints layer with slider
    if (eventItem.title === "Roof Footprint") {
        eventItem.visible = false;
        buidingFootprintSlider = new Slider({
            min: 0,
            max: 10000,
            values: [0],
            precision: 0,
            visibleElements: {
                labels: true,
                rangeLabels: true,
            },
        });

        //sign function - DaysSinceLastDiscovery
        let switch1 = document.createElement("label");
        let input1 = document.createElement("input");
        let span1 = document.createElement("span")
        switch1.className = "switch";
        input1.type = "checkbox";
        span1.className = "slider round"
        switch1.appendChild(input1);
        switch1.appendChild(span1);
        input1.checked = true;

        input1.addEventListener("change", (event) => {
            if (event.target.checked) {
                gtBuidingFootprint();
            } else {
                ltBuidingFootprint();
            }
        });
        eventItem.panel = {
            content: [
                "legend",
                "Toggle off the switch to reverse the slider function to '< less than function'.",
                "&nbsp;",
                "Building Footprints (m²)",
                "&nbsp;",
                "&nbsp;",
                switch1,
                "&nbsp;",
                buidingFootprintSlider,
                "&nbsp;",
            ],
            className: "esri-icon-sliders-horizontal",
            title: "Layer Properties",
        };
    }

    //cpo mills tagged by releaf with slider
    if (eventItem.title === "Confirmed Mills") {
        const __ret = releafTaggedMills(eventItem, daysSinceLastDiscoverySlider, Slider, tonnesBoughtTaggedMillsSlider, numberOfTxnSlider, gtDaysSinceLastDiscovery, ltDaysSinceLastDiscovery, gtTonnesBoughtTaggedMills, ltTonnesBoughtTaggedMills, gtNumberOfTxn, ltNumberOfTxn, cpoTaggedMillsFilterReset, sliderResetCPOtaggedMills, handleCPOTaggedReleafSummary);
        daysSinceLastDiscoverySlider = __ret.daysSinceLastDiscoverySlider;
        tonnesBoughtTaggedMillsSlider = __ret.tonnesBoughtTaggedMillsSlider;
        numberOfTxnSlider = __ret.numberOfTxnSlider;
    }

    function sliderResetCPOtaggedMills() {
        daysSinceLastDiscoverySlider.values = [0]
        tonnesBoughtTaggedMillsSlider.values = [0]
        numberOfTxnSlider.values = [0]
    }

    //CPO mill clustered layer with slider
    if (eventItem.title === "PIND Mills") {
        aveP1PerMillSlider = showCPOMillClusters(eventItem, aveP1PerMillSlider, Slider, gtAveP1PerMill, ltAveP1PerMill, handleClusteredMillSummary);
    }

    return {
        weeklyP1YieldSlider,
        oilPalmClusterSlider,
        p1LGASummarySlider,
        buidingFootprintSlider,
        daysSinceLastDiscoverySlider,
        tonnesBoughtTaggedMillsSlider,
        numberOfTxnSlider,
        aveP1PerMillSlider,
        sliderTonnesAtField,
        sliderDaysSincePurchase,
        pickupVehicleCheckboxes,
    };
}
