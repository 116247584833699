import { lgasNameToSlug } from "../data/lgaHash";
import { calcCrow, convertToFilterQueryString } from "../helpers";
import store from "../store";
import {getMostOccurringLGA} from "@/utils/mills";

const getMarkersWithinServiceArea = (
  markers,
  serviceAreaStartingPoint,
  width
) => {
  const markersWithinServiceArea = markers.filter(
    (marker) => calcCrow(marker.location, serviceAreaStartingPoint) < width
  );

  return markersWithinServiceArea;
};

const computeMillsSummary = (mills) => {
  return mills.reduce(
    (summary, mill) => {
      summary.count++;
      summary.totalPrice +=
        typeof mill.totalPrice === "string" &&
        parseFloat(mill.totalPrice.replace(/,/g, ""));

      summary.totalTonsBought +=
        typeof mill.tonsAmount === "string" &&
        parseFloat(mill.tonsAmount.replace(/,/g, ""));

      summary.totalNoOfTransactions += mill.noOfTransactions
        ? mill.noOfTransactions
        : 0;
      return summary;
    },
    {
      count: 0,
      totalPrice: 0,
      totalTonsBought: 0,
      totalNoOfTransactions: 0,
    }
  );
};

export const getMillsSummary = async (
  mills,
  serviceAreaStartingPoint,
  width
) => {
  const millsWithinServiceArea = getMarkersWithinServiceArea(
    mills,
    serviceAreaStartingPoint,
    width
  );

  console.log("mills in service area", millsWithinServiceArea);

  const location = getMostOccurringLGA(millsWithinServiceArea);

  lgasNameToSlug;

  const obj = {
    LGA: lgasNameToSlug[location],
  };

  const query = convertToFilterQueryString(obj);

  const price = await store.dispatch("mills/fetchCurrentP1Prices", query);

  const millsSummary = computeMillsSummary(millsWithinServiceArea);

  millsSummary.currentP1Price = price[obj.LGA] ? price[obj.LGA] : 50000;
  return millsSummary;
};

export const computeTotalAcres = (
  oilpalmClusters,
  serviceAreaStartingPoint,
  width
) => {
  const oilpalmClustersWithinServiceArea = getMarkersWithinServiceArea(
    oilpalmClusters,
    serviceAreaStartingPoint,
    width
  );

  const totalAcres = oilpalmClustersWithinServiceArea.reduce((acc, cluster) => {
    acc += cluster["acreArea"];
    return acc;
  }, 0);

  return totalAcres;
};

const getMinigridsWithinServiceArea = (
  minigrids,
  serviceAreaStartingPoint,
  width
) => {
  const minigridsWithinServiceArea = minigrids.filter((minigrid) => {
    const location = {
      latitude: minigrid.latitude,
      longitude: minigrid.longitude,
    };
    return calcCrow(location, serviceAreaStartingPoint) < width;
  });
  console.log(serviceAreaStartingPoint)

  return minigridsWithinServiceArea;
};

export const computeMinigridSummary = (
  minigrids,
  serviceAreaStartingPoint,
  width
) => {
  const minigridsWithinServiceArea = getMinigridsWithinServiceArea(
    minigrids,
    serviceAreaStartingPoint,
    width
  );
  return minigridsWithinServiceArea.reduce(
    (summary) => {
      summary.count++;
      return summary;
    },
    {
      count: 0,
    }
  );
};

const getOilPalmPFWithinServiceArea = (
  oilPalmPF,
  serviceAreaStartingPoint,
  width
) => {
  const oilPalmPFWithinServiceArea = oilPalmPF.filter((oilPalmPF) => {
    const location = {
      latitude: oilPalmPF.latitude,
      longitude: oilPalmPF.longitude,
    };
    return calcCrow(location, serviceAreaStartingPoint) < width;
  });
  console.log(serviceAreaStartingPoint)


  return oilPalmPFWithinServiceArea;
};

export const computeOilPalmPFSummary = (
  oilPalmPF,
  serviceAreaStartingPoint,
  width
) => {
  const oilPalmPFWithinServiceArea = getOilPalmPFWithinServiceArea(
    oilPalmPF,
    serviceAreaStartingPoint,
    width
  );
  return oilPalmPFWithinServiceArea.reduce(
    (summary) => {
      summary.count++;
      return summary;
    },
    {
      count: 0,
    }
  );
};

export const  profitPerWeek = (totalAcres, currentP1Price, logisticsCost) => {

  let p1Yield = 0.024 * totalAcres;

  const profitPerWeek =
      p1Yield * (73500 - (currentP1Price + logisticsCost + 10000)) * 0.1;// The assumption is that Releaf consumes 10% of market

  return parseInt(profitPerWeek).toLocaleString("en-US");

};