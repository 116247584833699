<template>
  <div>
    <div>
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :per-page="10"
        :current-page="currentPage"
        id="my-table"
      >
        <template v-slot:cell(actions)="data">
          <button
            class="btn btn-info btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Action
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <b-button
              v-b-modal.modal-prevent-closing
              @click="openEditModal(data.item.id)"
              class="dropdown-item"
            >
              <i class="fa fa-edit"></i>
              Edit
            </b-button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              title="Edit Customer Factory"
              @ok="updateFactory"
            >
              <form ref="form" @submit.stop.prevent="updateFactory">
                <b-form-group label="Name" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.name">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Latitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.latitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Longitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.longitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="PMS Cost">
                  <b-form-input
                    id="name-input"
                    v-model="pmsCost"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="AGO Cost">
                  <b-form-input
                    id="name-input"
                    v-model="agoCost"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Status">
                  <b-form-select
                    v-model="editFactory.status"
                    :options="statusoptions"
                  ></b-form-select>
                </b-form-group>
              </form>
            </b-modal>
            <button
              class="dropdown-item"
              @click="deleteLogisticsDepot(data.item.id)"
            >
              <i class="fa fa-trash text-danger"></i>
              Delete
            </button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="10"
        aria-controls="my-table"
      ></b-pagination>
      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Factory",
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "gas",
          label: "Gas Types",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      selected: null,

      statusoptions: [
        { value: null, text: "Please select an option" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Underconstruction", text: "Underconstruction" },
      ],
      factoryUpdate: {},
      items: [],
      showEditModal: false,
      editFactory: {},
      singleFactory: {},
      pmsCost: 0,
      agoCost: 0,
    };
  },
  mounted() {
    this.getLogisticsDepot();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    async getLogisticsDepot() {
      const depots = await this.$store.dispatch("markers/fetchLogisticsDepots");
      const mappedDepots = depots.map((depot) => depot.properties);

      this.items = mappedDepots;
      console.log(this.items);
      this.factoryUpdate = mappedDepots;
    },
    async deleteLogisticsDepot(itemId) {
      console.log(itemId);
      try {
        const isDeleteConfirmed = await this.$bvModal.msgBoxConfirm(
          "Please confirm that you want to delete this Customer Factory.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (!isDeleteConfirmed) return;

        console.log(itemId);
        const res = await this.$store.dispatch(
          "markers/deleteLogisticsDepot",
          itemId
        );

        const { data } = res;
        console.log(data);
        // updating the list on the UI after successful delete request
        if (data) {
          this.$toastr.i("Customer Factory successfully deleted");
          const idx = this.items.findIndex((factory) => factory.id === itemId);
          this.items.splice(idx, 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openEditModal(id) {
      const factory = await this.$store.dispatch(
        "markers/fetchSingleLogisticsDepot",
        id
      );
      this.editFactory = factory;
      this.showEditModal = true;
      this.pmsCost = this.editFactory.gas[0].cost;
      this.agoCost = this.editFactory.gas[1].cost;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async updateFactory(e) {
      let pms = {
        name: "PMS",
        cost: this.pmsCost,
      };
      let ago = {
        name: "AGO",
        cost: this.agoCost,
      };
      this.editFactory.gas = [pms, ago];
      e.preventDefault();
      try {
        const payload = {
          id: this.editFactory._id,
          name: this.editFactory.name,
          latitude: this.editFactorylatitude,
          longitude: this.editFactory.longitude,
          gas: this.editFactory.gas,
          status: this.editFactory.status,
        };
        console.log(payload);
        const res = await this.$store.dispatch(
          "markers/updateLogisticsDepot",
          payload
        );
        if (res.status === 200) {
          this.$toastr.i("Logistics Depot successfully updated");
          this.closeEditModal();
        }
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    checkFormValidity() {},
    resetModal() {},
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.updateFactory();
    },
    handleSubmit() {},
  },
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: right;
  background-color: #09571d;
  color: #fff;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.body {
  display: flex;
  justify-content: center;
}

.sidebar {
  width: 20%;
  background-color: #f5f5f5;
  padding: 20px;
}

.main-content {
  width: 80%;
  padding: 20px;
}
</style>
