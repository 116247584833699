<template>
  <div>
    <div v-show="showRFSummary" class="summary-board summary-shadow">
      <h1 class="text-center"><b>Summary Board</b></h1>
      <p @click="showContainerFx" class="close-x">X</p>

      <!-- Releaf Factory summary -->
      <div class="p1-summary mt-3">
        <p><b>Releaf Owned Factory Layer</b></p>
        <ul class="mt-2">
          <li>
            Total Number of Factories: {{ this.layersObject.totalNoFactories }}
          </li>
          <p class="mt-2"><b>Breakdown by Category</b></p>
          <li>
            Total Number of Crushing Mills: {{ this.layersObject.crushingMill }}
          </li>
          <li>
            Total Number of Kraken II:
            {{ this.layersObject.deshellingMill }}
          </li>
          <li>Total Number of FFB Mills: {{ this.layersObject.ffbMill }}</li>
          <li>Total Number of Refineries: {{ this.layersObject.refinery }}</li>
          <p class="mt-2"><b>Breakdown by Location</b></p>
          <li>Mills in Akwa Ibom: {{ this.layersObject.akwaibom }}</li>
          <li>Mills in Cross River: {{ this.layersObject.crossriver }}</li>
          <li>Mills in Lagos: {{ this.layersObject.lagos }}</li>
          <li>Mills in Kano: {{ this.layersObject.kano }}</li>
          <li>Mills in Ogun: {{ this.layersObject.ogun }}</li>
          <li>Mills in Ondo: {{ this.layersObject.ondo }}</li>
          <li>Mills in Edo: {{ this.layersObject.edo }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { reverseGeocoding } from "../../config/api-config";
import { APIKeys } from "../../config/keys";

export default {
  name: "FpsClientLayersSummy",
  props: {
    showRFSummary: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      layersObject: {},
    };
  },

  mounted() {
    this.loadEsri();
  },

  computed: {},

  methods: {
    showContainerFx() {
      this.showRFSummary = !this.showRFSummary;
    },
    loadEsri() {
      loadModules(["esri/config", "esri/request", "esri/rest/locator"]).then(
        ([esriConfig, esriRequest, locator]) => {
          esriConfig.apiKey = APIKeys;
          this.layersObject = {
            totalNoFactories: 0,
            crushingMill: 0,
            deshellingMill: 0,
            ffbMill: 0,
            refinery: 0,
            akwaibom: 0,
            crossriver: 0,
            kano: 0,
            lagos: 0,
            ogun: 0,
            ondo: 0,
            edo: 0,
          };

          var toObjectLayer = this.layersObject;

          esriRequest(
            "https://api.fps.releaf.ng/api/marker-assets/filter?markerType=Factory",
            {
              responseType: "json",
            }
          )
            .then((response) => {
              //Total number of factories
              let totalNoOfFactories = response.data.features.length;
              toObjectLayer.totalNoFactories = totalNoOfFactories;

              // Total number of Crushing mills
              let noOfCrushingMills = response.data.features.map(
                (crushing) => crushing.properties.type
              );
              const uniqueCount2 = {};

              for (const element of noOfCrushingMills) {
                if (uniqueCount2[element]) {
                  uniqueCount2[element] += 1;
                } else {
                  uniqueCount2[element] = 1;
                }
              }
              // Total number of Crushing mills
              toObjectLayer.crushingMill = uniqueCount2["Crushing Mill"];
              // Total number of Deshelling mills
              toObjectLayer.deshellingMill = uniqueCount2["Deshelling Mill"];
              // Total number of FFB Mill
              toObjectLayer.ffbMill = uniqueCount2["FFB Mill"];
              // Total number of Refinery
              toObjectLayer.refinery = uniqueCount2.Refinery;

              // Breakdown by Location
              let coordinate = response.data.features.map((crushing) => {
                let long = crushing.properties.longitude;
                let lat = crushing.properties.latitude;
                let longLat = [long, lat];
                return longLat;
              });
              let reverseGeocoder = coordinate.map((address) => {
                const serviceUrl = reverseGeocoding;
                const params = {
                  location: address,
                };
                return locator
                  .locationToAddress(serviceUrl, params)
                  .then((response) => {
                    let region = response.attributes.Region;
                    return region;
                  });
              });

              Promise.all(reverseGeocoder).then((addressArray) => {
                const uniqueuniqueRegions = {};

                for (const element of addressArray) {
                  if (uniqueuniqueRegions[element]) {
                    uniqueuniqueRegions[element] += 1;
                  } else {
                    uniqueuniqueRegions[element] = 1;
                  }
                }
                toObjectLayer.akwaibom = uniqueuniqueRegions["Akwa Ibom"];
                toObjectLayer.crossriver = uniqueuniqueRegions["Cross River"];
                toObjectLayer.edo = uniqueuniqueRegions.Edo;
                toObjectLayer.kano = uniqueuniqueRegions.Kano;
                toObjectLayer.lagos = uniqueuniqueRegions.Lagos;
                toObjectLayer.ogun = uniqueuniqueRegions.Ogun;
                toObjectLayer.ondo = uniqueuniqueRegions.Ondo;
              });
            })

            .catch((error) => {
              console.error("Error loading layer metadata:", error);
            });
        }
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.close-x {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.summary-board {
  padding: 40px;
  width: 500px;
  height: 300px;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  bottom: 100px;
  left: 70px;
}

.summary-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

ol,
ul {
  list-style-type: circle;
}
</style>