import Vuex from 'vuex';
import Vue from 'vue';
import createCache from 'vuex-cache';
import auth from './modules/auth';
import cassava from './modules/cassava';
import mills from './modules/mills';
import markers from './modules/markers';
import oilpalmClusters from './modules/oilpalmClusters';
import serviceArea from './modules/serviceArea';
import serviceAreaWidget from './modules/external-widget/serviceAreaWidget';
import serviceAreaWY from './modules/external-widget/serviceAreaWY';
import serviceAreaPPW from './modules/external-widget/serviceAreaPPW';
import arcgisMap from './modules/arcgisMap'
import inventory from "@/store/modules/inventory";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createCache()],
  state: {
    farmersList: [],
  },

  mutations: {},

  actions: {},

  getters: {},

  modules: {
    auth,
    cassava,
    mills,
    markers,
    oilpalmClusters,
    serviceArea,
    serviceAreaWidget,
    serviceAreaWY,
    serviceAreaPPW,
    arcgisMap,
    inventory
  },
});
