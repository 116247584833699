const state = () => ({
    mapLayers: [],
    mapView: []
});

const getters = {
    getMapLayers: (state) => state.mapLayers,
    getMapView: (state) => state.mapView
};

const mutations = {
    saveLayers(state, payload) {
        state.mapLayers = payload
    },
    saveView(state, payload) {
        state.mapView = payload
    },
};

const actions = {
    saveLayers({commit}, layers) {
        commit('saveLayers', layers);
    },
    saveView({commit}, view) {
        commit('saveView', view)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};