import {arcgisURL} from "@/config/api-config";

const wardBoundaryLayer = {
    title: "Nigeria Ward Boundaries",
    url: arcgisURL + "wards/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            color: "rgba(140, 140, 140, 0.8)",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
        },
    },
    popupTemplate: {
        title: "{wardname}, {lganame} LGA",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "wardname",
                        label: "Ward Name",
                        visible: true,
                    },
                    {
                        fieldName: "comm",
                        label: "Community Names",
                        visible: true,
                    },
                    {
                        fieldName: "F_id_count",
                        label: "Number of Tagged Mills",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "noOfTransa",
                        label: "Number of Transactions",
                        visible: true,
                        format: {
                            places: 0,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "tonSourced",
                        label: "Tonnes Sourced",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "aveP1Pur",
                        label: "Average P1 Purchased",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "currP1",
                        label: "Current Price of P1",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "date_max",
                        label: "Date of Current P1 Price",
                        visible: true,
                    },
                    {
                        fieldName: "loanSum",
                        label: "Number of Loans",
                        visible: true,
                        format: {
                            places: 0,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default wardBoundaryLayer;

