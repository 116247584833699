import {arcgisURL} from "@/config/api-config"



const industrialParksLayer = {
    title: "Industrial Parks",
    url: arcgisURL + "Industrial_parks/FeatureServer",
    copyright: "",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-marker",
            size: 15,
            color: "#118ab2",
            outline: {
                color: "rgba(250, 209, 221, 0.5)",
                width: 2,
            },
        },
    },
    popupTemplate: {
        title: "Industrial Park - {name}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "name",
                        label: "Name",
                        visible: true,
                    },
                    {
                        fieldName: "State",
                        label: "state",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default industrialParksLayer;

