import axios from 'axios';

const redirect_user = () => {
  const id_service_host_src = process.env.VUE_APP_AUTH_SERVICE_BASE_URL;
  window.location.href = `${id_service_host_src}/signin?redirect=${window.location.origin}${window.location.pathname}`;
};

export const getTokenFromCookie = () => {
  let token = null;

  if (
    process.env.VUE_APP_AXIOS_STATE === 'bare_metal' ||
    process.env.VUE_APP_AXIOS_STATE === 'docker-compose'
  ) {
    document.cookie =
      'token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmYTMxZDFjMDhkMjVkZWUyODQ1M2E4NiIsImVtYWlsIjoiZXhhbXBsZUByZWxlYWYubmciLCJyb2xlcyI6WyJkZXZlbG9wZXIiXSwiaWF0IjoxNjExMjk1NTgyfQ.9E9Qi88M-tr_P8GUY1RXMqVJdbOK1Oj4ZCtsbPse50I;path=/;max-age=3600';
  }

  const cookies = document.cookie.split(';');
  if (cookies.length > 0) {
    // iterate through the values
    for (let i in cookies) {
      const item = cookies[i].split('=');
      const key = item[0];
      const value = item[1];
      if (key.trim() === 'token') {
        token = value;
        axios.defaults.headers.common['Authorization'] = `${token}`;
        break;
      }
    }
    return token;
  }
  return token;
};

export { redirect_user };
