<template>
  <div>
    <!-- Service area modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="Set the Driving time!"
      class="modal-area"
    >
      <div class="d-block">
        <p>
          In order to get the catchment area, you need to set the driving time
        </p>
        <div class="flex justify-content-between align-items-center mt-4">
          <div class="flex justify-content-between align-items-center">
            <input
              type="number"
              :min="2"
              :max="300"
              v-model="serviceAreaValue"
              class="mr-3 px-2 py-1 border border-solid border-black w-16"
            />
            <p>Minutes</p>
          </div>
          <button class="btn btn-primary px-4" @click="generateServiceArea">
            Set
          </button>
        </div>
        <div></div>
      </div>
    </b-modal>
    <b-spinner
      v-if="loading"
      style="width: 5rem; height: 5rem"
      label="Large Spinner"
      class="spinner"
      type="grow"
    ></b-spinner>
    <div class="overlay" v-if="loading"></div>
  </div>
</template>

<script>
export default {
  name: "ServiceAreaModal",

  data() {
    return {
      params: {},
      serviceAreaValue: 0,
      me: true,
      loading: false,
    };
  },

  props: {
    serviceAreaArgs: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    divideServiceAreaValue() {
      return Math.round(Number(this.serviceAreaValue));
    },
  },

  methods: {
    generateServiceArea() {
      if(this.$store.getters["serviceArea/getServiceAreaCount"] < 5){
        this.loading = true;
        this.params["divideServiceAreaValue"] = this.divideServiceAreaValue;
        this.params["closeModal"] = this.hideModal;
        this.$store.dispatch("serviceArea/drawServiceArea", this.params);
      }else{
        this.params["closeModal"] = this.hideModal;
        this.$refs["my-modal"].hide();
        alert("Maximum service exceeded");
      }

    },
    showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
      this.loading = false;
    },
  },

  watch: {
    serviceAreaArgs(newValue) {
      this.params = newValue;
    },
  },
};
</script>

<style scoped>
.spinner {
  position: absolute;
  top: 35%;
  left: 47%;
  z-index: 9999;
  color: #669933;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.651);
  backdrop-filter: blur(3px);
  z-index: 9990;
}
</style>
