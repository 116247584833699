import moment from 'moment';
const getReadableTimeStamp = (timestamp) => {
  let d = new Date(timestamp);
  return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
};

const getDateFormatted = (datetime, format = 'Do MMM YYYY') => {
  const date = moment(new Date(datetime)).format(format);
  return date;
};

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getWeek = (datetime) => moment(new Date(datetime)).week();

export const getMonth = (datetime) => moment(new Date(datetime)).month() + 1;

const getDate = (
  payload = { viewType: 'weekly', rangeType: 'current', customStartDate: null }
) => {
  const { viewType, rangeType, customStartDate } = payload;
  let startDate = moment().startOf('week');
  let endDate = moment().endOf('week');

  if (viewType === 'weekly') {
    if (rangeType === 'current') {
      startDate = moment().startOf('week');
      endDate = moment().endOf('week');
    }
    if (rangeType === 'previous') {
      startDate = moment().startOf('week').subtract(7, 'days');
      endDate = moment().endOf('week').subtract(7, 'days');
    }
    if (rangeType === 'custom') {
      startDate = moment(customStartDate);
      endDate = moment(startDate).endOf('week');
    }
  }
  if (viewType === 'monthly') {
    if (rangeType === 'current') {
      startDate = moment().startOf('month');
      endDate = moment().endOf('month');
    }
    if (rangeType === 'previous') {
      startDate = moment().startOf('month').subtract(1, 'months');
      endDate = moment().endOf('month').subtract(1, 'months');
    }
    if (rangeType === 'custom') {
      startDate = moment(customStartDate);
      endDate = moment(startDate).endOf('month');
    }
  }
  startDate = startDate.format('Y-MM-DD');
  endDate = endDate.format('Y-MM-DD');
  return {
    startDate,
    endDate,
  };
};

const convertToArrayOfIntegers = (string) => {
  return string.split('-').map((el) => Number(el));
};

const withinRange = (array, value) => {
  const [lowerLimit, upperLimit] = array.map(Number);
  return value >= lowerLimit && value <= upperLimit;
};

export const mapFilterQuery = (payload) => {
  const { mill, millSize, LGA, noOfTransaction, tonsAmount, totalPrice } = payload;

  if (millSize && mill.size !== millSize) {
    return false;
  }

  if (LGA && mill.lga_slug !== LGA) {
    return false;
  }

  if (noOfTransaction && !withinRange(noOfTransaction.split('-'), mill.noOfTransactions || 0)) {
    return false;
  }

  if (tonsAmount && !withinRange(tonsAmount.split('-'), mill.tonsAmount || 0)) {
    return false;
  }

  if (totalPrice && !withinRange(totalPrice.split('-'), mill.totalPrice || 0)) {
    return false;
  }

  return true;
};


// const getItemInStorage = (key, value) => {
//   const items = localStorage.getItem(key);
//   if (items) return JSON.parse(items);
//   return [];
// };
// const setItemInStorage = (key, value) => {
//   localStorage.setItem(key, JSON.stringify(value));
// };

// Handle computation from gm
const getRevenue = (p1QtyMoved, p2Price) => {
  const p1MovedQty = p1QtyMoved; //400 //
  // P2
  const p2b = 0.2192;

  const totalP2Tons = p1MovedQty * p2b;
  const p2Revenue = p2Price * totalP2Tons;

  const pkSd = 0.3936;
  const pksPrice = 10000;

  const totalPKSTons = p1MovedQty * pkSd;
  const pksRevenue = pksPrice * totalPKSTons;

  const revenue = p2Revenue + pksRevenue;
  return revenue;
};
// compute cost
const getCost = (p1QtyMoved, p1Price, logCostPerTon, processingCostPerTon) => {
  const p1MovedQty = p1QtyMoved;
  const p1Cost = p1Price * p1MovedQty;
  const logCost = logCostPerTon * p1MovedQty;
  const processingCost = processingCostPerTon * p1MovedQty;

  const cost = p1Cost + logCost + processingCost;
  return cost;
};

// compute GM
export const computeGM = ({
  current_p1QtyMoved,
  current_p2Price,

  prev_p1QtyMoved,
  prev_p2Price,

  current_p1Price,
  current_logCostPerTon,
  current_processingCostPerTon,

  prev_p1Price,
  prev_logCostPerTon,
  prev_processingCostPerTon,
}) => {
  const currentRev = getRevenue(current_p1QtyMoved, current_p2Price);

  const currentCost = getCost(
    current_p1QtyMoved,
    current_p1Price,
    current_logCostPerTon,
    current_processingCostPerTon
  );
  const currentCostPerTon =
    current_p1Price + current_logCostPerTon + current_processingCostPerTon;

  const gross_margin = (currentRev - currentCost) / currentRev;
  const gross_margin_percentage = gross_margin * 100;
  const gross_profit = currentRev / current_p1QtyMoved - currentCostPerTon;

  const prevRev = getRevenue(prev_p1QtyMoved, prev_p2Price);

  const prevCost = getCost(
    prev_p1QtyMoved,
    prev_p1Price,
    prev_logCostPerTon,
    prev_processingCostPerTon
  );
  const prevCostPerTon =
    prev_p1Price + prev_logCostPerTon + prev_processingCostPerTon;

  const prev_gross_margin = (prevRev - prevCost) / prevRev;
  // const gross_margin = parseFloat(((rev-cost)/rev).toFixed(2))
  const prev_gross_margin_percentage = prev_gross_margin * 100;
  const prev_gross_profit = prevRev / prev_p1QtyMoved - prevCostPerTon;

  return {
    gross_margin,
    gross_profit,
    gross_margin_percentage,
    prev_gross_margin,
    prev_gross_margin_percentage,
    prev_gross_profit,
  };
};

const getToLocaleString = (amount) => {
  if (!amount) return 0;
  return amount.toLocaleString();
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const calcCrow = (coords1, coords2) => {
  // const R = 6.371; // km
  const R = 6371000;
  const dLat = toRad(coords2.latitude - coords1.latitude);
  const dLon = toRad(coords2.longitude - coords1.longitude);
  const lat1 = toRad(coords1.latitude);
  const lat2 = toRad(coords2.latitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

// Converts numeric degrees to radians
const toRad = (Value) => {
  return (Value * Math.PI) / 180;
};

const roundToTwoDP = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const convertToFilterQueryString = (obj) => {
  let filterQuery = {};
  let result = [];

  //get query object with non empty values
  for (let prop in obj) {
    if (obj[prop]) {
      filterQuery = {
        ...filterQuery,
        [prop]: obj[prop],
      };
    }
  }

  for (let prop in filterQuery) {
    result.push(`${prop}=${filterQuery[prop]}`);
  }
  return result.join('&');
};

export {
  getReadableTimeStamp,
  getDateFormatted,
  getDate,
  getToLocaleString,
  roundToTwoDP,
};
