<template>
  <div>
    <div>
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :per-page="10"
        :current-page="currentPage"
        id="my-table"
      >
        <template v-slot:cell(actions)="data">
          <button
            class="btn btn-info btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Action
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button
              v-b-modal.modal-prevent-closing
              @click="openEditModal(data.item._id)"
              class="dropdown-item"
            >
              <i class="fa fa-edit"></i>
              Edit
            </button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              title="Edit Factory"
              @ok="updateAp"
            >
              <form ref="form" @submit.stop.prevent="updateAp">
                <b-form-group label="Name" label-for="name-input">
                  <b-form-input id="name-input" v-model="editAp.name">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Latitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editAp.latitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Longitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editAp.longitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Status">
                  <b-form-select
                    v-model="editAp.status"
                    :options="statusoptions"
                  ></b-form-select>
                </b-form-group>
              </form>
            </b-modal>
            <button
              class="dropdown-item"
              @click="showDeletePrompt(data.item._id)"
            >
              <i class="fa fa-trash text-danger"></i>
              Delete
            </button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="10"
        aria-controls="my-table"
      ></b-pagination>
      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Aps",
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      statusoptions: [
        { value: null, text: "Please select an option" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Underconstruction", text: "Underconstruction" },
      ],
      items: [],
      apUpdate: {},
      showEditModal: false,
      editAp: {},
      singleAp: {},
    };
  },
  mounted() {
    this.getAP();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    async getAP() {
      const ap = await this.$store.dispatch("markers/fetchAps");
      this.items = ap;
      this.apUpdate = ap;
    },
    async showDeletePrompt(itemId) {
      try {
        const isDeleteConfirmed = await this.$bvModal.msgBoxConfirm(
          "Please confirm that you want to delete this AP.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (!isDeleteConfirmed) return;
        const res = await this.$store.dispatch("markers/deleteMarker", itemId);
        const { data } = res;
        // updating the list on the UI after successful delete request
        if (data) {
          this.$toastr.i("AP successfully deleted");
          const idx = this.items.findIndex((ap) => ap._id === itemId);
          this.items.splice(idx, 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openEditModal(_id) {
      const ap = await this.$store.dispatch("markers/fetchSingleFactory", _id);
      this.editAp = ap;
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async updateAp(e) {
      e.preventDefault();
      try {
        const payload = {
          id: this.editAp._id,
          name: this.editAp.name,
          latitude: this.editAp.latitude,
          longitude: this.editAp.longitude,
          status: this.editAp.status,
        };
        const res = await this.$store.dispatch("markers/updateMarker", payload);
        if (res.status === 200) {
          this.$toastr.i("AP successfully updated");
          this.closeEditModal();
        }
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.updateAp();
    },
  },
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: right;
  background-color: #09571d;
  color: #fff;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.body {
  display: flex;
  justify-content: center;
}

.sidebar {
  width: 20%;
  background-color: #f5f5f5;
  padding: 20px;
}

.main-content {
  width: 80%;
  padding: 20px;
}
</style>
