import {fp} from "@/views/mills/data/featureLayersDetails";
import {baseURL} from "@/config/keys"

const viewFuturePrice = {
    title: "View Price Projection",
    id: "edit-this",
    className: "esri-icon-review"
};
export function communityPriceProjectionLayer(date){
    return {
        url: baseURL + `future-price/date?date=${date}`,
        title: "Community Price Projections",
        renderer: fp.renderer,
        popupTemplate: {
            title: "Releaf Assets - {communityName}",
            content: [
                {
                    type: "fields",
                    fieldInfos: [
                        {
                            fieldName: "communityName",
                            label: "Name",
                            visible: true,
                        },
                        {
                            fieldName: "previousPeriodP1Price",
                            label: "Current Price",
                            visible: true,
                            format: {
                                places: 2,
                                digitSeparator: true,
                            },
                        },
                        {
                            fieldName: "futurePrice",
                            label: "Community Price Projections",
                            visible: true,
                            format: {
                                places: 2,
                                digitSeparator: true,
                            },
                        },
                        {
                            fieldName: "futureDate",
                            label: "Date",
                            visible: true,
                        },
                    ],
                },
            ],
            actions: [viewFuturePrice],
        },
    }
}

