<template>
  <div>
    <div>
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :per-page="10"
        :current-page="currentPage"
        id="my-table"
      >
        <template v-slot:cell(actions)="data">
          <button
            class="btn btn-info btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Action
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <b-button
              v-b-modal.modal-prevent-closing
              @click="openEditModal(data.item._id)"
              class="dropdown-item"
            >
              <i class="fa fa-edit"></i>
              Edit
            </b-button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              title="Edit Customer Factory"
              @ok="updateFactory"
            >
              <form ref="form" @submit.stop.prevent="updateFactory">
                <b-form-group label="Name" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.name">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Phone Number" label-for="phone-input">
                  <b-form-input id="phone-input" v-model="editFactory.phoneNumber">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Latitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.latitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Longitude" label-for="name-input">
                  <b-form-input id="name-input" v-model="editFactory.longitude">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Raw Material">
                  <b-form-select
                    v-model="rawMaterials"
                    :options="materialoptions"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="Status">
                  <b-form-select
                    v-model="editFactory.status"
                    :options="statusoptions"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="Type">
                  <b-form-select
                    v-model="editFactory.factoryType"
                    :options="typeoptions"
                  ></b-form-select>
                </b-form-group>
              </form>
            </b-modal>
            <button
              class="dropdown-item"
              @click="showDeletePrompt(data.item._id)"
            >
              <i class="fa fa-trash text-danger"></i>
              Delete
            </button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="10"
        aria-controls="my-table"
      ></b-pagination>
      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Factory",
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "rawMaterials",
          label: "Raw Material",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "factoryType",
          label: "Type",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      selected: null,
      materialoptions: [
        { value: null, text: "Please select an option" },
        { value: "FFB", text: "FFB" },
        { value: "P1", text: "P1" },
        { value: "P2", text: "P2" },
        { value: "PKO", text: "PKO" },
        { value: ["FFB, P1, P2, PKO"], text: "FFB, P1, P2, PKO" },

        { value: "FFB, P1, P2", text: "FFB, P1, P2" },
        { value: "FFB, P1, PKO", text: "FFB, P1, PKO" },
        { value: "FFB, P2, PKO", text: "FFB, P2, PKO" },
        { value: "P1, P2, PKO", text: "P1, P2, PKO" },

        { value: "FFB, P1", text: "FFB, P1" },
        { value: "FFB, P2", text: "FFB, P2" },
        { value: "FFB, PKO", text: "FFB, PKO" },

        { value: "P1, P2", text: "P1, P2" },
        { value: "P1, PKO", text: "P1,PKO" },
        { value: "P2, PKO", text: "P2,PKO" },
      ],
      typeoptions: [
        { value: null, text: "Please select an option" },
        { value: "Crushing Mill", text: "Crushing Mill" },
        { value: "FFB Mill", text: "FFB Mill" },
        { value: "Deshelling Mill", text: "Deshelling Mill" },
        { value: "Kraken II", text: "Kraken II" },
        { value: "Refinery", text: "Refinery" },
        { value: "Cement", text: "Cement" },
        { value: "Other Industries", text: "Other Industries" },
      ],
      statusoptions: [
        { value: null, text: "Please select an option" },
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Underconstruction", text: "Underconstruction" },
      ],
      factoryUpdate: {},
      // name: this.singleFactory?.name || "",
      // rawMaterial: this.singleFactory?.rawMaterial || "",
      // status: this.singleFactory?.status || "",
      // type: this.singleFactory?.type || "",
      items: [],
      showEditModal: false,
      editFactory: {},
      singleFactory: {},
      rawMaterials: "",
    };
  },
  mounted() {
    this.getCustomerFactory();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    async getCustomerFactory() {
      const customers = await this.$store.dispatch("markers/fetchCustomers");

      this.items = customers;
      console.log("this item", this.items);
      this.factoryUpdate = customers;
    },
    async showDeletePrompt(itemId) {
      try {
        const isDeleteConfirmed = await this.$bvModal.msgBoxConfirm(
          "Please confirm that you want to delete this Customer Factory.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (!isDeleteConfirmed) return;
        const res = await this.$store.dispatch(
          "markers/deleteCustomer",
          itemId
        );
        const { data } = res;
        // updating the list on the UI after successful delete request
        if (data) {
          this.$toastr.i("Customer Factory successfully deleted");
          const idx = this.items.findIndex((factory) => factory._id === itemId);
          this.items.splice(idx, 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openEditModal(_id) {
      const factory = await this.$store.dispatch(
        "markers/fetchSingleCustomerFactory",
        _id
      );
      this.editFactory = factory;

      this.rawMaterials = this.editFactory.rawMaterials[0];
      // this.editFactory = data;
      // console.log(_id);
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async updateFactory(e) {
      e.preventDefault();
      try {
        const payload = {
          id: this.editFactory._id,
          name: this.editFactory.name,
          phoneNumber: this.editFactory.phoneNumber,

          latitude: this.editFactory.latitude,
          longitude: this.editFactory.longitude,
          rawMaterials: this.rawMaterials,
          status: this.editFactory.status,
          factoryType: this.editFactory.factoryType,
        };
        console.log("payload", payload);
        const res = await this.$store.dispatch(
          "markers/updateCustomer",
          payload
        );
        if (res.status === 200) {
          this.$toastr.i("Customer Factory successfully updated");
          this.closeEditModal();
        }
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    checkFormValidity() {
      // const valid = this.$refs.form.checkValidity();
      // this.nameState = valid;
      // return valid;
    },
    resetModal() {
      // this.name = "";
      // this.nameState = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.updateFactory();
    },
    handleSubmit() {},
  },
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: right;
  background-color: #09571d;
  color: #fff;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.body {
  display: flex;
  justify-content: center;
}

.sidebar {
  width: 20%;
  background-color: #f5f5f5;
  padding: 20px;
}

.main-content {
  width: 80%;
  padding: 20px;
}
</style>
