import { render, staticRenderFns } from "./p1-at-field-summary.vue?vue&type=template&id=a0b6f4ce&scoped=true&"
import script from "./p1-at-field-summary.vue?vue&type=script&lang=js&"
export * from "./p1-at-field-summary.vue?vue&type=script&lang=js&"
import style0 from "./p1-at-field-summary.vue?vue&type=style&index=0&id=a0b6f4ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0b6f4ce",
  null
  
)

export default component.exports