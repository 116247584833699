<template>
  <div>
    <div v-show="showClusteredMillSummary" class="summary-board summary-shadow">
      <h1 class="text-center"><b>Summary Board</b></h1>
      <p @click="showContainerFx" class="close-x">X</p>

      <!-- Releaf Factory summary -->
      <div class="p1-summary mt-3">
        <p><b>CPO Mills Cluster Summary</b></p>
        <ul class="mt-2">
          <li>
            Total No. of Mill Clusters {{ this.layersObject.totalNoClusters }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { arcgisURL } from "@/config/api-config";

export default {
  name: "FpsClientLayersSummy",
  props: {
    showClusteredMillSummary: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      layersObject: {},
    };
  },

  mounted() {
    this.loadEsri();
  },

  computed: {},

  methods: {
    showContainerFx() {
      this.showClusteredMillSummary = !this.showClusteredMillSummary;
    },
    loadEsri() {
      loadModules(["esri/layers/FeatureLayer"]).then(([FeatureLayer]) => {
        this.layersObject = {
          totalNoClusters: 0,
        };

        const millCluster = new FeatureLayer({
          url: arcgisURL + "taggedMillsClus/FeatureServer",
        });

        var toObjectLayer = this.layersObject;
        let query = millCluster.createQuery();

        millCluster
          .queryFeatures(query)
          .then(function (response) {
            let layerAttributesArray = response.features.map(
              (feature) => feature.attributes
            );
            toObjectLayer.totalNoClusters = layerAttributesArray.length;

            return layerAttributesArray; // Return the array for further processing if needed
          })
          .catch(function (error) {
            console.error("Error occurred during queryFeatures:", error);
          });
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.close-x {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.summary-board {
  padding: 40px;
  width: 500px;
  height: 300px;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  bottom: 100px;
  left: 70px;
}

.summary-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

ol,
ul {
  list-style-type: circle;
}
</style>