export const oilPalmPFData = [
    {
      ParentCompany: "Eyop Industries Ltd",
      Suppliers: "Ibad Palm Oil Mill",
      RSPOStatus: "No",
      latitude: 5.527424,
      longitude: 8.150314,
      Address: "Ekong-Anaku Road, Aningeje Akamkpa Local Government, Calabar, Cross River, Nigeria",
      Product: ""
    },
    {
      ParentCompany: "Aden River Estates Limited",
      Suppliers: "Aden River Estates Limited",
      RSPOStatus: "Yes - MB",
      latitude: 6.405017,
      longitude: 5.235370,
      Address: "Aden, near Ugbogui near Okada Village, Edo State.",
      Product: ""
    },
    {
      ParentCompany: "IMC",
      Suppliers: "Nsukwa Oil Palm Estate Limited",
      RSPOStatus: "No",
      latitude: 6.015917,
      longitude: 6.411891,
      Address: "Nsukwa, near Ogwashi-Uku, Delta State",
      Product: ""
    },
    {
      ParentCompany: "Biase Plantations Limited",
      Suppliers: "Calaro Palm Oil Mill",
      RSPOStatus: "No",
      latitude: 5.290837,
      longitude: 8.298390,
      Address: "Calaro Oil Palm Estate, Mbarakom, Akamkpa LGA, Cross River Estate, Nigeria",
      Product: ""
    },
    {
      ParentCompany: "Monica",
      Suppliers: "",
      RSPOStatus: "No",
      latitude: 5.495388,
      longitude: 7.482471,
      Address: "2 Aba Rd, 440236, Umuahia",
      Product: ""
    },
    {
      ParentCompany: "Jilnas",
      Suppliers: "",
      RSPOStatus: "No",
      latitude: 5.5343558,
      longitude: 7.4712972,
      Address: "GFMC+RJ6, Eziama Ossah 440236, Umuahia",
      Product: ""
    },
    {
      ParentCompany: "Polema",
      Suppliers: "",
      RSPOStatus: "No",
      latitude: 5.1650775,
      longitude: 7.3157916,
      Address: "Osisioma Aba Abia State",
      Product: ""
    },
    {
      ParentCompany: "New Rivoc",
      Suppliers: "",
      RSPOStatus: "No",
      latitude: 4.8343085,
      longitude: 7.0316556,
      Address: "PLOT 80, 81 Trans-Amadi Industrial Layout Rd, Trans Amadi 500102, Port Harcourt",
      Product: ""
    },
    {
      ParentCompany: "Zionbim Farminex Nig Ltd",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.64556,
      longitude: 3.34173,
      Address: "Ogun, Oyo, Ikeja, Lagos",
      Product: "Honey,CPO ,Corn"
    },
    {
      ParentCompany: "O.J Success Ventures NIG Ltd",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 7.89466,
      longitude: 6.91968,
      Address: "Ishiuzo Egbu, Owerri North , Owerri, Imo.",
      Product: "CPO,Shoe production"
    },
    {
      ParentCompany: "The Ahamefulas: Palm Permutations",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.62936,
      longitude: 3.35752,
      Address: "14 Celestial Church Street, Off Hercules Bus Stop,Lagos.",
      Product: "Palm kernel nuts "
    },
    {
      ParentCompany: "Onwugamba Foods Agro and Chemicals",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.46037,
      longitude: 3.20223,
      Address: "7 mayegun street ojo, Lagos",
      Product: "CPO,Organic long rice"
    },
    {
      ParentCompany: "Shigotech Solutions System Ltd.",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 7.80508,
      longitude: 4.56209,
      Address: "No 12 wosem street, oke onitea area, Osogbo, Osun State",
      Product: "CPO"
    },
    {
      ParentCompany: "Bettymor Enterprises Limited",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.10586,
      longitude: 7.19867,
      Address: "120 Dsc Express Way Ovwian, Udu LGA, Warri, Delta State,",
      Product: "CPO,Coffee,Rubber"
    },
    {
      ParentCompany: "Nosak Distilleries Limited",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.47798,
      longitude: 3.32182,
      Address: "Plot C34, Nosak Road, Amuwo Odofin Industrial Estate, Amuwo Odofin, Lagos",
      Product: "Crude CPO"
    },
    {
      ParentCompany: "Jonnykelle Farms Ltd",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 5.43349,
      longitude: 7.5444,
      Address: "JK House 6, Accara Lane, Umuahia, Abia",
      Product: "Crude CPO"
    },
    {
      ParentCompany: "Neerg Pko Ventures",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.35055,
      longitude: 5.66142,
      Address: "SHOP 35 RAMART PARK, OREGBENI MARKET IKPOBA HILL, Benin City, Edo",
      Product: "CPO,Kernel fruits"
    },
    {
      ParentCompany: "CHISREAL INDUSTRIES LTD",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 5.89124,
      longitude: 7.93134,
      Address: "3B REACH AVENUE OFF OZIZZA ROAD, AFIKPO, AFIKPO NORTH L.G.A., Afikpo, Ebonyi",
      Product: "CPO machinery supplies"
    },
    {
      ParentCompany: "The Mastersource",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 10.54606,
      longitude: 7.46466,
      Address: "2B Dan Amar Road U/Rimi GRA Kaduna, Kaduna.",
      Product: "CPO.Cashew Nuts"
    },
    {
      ParentCompany: "Elvkells oil palm",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 4.83508,
      longitude: 7.095,
      Address: "Apkajo eleme, Port Harcourt, Rivers",
      Product: "CPO,Palm nuts,pko and pks"
    },
    {
      ParentCompany: "Queensoil Ventures",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.83185,
      longitude: 3.63248,
      Address: "22B Araromi Street, Off Adigbe Road, Abeokuta South, Ogun State, Nigeria",
      Product: "CPO"
    },
    {
      ParentCompany: "Swift global investment Nigeria LTD",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 7.37774,
      longitude: 3.94792,
      Address: "Shop 5 Oladuni complex, ekerin junction Ologuneru, Ibadan, Oyo",
      Product: "CPO"
    },
    {
      ParentCompany: "Okomu Oil Palm Company",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.34772,
      longitude: 5.62235,
      Address: "Okomu Udo, Ovia South Local Government Area, Benin City, Edo State, Nigeria",
      Product: "Palm Oil Products and Rubber Products"
    },
    {
      ParentCompany: "SIAT Nigeria Ltd",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 5.16637,
      longitude: 6.91682,
      Address: "Ubima Estate,Km 6 Elele/Owerri Road,Port Harcourt,Rivers State",
      Product: "CPO,CPKO"
    },
    {
      ParentCompany: "PZ Wilmar",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.6196,
      longitude: 3.51095,
      Address: "KM6, Shagamu Road,Opposite Army Barracks, Ilosi-Oba Village, Ikorodu, Lagos State, Nigeria",
      Product: "Vegetable oil"
    },
    {
      ParentCompany: "Presco Plc",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.2307,
      longitude: 5.63788,
      Address: "Obaretin Estate,Km 22 Benin / Sapele Road,Ikpoba-Okha Local Government Area",
      Product: "CPKO,RPKO,RBDO"
    },
    {
      ParentCompany: "Okitipupa Palm Oil Plc",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.50214,
      longitude: 4.77951,
      Address: "No. 1, Marine Road,Okitipupa OndoState, Nigeria",
      Product: "Agro Allied Products,Palm Kernel Production,CPO Production,"
    },
    {
      ParentCompany: "Dijesr Fennimol Limited",
      Suppliers: "",
      RSPOStatus: "",
      latitude: 6.66142,
      longitude: 3.31334,
      Address: "8 Dayo Adeniji Street, New Oko Oba, Ifako Ijaiye, Lagos State, Nigeria",
      Product: "CPO,Cocoa Seed and Honey"
    }
  ]