<template>
  <div>
    <!-- toggle between 2D and 3D -->
    <ToggleMap />
    <div ref="map" id="map"></div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { APIKeys, baseURL } from "../../config/keys";
import { clusterConfig } from "./clusterConfig";
import { fld } from "./data/featureLayersDetails";
import ToggleMap from "../../components/map/ToggleMap.vue";

export default {
  name: "MyMap3D",

  components: {
    ToggleMap,
  },

  data() {
    return {
      view: null,
    };
  },

  mounted() {
    this.loadMap();
  },

  methods: {
    loadMap() {
      loadModules(
        [
          "esri/config",
          "esri/Map",
          "esri/views/SceneView",
          "esri/widgets/Home",
          "esri/widgets/Search",
          "esri/layers/GeoJSONLayer",
          "esri/layers/FeatureLayer",
          "esri/widgets/LayerList",
          "esri/widgets/Expand",
          "esri/widgets/BasemapToggle",
        ],
        {
          css: true,
        }
      ).then(
        ([
          esriConfig,
          ArcGISMap,
          SceneView,
          Home,
          Search,
          GeoJSONLayer,
          FeatureLayer,
          LayerList,
          Expand,
          BasemapToggle,
        ]) => {
          esriConfig.apiKey = APIKeys;

          // Oil Palm Processing Facilities
          const oilpalmPFacilities = new FeatureLayer({
            title: "Oil Palm Processing Facilities",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/oilPalmPF/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#83b04f",
                outline: {
                  color: "rgba(230, 239, 220, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Oil Palm Processing Facilities - {Suppliers}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "Address",
                      label: "Address",
                      visible: true,
                    },
                    {
                      fieldName: "Suppliers",
                      label: "Suppliers",
                      visible: true,
                    },
                    {
                      fieldName: "ParentCompany",
                      label: "Parent Company",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          // Oil Palm Processing Facilities
          const miniGrids = new FeatureLayer({
            title: "Minigrids",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/Renewvia_pipeline_development_sites/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#b5838d",
                outline: {
                  color: "rgba(245, 239, 240, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Minigrids - {Site_Name}, {State}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "State",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "Site_Name",
                      label: "Site Name",
                      visible: true,
                    },
                    {
                      fieldName: "F_of_Connections_on_ground",
                      label: "No of Ground Connections",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //Georeferenced Oil Palm Data
          const geoRFoilpalmdata = new FeatureLayer({
            title: "Georeferenced Oil Palm Data",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/Georeferenceoilpalm/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#c9ada7",
                outline: {
                  color: "rgba(235, 226, 224, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title:
                "Georeferenced Oil Palm Data - {nigeriaPULL_regionpullnigeria}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "nigeriaPULL_regionpullnigeria",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "farmarea_trace_ha",
                      label: "Size (ha)",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //LGA Data - LGA transaction summary
          const lgadata = new FeatureLayer({
            title: "LGA transaction summary",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/PindTransactions/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#888caa",
                outline: {
                  color: "rgba(225, 226, 234, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "LGA transaction summary - {Name}, {State}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "State",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "Name",
                      label: "Name",
                      visible: true,
                    },
                    {
                      fieldName: "Transactions",
                      label: "No of Transactions",
                      visible: true,
                    },
                    {
                      fieldName: "No_Of_Loans",
                      label: "No of Loans",
                      visible: true,
                    },
                    {
                      fieldName: "Total_Amount_Paid",
                      label: "Total Amount Paid (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "CostPerTonne",
                      label: "Cost Per Tonne (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "Total_Ton_At_Source",
                      label: "Total P1 sourced",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //LGA Data - Akwa Ibom Plantation
          const akwaibomP = new FeatureLayer({
            title: "Akwa Ibom Plantations",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/akwaIbomPlantation/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#eb4778",
                outline: {
                  color: "rgba(250, 209, 221, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Akwa Ibom Plantations - {NameOfFarm}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "LGA",
                      label: "LGA",
                      visible: true,
                    },
                    {
                      fieldName: "Village",
                      label: "Village",
                      visible: true,
                    },
                    {
                      fieldName: "NameOfFarm",
                      label: "Name Of Farm",
                      visible: true,
                    },
                    {
                      fieldName: "SizeHa",
                      label: "Size (ha)",
                      visible: true,
                    },
                    {
                      fieldName: "Age",
                      label: "Age",
                      visible: true,
                    },
                    {
                      fieldName: "Type",
                      label: "Palm Type",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });
          //CPO layer
          const cpoLayer = new FeatureLayer({
            title: "Crude Palm Oil Layer",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/CPO_market_Depot/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#e6a400",
                outline: {
                  color: "rgba(255, 183, 3, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Crude Palm Oil Layer - {Location}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "State",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "L_G",
                      label: "LGA",
                      visible: true,
                    },
                    {
                      fieldName: "Location",
                      label: "Location",
                      visible: true,
                    },
                    {
                      fieldName: "Cpo_business_type",
                      label: "CPO Business Type",
                      visible: true,
                    },
                    {
                      fieldName: "Level_of_activities",
                      label: "Level of Activities",
                      visible: true,
                    },
                    {
                      fieldName: "Scout__RP",
                      label: "Scout",
                      visible: true,
                    },
                    {
                      fieldName: "Remarks",
                      label: "Remarks",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });
          //Pind Oil Palm Layer
          const pindOilPalm = new FeatureLayer({
            title: "Pind Oil Palm",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/PindOilPalm/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#ffd166",
                outline: {
                  color: "rgba(255, 240, 204, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Pind Oil Palm Data - {Village}, {State}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "State",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "LGA",
                      label: "LGA",
                      visible: true,
                    },
                    {
                      fieldName: "Village",
                      label: "Village",
                      visible: true,
                    },
                    {
                      fieldName: "No_of_farmers",
                      label: "No of farmers",
                      visible: true,
                    },
                    {
                      fieldName: "Cluster_Made",
                      label: "Cluster Made",
                      visible: true,
                    },
                    {
                      fieldName: "Farming_Years",
                      label: "Farming Years",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //Pind Community Data
          const pindCommunity = new FeatureLayer({
            title: "Community transaction summary",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/Community/FeatureServer",
            copyright: "",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#43c4ef",
                outline: {
                  color: "rgba(208, 240, 251, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Community transaction summary - {Name}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "Name",
                      label: "Name of Community",
                      visible: true,
                    },
                    {
                      fieldName: "No_Of_Transactions",
                      label: "No Of Transactions",
                      visible: true,
                    },
                    {
                      fieldName: "No_Of_Loans",
                      label: "No Of Loans",
                      visible: true,
                    },
                    {
                      fieldName: "Total_Amount_Paid",
                      label: "Total Amount Paid (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "Cost_Per_Tonne",
                      label: "Cost Per Tonne (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "Total_Ton_At_Source",
                      label: "Total P1 sourced",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //Geojson for APs and stock levels
          // const APs = new GeoJSONLayer({
          //   url: "https://sandbox.api.salt.releaf.ng/api/destination/GeoJSON",
          //   title: "APs and Stock Levels",
          //   featureReduction: clusterConfig,
          //   renderer: {
          //     type: "simple",
          //     // field: "mag",
          //     symbol: {
          //       type: "simple-marker",
          //       size: 15,
          //       color: "#cea47e",
          //       outline: {
          //         color: "rgba(241, 229, 218, 0.5)",
          //         width: 5,
          //       },
          //     },
          //   },
          //   popupTemplate: {
          //     title: "APs and Stock Levels",
          //     content: [
          //       {
          //         type: "fields",
          //         fieldInfos: [
          //           {
          //             fieldName: "name",
          //             label: "Name",
          //             visible: true,
          //           },
          //           {
          //             fieldName: "longitude",
          //             label: "Longitude",
          //             visible: true,
          //           },
          //           {
          //             fieldName: "latitude",
          //             label: "Latitude",
          //             visible: true,
          //           },
          //         ],
          //       },
          //     ],
          //   },
          // });

          //Geojson for releaf tagged mills
          const RFTaggedMills = new GeoJSONLayer({
            url: baseURL + "purchase/mills-purchase-aggregates?isGeoJSON=yes",
            title: "Releaf Tagged",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              // field: "mag",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#cea47e",
                outline: {
                  color: "rgba(241, 229, 218, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Releaf Tagged Mills - {name}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "name",
                      label: "Mill Owner",
                      visible: true,
                    },
                    {
                      fieldName: "state_slug",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "lga_slug",
                      label: "LGA",
                      visible: true,
                    },
                    {
                      fieldName: "cracker",
                      label: "Cracker",
                      visible: true,
                    },
                    {
                      fieldName: "separator",
                      label: "Separator",
                      visible: true,
                    },
                    {
                      fieldName: "number_of_pots",
                      label: "Number Of Pots",
                      visible: true,
                    },
                    {
                      fieldName: "number_of_pressers",
                      label: "Number Of Pressers",
                      visible: true,
                    },
                    {
                      fieldName: "physically_tagged",
                      label: "Physically Tagged",
                      visible: true,
                    },
                    {
                      fieldName: "totalPrice",
                      label: "Total Amount Spent (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "tonsAmount",
                      label: "Tonnes Bought",
                      visible: true,
                    },
                    {
                      fieldName: "avgPricePerTon",
                      label: "Average Price Per Tonne (₦)",
                      visible: true,
                    },
                    {
                      fieldName: "noOfTransactions",
                      label: "Number of Transactions",
                      visible: true,
                    },
                    {
                      fieldName: "size",
                      label: "Mill Size",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //Releaf Assets
          const releafAsset = new FeatureLayer({
            title: "Releaf Assets",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/releafAssets/FeatureServer",
            copyright: "",
            outFields: ["*"],
            id: "assetLayer",
            // geometryType: "point",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              // field: "mag",
              symbol: {
                type: "simple-marker",
                size: 15,
                color: "#e7967e",
                outline: {
                  color: "rgba(247, 220, 212, 0.5)",
                  width: 5,
                },
              },
            },
            popupTemplate: {
              title: "Releaf Assets - {name}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "name",
                      label: "Name",
                      visible: true,
                    },
                    {
                      fieldName: "type",
                      label: "Type",
                      visible: true,
                    },
                    {
                      fieldName: "rawMaterial",
                      label: "Material",
                      visible: true,
                    },
                    {
                      fieldName: "status",
                      label: "Status",
                      visible: true,
                    },
                    {
                      fieldName: "latitude",
                      label: "Latitude",
                      visible: true,
                    },
                    {
                      fieldName: "longitude",
                      label: "Longitude",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          const lgaOilPalm = new FeatureLayer({
            title: "Nigeria LGA Boundaries",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/ngnLGA/FeatureServer",
            labelingInfo: "ADM2_EN",
            opacity: 0.5,
            copyright: "",
            geometryType: "polygon",
            renderer: fld.renderer,
            popupTemplate: {
              title: "Summary for {ADM2_EN} LGA",
              expressionInfos: [
                {
                  name: "change",
                  title: "Current Price of P1 (₦)",
                  expression: `
                  function checkValue(value){
                  
                    var val = IsEmpty(value);
                    if (val) {
                      return "No historic purchase"
                    }
                    else {
                      return value
                    }
                  }
                  checkValue($feature.currP1Price);
                      `,
                },
              ],
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "ADM1_EN",
                      label: "State",
                      visible: true,
                    },
                    {
                      fieldName: "ADM2_EN",
                      label: "LGA",
                      visible: true,
                    },
                    {
                      fieldName: "oilClusterStr",
                      label: "Total size of OilPalm Cluster (ha)",
                      visible: true,
                    },
                    {
                      fieldName: "tones",
                      label: "Oil Palm Cluster Tonnes (T)",
                      visible: true,
                      format: {
                        places: 2,
                        digitSeparator: true,
                      },
                    },
                    {
                      fieldName: "noTaggedMi",
                      label: "Number of Tagged Mills",
                      visible: true,
                    },
                    {
                      fieldName: "p1Weekly",
                      label: "P1 yield per week",
                      visible: true,
                    },
                    {
                      fieldName: "avgPricePe",
                      label: "Ave P1 price/T (₦)",
                      visible: true,
                      format: {
                        places: 0,
                        digitSeparator: true,
                      },
                    },
                    {
                      fieldName: "noPindTran",
                      label: "Total Number of Transactions",
                      visible: true,
                    },
                    {
                      fieldName: "noOilPalmF",
                      label: "Total Oil Palm Processing Facilities",
                      visible: true,
                    },
                    {
                      fieldName: "expression/change",
                      label: "Current Price of P1 (₦)",
                      visible: true,
                      format: {
                        places: 2,
                        digitSeparator: true,
                      },
                    },
                    {
                      fieldName: "updatedAt",
                      label: "Last updated",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          //OilPalmAll Cluster
          const palmoilclusterFL = new FeatureLayer({
            title: "Oil Palm Clusters",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/palmclustersAllPrj/FeatureServer",
            opacity: 0.8,
            copyright: "",
            geometryType: "polygon",
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-fill",
                // size: 15,
                color: "#2a9d8f",
                outline: {
                  color: "rgba(10, 41, 37, 10%)",
                  width: 0.2,
                },
              },
            },
            popupTemplate: {
              title: "Oil Palm Cluster - {ha_area_1} (ha)",
              // symbol: symbol,
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "ha_area_1",
                      label: "Area Size (ha)",
                      visible: true,
                    },
                    {
                      fieldName: "tones",
                      label: "Tonnes yield (T)",
                      visible: true,
                    },
                    {
                      fieldName: "WeeklyP1Yield",
                      label: "Weekly P1 Yield (T)",
                      visible: true,
                    },
                    {
                      fieldName: "WeeklyFFBYield",
                      label: "Weekly FFB Yield (T)",
                      visible: true,
                    },
                    {
                      fieldName: "Ages",
                      label: "Age",
                      visible: true,
                    },
                    {
                      fieldName: "BulkDens",
                      label: "Bulk Density",
                      visible: true,
                    },
                    {
                      fieldName: "layer",
                      label: "State",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          this.cluster = palmoilclusterFL;

          // create map with the given options
          const map = new ArcGISMap({
            basemap: "arcgis-light-gray",
            layers: [
              // APs,
              miniGrids,
              oilpalmPFacilities,
              cpoLayer,
              akwaibomP,
              pindCommunity,
              lgadata,
              pindOilPalm,
              geoRFoilpalmdata,
              lgaOilPalm,
              releafAsset,
              RFTaggedMills,
              palmoilclusterFL,
            ],
          });

          // //For 3D
          this.view = new SceneView({
            container: this.$el,
            map: map,
            camera: {
              position: {
                x: 7.351415, //Longitude
                y: 4.056338, //Latitude
                z: 900000, //Meters
              },
              tilt: 10,
            },
          });

          //Adding basemap toggle
          const basemapToggle = new BasemapToggle({
            view: this.view,
            nextBasemap: "arcgis-imagery",
          });
          this.view.ui.add(basemapToggle, "bottom-right");

          //Add home button widget here
          let homeWidget = new Home({
            view: this.view,
          });
          // adds the home widget to the top left corner of the MapView
          this.view.ui.add(homeWidget, "top-left");

          //Add Search bar
          let searchWidget = new Search({ view: this.view });
          this.view.ui.add(searchWidget, "top-left");

          //Add Layer List
          let layerList = new LayerList({
            view: this.view,
            listItemCreatedFunction: (event) => {
              const item = event.item;
              // console.log(item);
              if (item.title === "APs and Stock Levels") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Community transaction summary") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Oil Palm Processing Facilities") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Oil Palm Clusters") {
                // open the list item in the LayerList
                item.visible = true;
              }
              if (item.title === "Akwa Ibom Plantations") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "LGA transaction summary") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Minigrids") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Georeferenced Oil Palm Data") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Crude Palm Oil Layer") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Pind Oil Palm") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Tonne Yield for Oil Palm Cluster") {
                // open the list item in the LayerList
                item.visible = true;
              }
              if (item.title === "Releaf Assets") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.title === "Releaf Tagged Mills") {
                // open the list item in the LayerList
                item.visible = true;
              }
              if (item.title === "Nigeria LGA Boundaries") {
                // open the list item in the LayerList
                item.visible = false;
              }
              if (item.layer.type != "group") {
                // don't show legend twice
                item.panel = {
                  content: "legend",
                  open: false,
                  visible: true,
                };
              }
            },
          });

          //Add layer list expanded
          const layerListExpand = new Expand({
            expandIconClass: "esri-icon-layer-list",
            view: this.view,
            content: layerList,
            group: "top-left",
          });
          layerListExpand.expandTooltip = "Layers List";
          this.view.ui.add(layerListExpand, "top-left");
        }
      );
    },
  },
};
</script>

<style>
#map {
  height: 100vh;
}
</style>
