<template>
  <div>
    <div class="layout-parent">
      <div class="side-bar-container"><Sidebar /></div>
      <div class="main-content-container">
        <div class="header-container"><Header /></div>

        <div class="main-content">
          <slot />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "../../components/admin-dashboard/header/Header.vue";
  import Sidebar from "../../components/admin-dashboard/side-bar/SideBar.vue";

  export default {
    name: "DashboardLayout",

    components: { Header, Sidebar },
  };
</script>

<style scoped>
  .layout-parent {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .side-bar-container {
    width: 18%;
    height: 100%;
  }

  .main-content-container {
    flex: 1;
  }

  .header-container {
    width: 100%;
    height: 8%;
  }

  .main-content {
    width: 100%;
  }
  .add-asset-prompt {
    position: fixed;
    top: 50%;
    left: 90%;
    padding: 20px;
  }
</style>
