import {fld} from "@/views/mills/data/featureLayersDetails";
import {arcgisURL} from "@/config/api-config";

const lgaSummaryLayer = {
    title: "P1 LGA Summary",
    url: arcgisURL + "ngnLGA/FeatureServer",
    labelingInfo: "ADM2_EN",
    opacity: 0.5,
    copyright: "",
    geometryType: "polygon",
    renderer: fld.renderer,
    popupTemplate: {
        title: "Summary for {ADM2_EN} LGA, {ADM1_EN} State.",
        expressionInfos: [
            {
                name: "change",
                title: "Current Price of P1 (₦)",
                expression: `
                  function checkValue(value){
                  
                    var val = IsEmpty(value);
                    if (val) {
                      return "No historic purchase"
                    }
                    else {
                      return value
                    }
                  }
                  checkValue($feature.currP1Price);
                      `,
            },
        ],
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "tonSourced",
                        label: "Total P1 sourced",
                        visible: true,
                    },
                    {
                        fieldName: "noOfTransa",
                        label: "Number of Transactions",
                        visible: true,
                    },
                    {
                        fieldName: "aveP1Pur",
                        label: "Average P1 Purchased (T)",
                        visible: true,
                    },
                    {
                        fieldName: "mill_id_co",
                        label: "Number of CPO Tagged Mills",
                        visible: true,
                    },
                    {
                        fieldName: "aveTPur",
                        label: "Average Tonnes Purchases/Mill",
                        visible: true,
                    },
                    {
                        fieldName: "price_max",
                        label: "Current P1 Price",
                        visible: true,
                    },
                    {
                        fieldName: "date_max",
                        label: "Last Purchase Date of P1",
                        visible: true,
                    },
                    {
                        fieldName: "noOilPalmF",
                        label: "Total Oil Palm Processing Facilities",
                        visible: true,
                    },
                    {
                        fieldName: "loans_sum",
                        label: "Number of Loans",
                        visible: true,
                    },
                    {
                        fieldName: "date_min",
                        label: "Date of First Purchase in LGA",
                        visible: true,
                    },
                    {
                        fieldName: "weeks_min",
                        label: "Number of Weeks Purchasing in LGA",
                        visible: true,
                    },
                    {
                        fieldName: "aveTP1Week",
                        label: "Average Tonnes of P1 Sourced/Week",
                        visible: true,
                    },
                ],
            },
        ],
    },
}
export default lgaSummaryLayer;

