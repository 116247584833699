export const forestCoverLossRenderer = {
    type: "class-breaks",
    field: "loss",
    legendOptions: {
      title: "Forest Cover Loss"
    },
    classBreakInfos: [
      {
        minValue: 0,
        maxValue: 0,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(0, 129, 167, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "0 ha"
      },
      {
        minValue: 1,
        maxValue: 128,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(0, 175, 185, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "1 - 128 ha"
      },
      {
        minValue: 128,
        maxValue: 860,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(253, 252, 220, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "28 - 357 ha"
      },
      {
        minValue: 861,
        maxValue: 5728,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(254, 217, 183, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "861 - 5,728 ha"
      },
      {
        minValue: 5728,
        maxValue: 23000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(240, 113, 103, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "5728 - 23,000 ha"
      },
    ]
  };