//high carbon stocks area renderer
export const highCarbonStocksRenderer = {
    type: "class-breaks",
    field: "gridcode",
    legendOptions: {
      title: "Tonnes biomass"
    },
    defaultSymbol: {
      type: "simple-fill",
      color: "#f3f3f1",
      style: "solid",
      outline: {
        color: "rgba(10, 41, 37, 0.4)",
        width: 0.2,
    },
    },
    defaultLabel: "no data",
    classBreakInfos: [
      {
        minValue: 21001,
        maxValue: 37850,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#0B2C79",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "21001 - 37850 (0.01 tons biomass C/hectare)"
      },
      {
        minValue: 16901,
        maxValue: 21000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#124982",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "16901 - 21000"
      },
      {
        minValue: 13401,
        maxValue: 16900,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#16668A",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "13401 - 16900"
      },
      {
        minValue: 11251,
        maxValue: 13400,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#1B8691",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "11251 - 13400"
      },
      {
        minValue: 9651,
        maxValue: 11250,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#1F998F",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "9651 - 11250"
      },

      {
        minValue: 8451,
        maxValue: 9650,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#19A87C",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "8451 - 9650"
      },

      {
        minValue: 7501,
        maxValue: 8450,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#14BA5F",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "7501 - 8450"
      },

      {
        minValue: 6101,
        maxValue: 7500,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#00DB00",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "6101 - 7500"
      },

      {
        minValue: 5301,
        maxValue: 6100,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#3CE300",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "5301 - 6100"
      },

      {
        minValue: 4801,
        maxValue: 5300,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#7BEC00",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "4801 - 5300"
      },

      {
        minValue: 4351,
        maxValue: 4800,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#BCF400",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "4351 - 4800"
      },

      {
        minValue: 3901,
        maxValue: 4350,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#FFFF00",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "3901 - 4350"
      },

      {
        minValue: 3551,
        maxValue: 3900,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#FAE209",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "3551 - 3900"
      },
      
      {
        minValue: 3001,
        maxValue: 3550,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#F5CA09",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "3001 - 3550"
      },

      {
        minValue: 1926,
        maxValue: 3000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#F0B310",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "1926 - 3000"
      },
      
      {
        minValue: 1101,
        maxValue: 1925,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#EDA014",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "1101 - 1925"
      },
      
      {
        minValue: 676,
        maxValue: 1100,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#DF8220",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "676 - 1100"
      },
      
      {
        minValue: 301,
        maxValue: 675,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#D66E2B",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "301 - 675"
      },
      
      {
        minValue: 1,
        maxValue: 300,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#CB6035",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "1 - 300 (0.01 tons biomass C/hectare) "
      },
      
      {
        minValue: 0,
        maxValue: 0.9,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "#fff",
            outline: {
                color: "rgba(10, 41, 37, 0.4)",
                width: 0.2,
            },
          },
        label: "1 - 300 (0.01 tons biomass C/hectare) "
      },
    ]
  };