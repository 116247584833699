import { render, staticRenderFns } from "./nigeria-ward-summary.vue?vue&type=template&id=bca9074c&scoped=true&"
import script from "./nigeria-ward-summary.vue?vue&type=script&lang=js&"
export * from "./nigeria-ward-summary.vue?vue&type=script&lang=js&"
import style0 from "./nigeria-ward-summary.vue?vue&type=style&index=0&id=bca9074c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca9074c",
  null
  
)

export default component.exports