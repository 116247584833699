<template>
  <div>
    <!-- Spinner for serviceArea -->
    <Loader :loading="loading"/>
    <LightOverlay :loading="loading"/>

    <!-- Timeout modal -->
    <b-modal id="bv-modal-example" hide-footer>
      <div class="d-block text-center">
        <h3>
          Toggle the Service Area button right-hand side, then click anywhere on
          the map to begin.
        </h3>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
      >Close Me
      </b-button
      >
    </b-modal>

    <!-- Timeout modal -->
    <b-modal id="bv-modal-example2" hide-footer>
      <div class="d-block text-center">
        <h3>
          Toggle off the Service Area button. Now, click anywhere on the service
          area drawned for more details
        </h3>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example2')"
      >Close Me
      </b-button
      >
    </b-modal>

    <!-- Service Area Toggle -->
    <div
        class="saDraw"
        style="text-align: center; justify-content: center; align-items: center"
        v-b-tooltip.hover
        title="Service Area"
        data-v-step="2"
    >
      <img
          src="https://cdn-icons-png.flaticon.com/512/1034/1034129.png?w=1060&t=st=1654678382~exp=1654678982~hmac=353558f917e616ef20268a5937ca377c428459b18acebae3bd182538f88ad1ad"
          width="25px"
          height="25px"
          style="
          margin-bottom: 8px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        "
          v-b-tooltip.hover
          title="Service Area"
      />
      <label class="switch">
        <input type="checkbox" @click="toggleServiceArea"/>
        <span class="slider round"></span> </label
      ><br/>

      <!-- <button @click="clearServiceArea">X</button> -->
    </div>

    <!-- map -->
    <div ref="map" id="map"></div>
  </div>
</template>

<script>
import {loadModules} from "esri-loader";
import {getMillsAggregatedPurchases} from "../../utils";
import Loader from "../../components/loader/Loader.vue";
import LightOverlay from "../../components/loader/LightOverlay.vue";
import {APIKeys} from "../../config/keys";

export default {
  name: "MyMap",
  components: {
    Loader,
    LightOverlay,
  },
  data() {
    return {
      list: [],
      view: null,
      map: null,
      serviceArea: null,
      serviceAreaStartingPoint: {},
      serviceAreaParams: null,
      locationGraphic: null,
      outSpatialReference: null,
      FeatureSet: null,
      driveTimeCutoffs: [0, 0, 0],
      saToggle: false,
      toggleFilterForm: false,
      serviceAreaValue: 60,
      params: {},
      me: true,
      loading: false,
      mills: [],
      oilpalmClusters: [],
      cluster: {},
      serviceAreaArgs: {},
      showServiceAreaModal: false,
      steps: [
        {
          target: "#v-step-0",
          header: {
            title: "Welcome!",
          },
          content: `Explore Site in easy steps <br><strong>Take a quick tour.</strong>`,
        },
        {
          target: '[data-v-step="1"]',
          content: "Switch between map views.",
        },
        {
          target: '[data-v-step="2"]',
          content: "Explore areas to setup your factory.",
        },
        {
          target: '[data-v-step="3"]',
          content: "Switch between commodities.",
        },
        {
          target: '[data-v-step="5"]',
          content: "Slide to explore yield data.",
        },
        {
          target: '[data-v-step="6"]',
          content: "Edit assets with pen tool.",
        },
        {
          target: '[data-v-step="7"]',
          content: "Filter tagged mills with properties",
        },
      ],
    };
  },
  mounted() {
    this.loadMap();
    this.fetchMillsAggregatedPurchases();
    this.fetchOilpalmClusters(
        "https://firebasestorage.googleapis.com/v0/b/fps-engine.appspot.com/o/geojsons%2Fclusters.json?alt=media&token=0cf28a0a-a83a-4238-93c1-17c6306ec5fb"
    );
    if (JSON.parse(localStorage.getItem("first_time")) === true) {
      return;
    } else {
      this.$tours["myTour"].start();
      localStorage.setItem("first_time", JSON.stringify(true));
    }
  },
  computed: {
    serviceAreaStore() {
      return this.$store.getters["serviceAreaWY/getServiceAreaAttributes"];
    },
    updatedMills() {
      return this.$store.getters["mills/getFilteredMills"];
    },
    millsFilterStatus() {
      return this.$store.getters["mills/getFilterStatus"];
    },
    divideServiceAreaValue() {
      return Math.round(Number(this.serviceAreaValue));
    },
  },
  methods: {


    handleFilterToggle(status) {
      this.toggleFilterForm = status;
    },


    async fetchOilpalmClusters(url) {
      try {
        this.oilpalmClusters = await this.$store.dispatch(
            "oilpalmClusters/getOilPalmClusters",
            url
        );
      } catch (err) {
        console.log(err);
      }
    },

    // generateServiceArea() {
    //   console.log(this.params);
    // },

    toggleServiceArea() {
      this.saToggle = !this.saToggle;
      this.$emit("onToggle", this.saToggle);
    },

    showModal() {
      this.$refs["bv-modal-example"].show();
      this.$refs["bv-modal-example2"].show();
    },
    hideModal() {
      this.$refs["bv-modal-example"].hide();
      this.$refs["bv-modal-example2"].hide();
      // this.loading = false;
    },

    loadMap() {
      loadModules(
          [
            "esri/config",
            "esri/Map",
            "esri/views/MapView",
            "esri/rest/serviceArea",
            "esri/Graphic",
            "esri/rest/support/FeatureSet",
            "esri/rest/support/ServiceAreaParameters",
            "esri/widgets/Home",
            "esri/widgets/Search",
            "esri/layers/FeatureLayer",
            "esri/widgets/BasemapToggle",
            "esri/widgets/ScaleBar",
            "esri/widgets/Slider",
            "esri/widgets/FeatureForm",
            "esri/widgets/FeatureTemplates",
            "esri/rest/route",
            "esri/rest/support/RouteParameters",
            "esri/widgets/Directions",
            "esri/symbols/SimpleMarkerSymbol",
            "esri/rest/locator",
          ],
          {
            css: true,
          }
      ).then(
          ([
             esriConfig,
             ArcGISMap,
             MapView,
             serviceArea,
             Graphic,
             FeatureSet,
             ServiceAreaParams,
             Home,
             Search,
             FeatureLayer,
             BasemapToggle,
             ScaleBar,

           ]) => {
            esriConfig.apiKey = APIKeys;

            //OilPalmAll Cluster
            const palmoilclusterFL = new FeatureLayer({
              title: "Oil Palm Clusters",
              url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/palmclustersAllPrj/FeatureServer",
              opacity: 0.8,
              copyright: "",
              geometryType: "polygon",
              renderer: {
                type: "simple",
                symbol: {
                  type: "simple-fill",
                  // size: 15,
                  color: "#2a9d8f",
                  outline: {
                    color: "rgba(10, 41, 37, 10%)",
                    width: 0.2,
                  },
                },
              },
              popupTemplate: {
                title: "Oil Palm Cluster - {ha_area_1} (ha)",
                // symbol: symbol,
                content: [
                  {
                    type: "fields",
                    fieldInfos: [
                      {
                        fieldName: "ha_area_1",
                        label: "Area Size (ha)",
                        visible: true,
                      },
                      {
                        fieldName: "tones",
                        label: "Tonnes yield (T)",
                        visible: true,
                      },
                      {
                        fieldName: "WeeklyP1Yield",
                        label: "Weekly P1 Yield (T)",
                        visible: true,
                      },
                      {
                        fieldName: "WeeklyFFBYield",
                        label: "Weekly FFB Yield (T)",
                        visible: true,
                      },
                      {
                        fieldName: "Ages",
                        label: "Age",
                        visible: true,
                      },
                      {
                        fieldName: "BulkDens",
                        label: "Bulk Density",
                        visible: true,
                      },
                      {
                        fieldName: "layer",
                        label: "State",
                        visible: true,
                      },
                    ],
                  },
                ],
              },
            });

            this.cluster = palmoilclusterFL;
            // create 2D map with the given options
            this.map = new ArcGISMap({
              basemap: "arcgis-nova",
              ground: "world-elevation",
              layers: [],
            });

            // assign map to this view
            this.view = new MapView({
              container: this.$el,
              map: this.map,
              center: [7.9283, 5.0337], //Longitude, latitude
              zoom: 9.6,
            });

            //Adding basemap toggle
            const basemapToggle = new BasemapToggle({
              view: this.view,
              nextBasemap: "arcgis-imagery",
            });
            this.view.ui.add(basemapToggle, "bottom-right");


            //Add home button widget here
            const homeWidget = new Home({
              view: this.view,
            });
            this.view.ui.add(homeWidget, "top-left");
            //Add Search bar
            const searchWidget = new Search({view: this.view});
            this.view.ui.add(searchWidget, "top-left");


            //Scale Bar
            let scaleBar = new ScaleBar({
              view: this.view,
            });
            this.view.ui.add(scaleBar, {
              position: "bottom-left",
            });

            //service area implementation starts here
            function createGraphic(point, view) {
              view.graphics.removeAll();
              const graphic = new Graphic({
                geometry: point,
                symbol: {
                  type: "simple-marker",
                  color: "white",
                  size: 8,
                },
              });
              view.graphics.add(graphic);
              return graphic;
            }

            setTimeout(() => {
              this.$bvModal.show("bv-modal-example");

              this.view.on("click", (event) => {
                if (this.saToggle) {
                  this.showServiceAreaModal = this.saToggle;
                  let locationGraphic = createGraphic(event.mapPoint, this.view);

                  this.serviceArea = serviceArea;
                  this.serviceAreaParams = ServiceAreaParams;
                  this.locationGraphic = locationGraphic;
                  this.outSpatialReference = this.view.spatialReference;
                  this.FeatureSet = FeatureSet;
                  this.serviceAreaStartingPoint = {
                    latitude: event.mapPoint.latitude,
                    longitude: event.mapPoint.longitude,
                  };
                  // this.loading = false;
                }
              });
            }, 3000);

          }
      );
    },

    async fetchMillsAggregatedPurchases() {
      await getMillsAggregatedPurchases();
    },

    async fetchCurrentPrices() {
      this.$store.dispatch("mills/fetchCurrentP1Prices");
    },
  },
  watch: {
    serviceAreaStore(newValue) {
      if (newValue) {
        this.loading = false;
        this.$bvModal.show("bv-modal-example2");
      }
    },

    updatedMills(newValue) {
      this.view.graphics.removeAll();
      if (newValue) {
        const params = {
          mills: newValue,
          view: this.view,
        };
        this.$store.dispatch("mills/renderedFilteredMills", params);
      }
    },
    millsFilterStatus(newValue) {
      if (newValue) {
        this.view.graphics.removeAll();
      }
    },

    serviceAreaArgs(newValue) {
      this.params = newValue;
      console.log(newValue);
      this.loading = true;

      this.params["closeModal"] = this.hideModal;
      this.$store.dispatch("serviceAreaWY/drawServiceArea", this.params);
      this.params["divideServiceAreaValue"] = this.divideServiceAreaValue;
    },

    serviceAreaStartingPoint(newValue) {
      if (this.saToggle) {
        this.serviceAreaArgs = {
          divideServiceAreaValue: this.divideServiceAreaValue,
          outSpatialReference: this.outSpatialReference,
          view: this.view,
          serviceArea: this.serviceArea,
          locationGraphic: this.locationGraphic,
          serviceAreaStartingPoint: newValue,
        };
      }
    },
  },
};
</script>
<style scoped>


div .sa-list p.sa {
  font-weight: bold;
  font-size: 16px;
}

.esri-view .esri-directions {
  right: 15px;
  max-height: calc(100% - 45px) !important;
}

.btn-group .button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  /* position: absolute; */
  margin-left: 20px;
  margin-top: 10px;
  /* float: left; */
}

.btn-group .button:hover {
  background-color: #3e8e41;
}

.saDraw {
  position: absolute;
  display: block;
  top: 200px;
  right: 10px;
  z-index: 9980;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #669933;
}

input:focus + .slider {
  box-shadow: 0 0 1px #669933;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
  