<template>
  <div>
    <div
      class="saDraw"
      style="text-align: center; justify-content: center; align-items: center"
      v-b-tooltip.hover.right="'Add Asset'"
      data-v-step="2"
      @click="openCoordinateForm"
    ></div>

    <!-- Add Coordinate form -->
    <div
      v-if="showCoordinateForm"
      class="coordinate-form"
      style="font-size: 12px"
    >
      <CoordinateForm @onOpenSelectMarkerForm="onOpenSelectMarkerForm" />
    </div>

    <!-- Select Factories Marker form -->
    <div
      v-if="showSelectMarkerForm"
      class="marker-menu"
      style="font-size: 12px"
    >
      <SelectMarkerForm
        @onCloseSelectForm="onCloseSelectForm"
        @onSelectMarkerType="handleSelectedMarker"
      />
    </div>

    <!-- Add Marker Modal -->
    <div v-if="showAddMarkerModal" class="add-marker-modal">
      <AddMarkerForm
        @onCloseModal="handleCloseModal"
        :modalTitle="modalTitle"
      />
    </div>
    <div class="dark-overlay" v-if="showAddMarkerModal"></div>

    <!-- Update Marker Modal -->
    <div v-if="showUpdateMarkerModal" class="add-marker-modal">
      <UpdateMarkerForm
        @onCloseModal="handleCloseUpdateModal"
        :modalTitle="modalTitle"
        :markerToBeUpdated="markerToBeUpdated"
      />
    </div>
    <div class="dark-overlay" v-if="showUpdateMarkerModal"></div>
  </div>
</template>
  
<script>
import CoordinateForm from "./CoordinateForm.vue"
import SelectMarkerForm from "./SelectMarkerForm.vue";
import AddMarkerForm from "./AddMarkerForm.vue";
import UpdateMarkerForm from "./AddMarkerForm.vue";

export default {
  name: "AddRAsset",

  components: {
    CoordinateForm,
    SelectMarkerForm,
    AddMarkerForm,
    UpdateMarkerForm,
  },
  data() {
    return {
      addAssetToggle: false,
      showCoordinateForm: false,
      showSelectMarkerForm: false,
      showAddMarkerModal: false,
      showUpdateMarkerModal: false,
    };
  },
  methods: {
    toggleAddAsset() {
      this.addAssetToggle = !this.addAssetToggle;
      this.$emit("onToggle", this.addAssetToggle);
      // this.openCoordinateForm();
    },

    openCoordinateForm() {
      this.showCoordinateForm = !this.showCoordinateForm;
    },

    onCloseSelectForm(isOpen) {
      this.showSelectMarkerForm = isOpen;
    },

    handleSelectedMarker(selectedMarkerType) {
      console.log("selected maker: " + selectedMarkerType);
      this.selectedMarkerType = selectedMarkerType;
      this.showAddMarkerModal = true;
      this.modalTitle = this.selectedMarkerType;
      this.$store.dispatch("markers/setMarkerFormAction", "");
    },

    handleCloseModal(modalStatus) {
      this.showAddMarkerModal = modalStatus;
    },

    handleCloseUpdateModal(modalStatus) {
      this.showUpdateMarkerModal = modalStatus;
    },

    onOpenSelectMarkerForm(isOpen) {
      this.showCoordinateForm = isOpen;
      this.showSelectMarkerForm = true;
    },

    updateMarkerModalStatus(newValue) {
      this.showUpdateMarkerModal = newValue;
    },
  },
};
</script>
  
  <style scoped>
.add-icon {
  position: absolute;
  margin-right: 15px;
  margin-top: 370px;
  right: 0;
  /* top: 0; */
  background: #fff;
  color: rgba(57, 96, 18, 0.907);
  font-size: 40px;
  cursor: pointer;
}

/* side map icon */
.map-icon {
  background-color: #fff;
  padding: 10px;
  border-radius: 30%;
  position: absolute;
  bottom: 370px;
  right: 10px;
  z-index: 9980;
  cursor: pointer;
}
.map-icon:hover {
  transform: scale(1.15);
}

.coordinate-form {
  width: 10rem;
  border-radius: 5px;
  position: absolute;
  right: 80px;
  bottom: 400px;
  background-color: rgb(245, 244, 244);
  z-index: 999;
  border: 1px solid rgb(214, 210, 210);
}

.marker-menu {
  width: 10rem;
  border-radius: 5px;
  position: absolute;
  left: 90px;
  bottom: 200px;
  background-color: rgb(245, 244, 244);
  z-index: 999;
  border: 1px solid rgb(214, 210, 210);
}

.add-marker-modal {
  border: 1px solid #000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  max-width: 350px;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.saDraw {
  width: 2.15em;
  height: 2.15em;
  z-index: 9980;
  background: url("https://cdn.icon-icons.com/icons2/1875/PNG/512/additem_120286.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  display: block;
  top: 300px;
  left: 14px;
  z-index: 9980;
  padding-top: 5px;
  padding-left: 3px;
  padding-right: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #669933;
}
input:focus + .slider {
  box-shadow: 0 0 1px #669933;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}
.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.new-asset {
  background-color: #fff;
  color: rgb(8, 174, 30);
  border: 1px solid rgb(12, 175, 66);
  border-radius: 5px;
  width: 90px;
  padding: 5px;
  cursor: pointer;
  font-size: small;
}
</style>