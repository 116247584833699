export const forestCoverGainRenderer = {
    type: "class-breaks",
    field: "gain",
    legendOptions: {
      title: "Forest Cover Gain"
    },
    classBreakInfos: [
      {
        minValue: 0,
        maxValue: 6099,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(240, 113, 103, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "0 - 6, 099 ha"
      },
      {
        minValue: 6100,
        maxValue: 15738,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(254, 217, 183, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "6,100 - 15,738 ha"
      },
      {
        minValue: 15738,
        maxValue: 24559,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(253, 252, 220, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "15,738 - 24,559 ha"
      },
      {
        minValue: 24560,
        maxValue: 43436,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(0, 175, 185, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "24,560 - 43,436 ha"
      },
      {
        minValue: 43437,
        maxValue: 102000,
        symbol: {
            type: "simple-fill", //simple-marker
            color: "rgba(0, 129, 167, .7)",
            outline: {
              color: "grey",
              width: 0.2,
            },
          },
        label: "43,437 - 102,000 ha"
      },
    ]
  };