import {forestCoverGainRenderer} from "@/views/mills/data/forestCoverGainRenderer";
import {arcgisURL} from "@/config/api-config";

const forestCoverGainLayer = {
    title: "Forest Cover Gain",
    url: arcgisURL + "forestCover/FeatureServer",
    opacity: 0.8,
    copyright: "",
    geometryType: "polygon",
    renderer: forestCoverGainRenderer,
    popupTemplate: {
        title: "{ADM1_EN} State",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "ADM1_EN",
                        label: "State",
                        visible: true,
                    },
                    {
                        fieldName: "gain",
                        label: "Forest Cover Gain (ha)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}
export default forestCoverGainLayer;

