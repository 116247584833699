<template>
  <div>
    <div class="map-view-containers" data-v-step="1">
      <SceneViewDropdown
        :options="['Map View', '2D', '3D']"
        class="select"
        dropdownTitle="select map scene"
        @onInput="handleSelectedViews"
      />
    </div>
  </div>
</template>
<script>
import SceneViewDropdown from "../../components/map/SceneView.vue";

export default {
  name: "ToggleMap",
  components: {
    SceneViewDropdown,
  },
  methods: {
    handleSelectedViews(selectedView) {
      if (selectedView === "2D") {
        this.$router.push("/map/oilpalm");
      } else if (selectedView === "3D") {
        this.$router.push("/map/oilpalm3d");
      }
    },
  },

  // setup() {},
};
</script>

<style scoped>
.map-view-containers {
  position: absolute;
  top: 10px;
  right: 800px;
  z-index: 9980;
}
</style>
