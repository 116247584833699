<template>
  <div>
    <TopNav />
    <div class="dateForm" v-show="showDate">
      <p @click="showDateOff" class="exit">x</p>
      <p>Enter Date <small>(dd-mm-yyyy)</small></p>
      <input class="date" type="date" v-model="date" />
      <button @click="setDate" class="setDate">set</button>
    </div>

    <div v-if="agentsLoading">
      <Loader :loading="agentsLoading" />
    </div>

    <div v-else>
      <AddRAsset @onToggle="handleAddAssetToggle" />

      <!-- Service area toggle -->
      <ServiceAreaModal
        :serviceAreaArgs="serviceAreaArgs"
        ref="modal"
        id="v-step-0"
      />

      <div ref="viewDiv" id="viewDiv"></div>

      <!-- Toggle saDraw Button -->
      <ServiceAreaToggle :view="view" @onToggle="handleServiceAreaToggle" />
      <Loader :loading="loading" />
      <LightOverlay :loading="loading" />

      <!-- toggle between 2D and 3D -->
      <ToggleMap />

      <Admin />
      <P1Summary v-show="showP1Summary" :showP1Summary="showP1Summary" />
      <RFSummary v-show="showRFSummary" :showRFSummary="showRFSummary" />
      <CFSummary v-show="showCFSummary" :showCFSummary="showCFSummary" />
      <MillClusteredSummary
        v-show="showClusteredMillSummary"
        :showClusteredMillSummary="showClusteredMillSummary"
      />
      <WardSummary
        v-show="showWardsSummary"
        :showWardsSummary="showWardsSummary"
      />
      <NgnLGASummary
        v-show="showNgnLGASummary"
        :showNgnLGASummary="showNgnLGASummary"
      />
      <CPOTaggedReleafSummary
        v-show="showCPOTaggedReleafSummary"
        :showCPOTaggedReleafSummary="showCPOTaggedReleafSummary"
      />
      <OilpalmClusterSummary
        v-show="showOilpalmClusterSummary"
        :showOilpalmClusterSummary="showOilpalmClusterSummary"
      />
      <DeforestedOilpalmClusterSummary
        v-show="showDeforestedOilpalmClusterSummary"
        :showDeforestedOilpalmClusterSummary="
          showDeforestedOilpalmClusterSummary
        "
      />

      <!-- map -->
      <div ref="map" id="map"></div>
    </div>

    <v-tour name="myTour" :steps="steps"></v-tour>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { getMillsAggregatedPurchases } from "../../utils";
import ServiceAreaToggle from "../../components/service-area/ServiceAreaToggle.vue";
import ServiceAreaModal from "../../components/service-area/ServiceAreaModal.vue";
import Loader from "../../components/loader/Loader.vue";
import LightOverlay from "../../components/loader/LightOverlay.vue";
import ToggleMap from "../../components/map/ToggleMap.vue";
import { APIKeys } from "../../config/keys";
import { oilPalmClusterURL, reverseGeocoding } from "../../config/api-config";
import TopNav from "../../layouts/TopNav.vue";
import AddRAsset from "../../components/releaf-assets/AddRAsset.vue";
import Admin from "../../components/admin-dashboard/Admin.vue";
import tourSteps from "@/data/intro/tour-steps";
import miniGridLayer from "@/views/mills/widgets/layer-list/minigrids";
import releafTaggedMillsLayer from "@/views/mills/widgets/layer-list/releaf-tagged-mills";
import p1AtField from "@/views/mills/widgets/layer-list/P1-in-field";
import CPOMillsCluster from "@/views/mills/widgets/layer-list/cpo-mills-cluster";
import releafFactories from "@/views/mills/widgets/layer-list/releaf-factories";
import lgaSummaryLayer from "@/views/mills/widgets/layer-list/lga-summary";
import oilPalmClusterLayer from "@/views/mills/widgets/layer-list/oil-palm-cluster";
import protectedArea from "@/views/mills/widgets/layer-list/protected-area";
import buildingFootprintLayer from "@/views/mills/widgets/layer-list/building-footprint";
import { communityPriceProjectionLayer } from "@/views/mills/widgets/layer-list/community-price-projection";
import stateBoundaryLayer from "@/views/mills/widgets/layer-list/state-boundary";
import lgaBoundaryLayer from "@/views/mills/widgets/layer-list/lga-boundary";
import wardBoundaryLayer from "@/views/mills/widgets/layer-list/ward-boundary";
import wardTaggedMillsLayer from "@/views/mills/widgets/layer-list/ward-tagged-mills";
import wardTonnesSourcedLayer from "@/views/mills/widgets/layer-list/ward-tonnes-sourced";
import wardMillClusterLayer from "@/views/mills/widgets/layer-list/ward-mill-clusters";
import wardAverageP1SourcedLayer from "@/views/mills/widgets/layer-list/ward-averagep1-sourced";
import customerFactoriesLayer from "@/views/mills/widgets/layer-list/customer-factories";
import hcvaLayer from "@/views/mills/widgets/layer-list/high-conservation-value-areas";
import hcsLayer from "@/views/mills/widgets/layer-list/high-carbon-stocks";
import industrialParksLayer from "@/views/mills/widgets/layer-list/industrial-parks";
import p1AggregatesLayer from "@/views/mills/widgets/layer-list/p1-aggregates";
import logisticsDepotLayer from "@/views/mills/widgets/layer-list/logistics-depot";
import { layerListActions } from "@/views/mills/widgets/layer-list/layer-list-base";
import { searchActions } from "@/views/mills/widgets/layer-list/search-sources";
import forestCoverLossLayer from "@/views/mills/widgets/layer-list/forest-cover-loss";
import forestCoverGainLayer from "@/views/mills/widgets/layer-list/forest-cover-gain";
import protectedConservedArea from "@/views/mills/widgets/layer-list/protected-oilpalm-area";
import P1Summary from "@/components/layer-summary/p1-at-field-summary.vue";
import RFSummary from "@/components/layer-summary/releaf-factory-summary.vue";
import CFSummary from "@/components/layer-summary/customer-factory-summary.vue";
import CPOTaggedReleafSummary from "@/components/layer-summary/cpo-tagged-summary.vue";
import OilpalmClusterSummary from "@/components/layer-summary/oilpalm-clusters-summary.vue";
import DeforestedOilpalmClusterSummary from "@/components/layer-summary/deforestedoilpalm-clusters-summary.vue";
import NgnLGASummary from "@/components/layer-summary/nigeria-lga-summary.vue";
import WardSummary from "@/components/layer-summary/nigeria-ward-summary.vue";
import MillClusteredSummary from "@/components/layer-summary/cpo-millsCluster-summary.vue";
import oilpalmSustainabilityLayer from "@/views/mills/widgets/layer-list/oilpalm-sustainability";
import millsDistanceLayer from "@/views/mills/widgets/layer-list/mills-distance";

export default {
  name: "MyMap",
  components: {
    TopNav,
    Admin,
    ToggleMap,
    ServiceAreaToggle,
    ServiceAreaModal,
    Loader,
    LightOverlay,
    AddRAsset,
    P1Summary,
    RFSummary,
    CFSummary,
    CPOTaggedReleafSummary,
    OilpalmClusterSummary,
    NgnLGASummary,
    WardSummary,
    MillClusteredSummary,
    DeforestedOilpalmClusterSummary,
  },
  data() {
    return {
      myVariable: [],
      showP1Summary: false,
      showRFSummary: false,
      showCFSummary: false,
      showCPOTaggedReleafSummary: false,
      showOilpalmClusterSummary: false,
      showDeforestedOilpalmClusterSummary: false,
      showNgnLGASummary: false,
      showWardsSummary: false,
      showClusteredMillSummary: false,
      date: new Date().toISOString().substr(0, 10),
      futurePriceP1: [],
      currentPriceP1: [],
      showDate: false,
      showDeleteAssetPrompt: false,
      list: [],
      view: null,
      map: null,
      serviceArea: null,
      serviceAreaStartingPoint: {},
      serviceAreaParams: null,
      locationGraphic: null,
      outSpatialReference: null,
      FeatureSet: null,
      driveTimeCutoffs: [0, 0, 0],
      saToggle: false,
      addAssetToogle: false,
      toggleFilterForm: false,
      serviceAreaValue: 0,
      loading: false,
      mills: [],
      oilpalmClusters: [],
      cluster: {},
      serviceAreaArgs: {},
      showServiceAreaModal: false,
      steps: tourSteps,
      agentsLoading: false,
      user: null
    };
  },
  async mounted() {
    try {
      this.agentsLoading = true;
      this.user = this.$store.getters['auth/currentUser'];
      const response = await this.$store.dispatch("auth/fetchAgents");
      console.log(response);
      this.agentsLoading = false;
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
    this.loadMap();
    this.fetchMillsAggregatedPurchases();
    this.fetchOilpalmClusters(oilPalmClusterURL);

    if (!(JSON.parse(localStorage.getItem("first_time")) === true)) {
      this.$tours["myTour"].start();
      localStorage.setItem("first_time", JSON.stringify(true));
    }
  },

  beforeCreate() {
    this.$store.dispatch("inventory/fetchInventorySummaryPerAgent");
    this.$store.dispatch("inventory/fetchInventoryInField");
  },

  computed: {
    updatedMills() {
      return this.$store.getters["mills/getFilteredMills"];
    },
    millsFilterStatus() {
      return this.$store.getters["mills/getFilterStatus"];
    },
    divideServiceAreaValue() {
      return Math.round(Number(this.serviceAreaValue));
    },
    markers() {
      return this.$store.getters["markers/getAllMarkers"];
    },
  },

  methods: {
    showDateFx() {
      this.showDate = !this.showDate;
      this.show = !this.showDate;
    },

    showDateOff() {
      this.showDate = false;
    },
    setDate() {
      this.$store.dispatch("serviceArea/setFuturePrice", this.date);
      this.showDateOff();
    },

    handleServiceAreaToggle(status) {
      this.saToggle = status;
    },

    handleAddAssetToggle(status) {
      this.addAssetToggle = status;
      this.openCoordinateForm = status;
    },

    handleFilterToggle(status) {
      this.toggleFilterForm = status;
    },

    async fetchOilpalmClusters(url) {
      try {
        this.oilpalmClusters = await this.$store.dispatch(
          "oilpalmClusters/getOilPalmClusters",
          url
        );
      } catch (err) {
        console.log(err);
      }
    },

    showModal() {
      this.$refs["modal"].showModal();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    loadMap() {
      loadModules(
        [
          "esri/config",
          "esri/Map",
          "esri/views/MapView",
          "esri/rest/serviceArea",
          "esri/Graphic",
          "esri/rest/support/FeatureSet",
          "esri/rest/support/ServiceAreaParameters",
          "esri/widgets/Home",
          "esri/widgets/Search",
          "esri/widgets/LayerList",
          "esri/widgets/Expand",
          "esri/layers/FeatureLayer",
          "esri/widgets/BasemapToggle",
          "esri/widgets/ScaleBar",
          "esri/widgets/Slider",
          "esri/widgets/BasemapGallery",
          "esri/layers/GeoJSONLayer",
          "esri/widgets/Directions",
          "esri/symbols/SimpleMarkerSymbol",
          "esri/rest/locator",
          "esri/layers/GroupLayer",
        ],
        {
          css: true,
        }
      ).then(
        ([
          esriConfig,
          ArcGISMap,
          MapView,
          serviceArea,
          Graphic,
          FeatureSet,
          ServiceAreaParams,
          Home,
          Search,
          LayerList,
          Expand,
          FeatureLayer,
          BasemapToggle,
          ScaleBar,
          Slider,
          BasemapGallery,
          GeoJSONLayer,
          Directions,
          SimpleMarkerSymbol,
          locator,
          GroupLayer,
        ]) => {
          esriConfig.apiKey = APIKeys;

          // GeoJSONLayer layers
          const RFTaggedMills = new GeoJSONLayer(releafTaggedMillsLayer);
          const p1Aggregates = new GeoJSONLayer(p1AggregatesLayer);
          const rAssets = new GeoJSONLayer(releafFactories);
          const P1AtField = new GeoJSONLayer(p1AtField);
          const logisticsDepot = new GeoJSONLayer(logisticsDepotLayer);
          const customerFactories = new GeoJSONLayer(customerFactoriesLayer);
          const futurePriceLayer = new GeoJSONLayer(
            communityPriceProjectionLayer(this.date)
          );

          // FeatureLayer layers
          const oilpalmSustainability = new FeatureLayer(
            oilpalmSustainabilityLayer
          );
          const protetectedOilpalm = new FeatureLayer(protectedConservedArea);
          const miniGrids = new FeatureLayer(miniGridLayer);
          const RFTaggedMillsClustered = new FeatureLayer(CPOMillsCluster);
          const lgaOilPalm = new FeatureLayer(lgaSummaryLayer);
          const palmoilclusterFL = new FeatureLayer(oilPalmClusterLayer);
          const protectedAreas = new FeatureLayer(protectedArea);
          const buildingFP = new FeatureLayer(buildingFootprintLayer);
          const stateBoundary = new FeatureLayer(stateBoundaryLayer);
          const lgaBoundary = new FeatureLayer(lgaBoundaryLayer);
          const forestCoverLoss = new FeatureLayer(forestCoverLossLayer);
          const forestCoverGain = new FeatureLayer(forestCoverGainLayer);
          const wardBoundary = new FeatureLayer(wardBoundaryLayer);
          const wardTaggedMills = new FeatureLayer(wardTaggedMillsLayer);
          const wardTonnesSourced = new FeatureLayer(wardTonnesSourcedLayer);
          const wardMillClusters = new FeatureLayer(wardMillClusterLayer);
          const highConservationValueArea = new FeatureLayer(hcvaLayer);
          const highCarbonStocks = new FeatureLayer(hcsLayer);
          const industrialParks = new FeatureLayer(industrialParksLayer);
          const wardAverageP1Purchased = new FeatureLayer(
            wardAverageP1SourcedLayer
          );
          const millsDistance = new FeatureLayer(millsDistanceLayer);

          this.cluster = palmoilclusterFL;

          const lgaNest = new GroupLayer({
            title: "Nigeria LGA Layer",
            visible: true,
            visibilityMode: "exclusive",
            layers: [lgaOilPalm, lgaBoundary],
          });

          const wardNest = new GroupLayer({
            title: "Nigeria Ward Layer",
            visible: true,
            visibilityMode: "exclusive",
            layers: [
              wardAverageP1Purchased,
              wardMillClusters,
              wardTonnesSourced,
              wardTaggedMills,
              wardBoundary,
            ],
          });

          // Infrastructure
          const infrastructure = new GroupLayer({
            title: "Infrastructure",
            visible: true,
            visibilityMode: "exclusive",
            layers: [miniGrids, industrialParks, customerFactories],
          });

          // create 2D map with the given options
          this.map = new ArcGISMap({
            basemap: "arcgis-nova",
            layers: this.user.email === 'investor@releaf.ng' ? [
              RFTaggedMills,
              // stateBoundary,
              // lgaNest,
              // wardNest,
              // logisticsDepot,
              // p1Aggregates,
              // futurePriceLayer,
              // buildingFP,
              // highCarbonStocks,
              // highConservationValueArea,
              // forestCoverLoss,
              // forestCoverGain,
              // infrastructure,
              // RFTaggedMillsClustered,
              // millsDistance,
              rAssets,
              protectedAreas,
              palmoilclusterFL,
              protetectedOilpalm,
              // P1AtField,
              oilpalmSustainability,
            ]: 
            [
              RFTaggedMills,
              stateBoundary,
              lgaNest,
              wardNest,
              logisticsDepot,
              p1Aggregates,
              futurePriceLayer,
              buildingFP,
              highCarbonStocks,
              highConservationValueArea,
              forestCoverLoss,
              forestCoverGain,
              infrastructure,
              RFTaggedMillsClustered,
              millsDistance,
              rAssets,
              protectedAreas,
              palmoilclusterFL,
              protetectedOilpalm,
              P1AtField,
              oilpalmSustainability,
            ],
          });

          this.$store.dispatch("arcgisMap/saveLayers", this.map.layers._items);

          // assign map to this view
          this.view = new MapView({
            container: this.$el,
            map: this.map,
            center: [6.535123, 6.153788], //Longitude, latitude
            zoom: 7.6,
          });

          // Event handler that fires each time an action is clicked
          this.view.popup.on("trigger-action", (event) => {
            if (event.action.id === "edit-this") {
              this.showDateFx();
            }
          });

          //Adding basemap toggle
          const basemapToggle = new BasemapToggle({
            view: this.view,
            nextBasemap: "arcgis-imagery",
          });

          const layerList = new LayerList({
            view: this.view,
            listItemCreatedFunction: defineActions,
          });

          this.$store.dispatch("arcgisMap/saveView", this.view);

          //Add home button widget here
          let homeWidget = new Home({ view: this.view });

          //Add Search bar
          const searchItems = searchActions(rAssets, RFTaggedMills, APIKeys);
          let searchWidget = new Search({
            view: this.view,
            includeDefaultSources: false,
            sources: searchItems.searchComponents,
          });

          //Basemap gallery toggle
          let basemapGallery = new BasemapGallery({
            view: this.view,
          });
          const basemapGalleryExpand = new Expand({
            expandIconClass: "esri-icon-collection",
            view: this.view,
            content: basemapGallery,
            group: "top-left",
          });
          basemapGalleryExpand.expandTooltip = "Basemap gallery";

          //Add layer list expanded
          const layerListExpand = new Expand({
            expandIconClass: "esri-icon-layers",
            view: this.view,
            content: layerList,
            group: "top-left",
          });
          layerListExpand.expandTooltip = "Layers List";

          //Route and Direction begins here
          const routeDirection = new Directions({
            view: this.view,
            routeServiceUrl:
              "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World/",
          });

          const createStopSymbol = (outline, fill, size) => {
            return new SimpleMarkerSymbol({
              style: "cross",
              size: size,
              outline: {
                width: "2px",
                color: outline,
              },
              color: fill,
            });
          };
          // Stop symbols
          routeDirection.stopSymbols.first = createStopSymbol(
            "black",
            "white",
            "120px"
          );
          routeDirection.stopSymbols.middle = createStopSymbol(
            "grey",
            "white",
            "10px"
          );
          routeDirection.stopSymbols.last = createStopSymbol(
            "white",
            "black",
            "12px"
          );
          // Route symbol
          routeDirection.routeSymbol.width = "5";
          routeDirection.routeSymbol.color = [34, 131, 38, 0.8];
          routeDirection.routeSymbol.style = "solid";

          let weeklyP1YieldSlider; //oilPalm clusters slider - weeklyp1yield
          let p1LGASummarySlider; //p1 lga summary
          let oilPalmClusterSlider; //oilPalm clusters - age
          let buidingFootprintSlider; //building footprints - area
          let daysSinceLastDiscoverySlider; //cpo mills tagged by releaf - days from last discovery
          let tonnesBoughtTaggedMillsSlider; //cpo mills tagged by releaf - tons bought
          let numberOfTxnSlider; //cpo mills tagged by releaf - noOfTxn bought
          let aveP1PerMillSlider; //cpo mills cluster - averageP1PerMill
          let sliderTonnesAtField;
          let sliderDaysSincePurchase;

          let self = this;

          //function for layerlist
          async function defineActions(event) {
            const __ret = layerListActions(
              event,
              weeklyP1YieldSlider,
              Slider,
              oilPalmClusterSlider,
              p1LGASummarySlider,
              buidingFootprintSlider,
              daysSinceLastDiscoverySlider,
              tonnesBoughtTaggedMillsSlider,
              numberOfTxnSlider,
              aveP1PerMillSlider,
              sliderTonnesAtField,
              sliderDaysSincePurchase,
              pickupVehicleCheckboxes,
              agentsDropdownList,
              customerFactoryTypeFilter,
              customerFactoryTypeFilterReset,
              releafFactoryTypeFilter,
              releafFactoryTypeFilterReset,
              p1AtFieldReset,
              cpoTaggedMillsFilterReset,
              gtForDaySinceLastPurchase,
              ltForDaySinceLastPurchase,
              gtForTonnesPurchased,
              ltForTonnesPurchased,
              customerFactoryStatusFilter,
              releafFactoryStatusFilter,
              gtDaysSinceLastDiscovery,
              ltDaysSinceLastDiscovery,
              gtTonnesBoughtTaggedMills,
              ltTonnesBoughtTaggedMills,
              gtNumberOfTxn,
              ltNumberOfTxn,
              gtAveP1PerMill,
              ltAveP1PerMill,
              gtBuidingFootprint,
              ltBuidingFootprint,
              gtWeeklyP1Yield,
              ltWeeklyP1Yield,
              gtOilPalmCluster,
              ltOilPalmCluster,
              gtP1LGASummary,
              ltP1LGASummary,
              oilPalmClusterReset,
              handleShowP1Summary,
              handleReleafFactorySummary,
              handleCustomerFactorySummary,
              handleCPOTaggedReleafSummary,
              handleOilpalmClusterSummary,
              handlesNgnLGASummary,
              handlesWardsSummary,
              handleClusteredMillSummary,
              handleDeforestedOilpalmClusterSummary
            );

            weeklyP1YieldSlider = __ret.weeklyP1YieldSlider;
            oilPalmClusterSlider = __ret.oilPalmClusterSlider;
            p1LGASummarySlider = __ret.p1LGASummarySlider;
            buidingFootprintSlider = __ret.buidingFootprintSlider;
            daysSinceLastDiscoverySlider = __ret.daysSinceLastDiscoverySlider;
            tonnesBoughtTaggedMillsSlider = __ret.tonnesBoughtTaggedMillsSlider;
            numberOfTxnSlider = __ret.numberOfTxnSlider;
            aveP1PerMillSlider = __ret.aveP1PerMillSlider;
            sliderDaysSincePurchase = __ret.sliderDaysSincePurchase;
            sliderTonnesAtField = __ret.sliderTonnesAtField;
          }

          // Summary layer functions
          const handleShowP1Summary = () => {
            this.showP1Summary = !this.showP1Summary;
          };
          const handleReleafFactorySummary = () => {
            this.showRFSummary = !this.showRFSummary;
          };
          const handleCustomerFactorySummary = () => {
            this.showCFSummary = !this.showCFSummary;
          };
          const handleCPOTaggedReleafSummary = () => {
            this.showCPOTaggedReleafSummary = !this.showCPOTaggedReleafSummary;
          };
          const handleOilpalmClusterSummary = () => {
            this.showOilpalmClusterSummary = !this.showOilpalmClusterSummary;
          };
          const handleDeforestedOilpalmClusterSummary = () => {
            this.showDeforestedOilpalmClusterSummary =
              !this.showDeforestedOilpalmClusterSummary;
          };
          const handlesNgnLGASummary = () => {
            this.showNgnLGASummary = !this.showNgnLGASummary;
          };
          const handlesWardsSummary = () => {
            this.showWardsSummary = !this.showWardsSummary;
          };
          const handleClusteredMillSummary = () => {
            this.showClusteredMillSummary = !this.showClusteredMillSummary;
          };

          //filter Function for Releaf Factory Type
          function releafFactoryTypeFilter(selectedIndex) {
            self.view.whenLayerView(rAssets).then((layerView) => {
              applyReleafFactoryTypeFilter(layerView, selectedIndex);
            });
          }

          function applyReleafFactoryTypeFilter(layerView, selectedIndex) {
            const selectOption = selectedIndex
              .map((option) => `'${option}'`)
              .join(",");
            layerView.filter = {
              where: `type IN (${selectOption})`,
            };
          }

          //filter function for releaf factory status
          function releafFactoryStatusFilter(selectedIndex) {
            self.view.whenLayerView(rAssets).then((layerView) => {
              applyReleafFactoryStatusFilter(layerView, selectedIndex);
            });
          }

          function applyReleafFactoryStatusFilter(layerView, selectedIndex) {
            const field = "status";
            const radioLabels = ["Active", "Inactive", "Underconstruction"];
            layerView.filter = {
              where: field + " = '" + radioLabels[selectedIndex] + "'",
            };
          }

          //reset function for Releaf factory reset
          function releafFactoryTypeFilterReset(selectedIndex) {
            self.view.whenLayerView(rAssets).then((layerView) => {
              applyReleafFactoryTypeFilterReset(layerView, selectedIndex);
            });
          }

          function applyReleafFactoryTypeFilterReset(layerView, selectedIndex) {
            const field = "type";
            const radioLabels = [
              "Crushing Mill",
              "Refinery",
              "Deshelling Mill",
              "Kraken II",
            ];
            layerView.filter = {
              where: field + " <> '" + radioLabels[selectedIndex] + "'",
            };
          }

          //filter Function for Customer Factory Type
          function customerFactoryTypeFilter(selectedIndex) {
            self.view.whenLayerView(customerFactories).then((layerView) => {
              applyFactoryTypeFilter(layerView, selectedIndex);
            });
          }

          function applyFactoryTypeFilter(layerView, selectedIndex) {
            // const field = "type";
            const selectOption = selectedIndex
              .map((option) => `'${option}'`)
              .join(",");
            layerView.filter = {
              where: `type IN (${selectOption})`,
            };
          }

          //filter function for customer factory status
          function customerFactoryStatusFilter(selectedIndex) {
            self.view.whenLayerView(customerFactories).then((layerView) => {
              applyCustomerFactoryStatusFilter(layerView, selectedIndex);
            });
          }

          function applyCustomerFactoryStatusFilter(layerView, selectedIndex) {
            const field = "status";
            const radioLabels = ["Active", "Inactive", "Underconstruction"];
            layerView.filter = {
              where: field + " = '" + radioLabels[selectedIndex] + "'",
            };
          }

          //reset function for customer factory
          function customerFactoryTypeFilterReset(selectedIndex) {
            self.view.whenLayerView(customerFactories).then((layerView) => {
              applyFactoryTypeFilterReset(layerView, selectedIndex);
            });
          }

          function applyFactoryTypeFilterReset(layerView, selectedIndex) {
            const field = "type";
            const radioLabels = [
              "Crushing Mill",
              "Refinery",
              "Deshelling Mill",
              "Kraken II",
            ];
            layerView.filter = {
              where: field + " <> '" + radioLabels[selectedIndex] + "'",
            };
          }

          //fx for slider for daysSincePurchase in P1AtField
          function gtForDaySinceLastPurchase() {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              const field = "daysSincePurchase";
              sliderDaysSincePurchase.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtForDaySinceLastPurchase();

          function ltForDaySinceLastPurchase() {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              const field = "daysSincePurchase";
              sliderDaysSincePurchase.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //fx for slider for tonnes purchased in P1AtField
          function gtForTonnesPurchased() {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              const field = "tonnesPurchased";
              sliderTonnesAtField.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtForTonnesPurchased();

          function ltForTonnesPurchased() {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              const field = "tonnesPurchased";
              sliderTonnesAtField.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //Agents drop down function
          function agentsDropdownList(selectedIndex) {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              applyAgentFilter(layerView, selectedIndex);
            });
          }

          function applyAgentFilter(layerView, selectedIndex) {
            const field = "agentName";
            const agentsList = self.$store.getters["auth/getAgents"];
            layerView.filter = {
              where:
                "UPPER(" +
                field +
                ") = UPPER('" +
                agentsList[selectedIndex] +
                "')",
            };
          }

          function pickupVehicleCheckboxes() {
            const selectedCheckboxes = Array.from(
              document.querySelectorAll(
                'input[name="vehicleCheckboxGroup"]:checked'
              )
            );
            const selectedLabels = selectedCheckboxes.map(
              (checkbox) => checkbox.nextElementSibling.textContent
            );
            self.view.whenLayerView(P1AtField).then((layerView) => {
              applyVehicleFilter(layerView, selectedLabels);
            });
          }

          function applyVehicleFilter(layerView, checkboxValues) {
            const field = "recommendedPickupVehicle";
            const selectedOptions =
              checkboxValues.length > 0
                ? checkboxValues
                : getSelectedCheckboxValues();

            layerView.filter = {
              where: field + " IN ('" + selectedOptions.join("', '") + "')",
            };
          }

          function getSelectedCheckboxValues() {
            const checkboxes = document.querySelectorAll(
              'input[name="checkboxGroup"]'
            );
            return Array.from(checkboxes)
              .filter((checkbox) => checkbox.checked)
              .map((checkbox) => checkbox.nextElementSibling.textContent);
          }

          function p1AtFieldReset() {
            self.view.whenLayerView(P1AtField).then((layerView) => {
              const field = "recommendedPickupVehicle";
              layerView.filter = {
                where: field + " <> " + 1,
              };
            });
          }

          //> sign function - Oilplam cluster slider function - weeklyp1yield
          function gtWeeklyP1Yield() {
            self.view.whenLayerView(palmoilclusterFL).then((layerView) => {
              const field = "WeeklyP1Yield";
              weeklyP1YieldSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtWeeklyP1Yield();

          //< sign function - Oilplam cluster slider function - weeklyp1yield
          function ltWeeklyP1Yield() {
            self.view.whenLayerView(palmoilclusterFL).then((layerView) => {
              const field = "WeeklyP1Yield";
              weeklyP1YieldSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          function oilPalmClusterReset() {
            self.view.whenLayerView(palmoilclusterFL).then((layerView) => {
              const field = "ageInt";
              layerView.filter = {
                where: field + " <> " + 1,
              };
            });
          }

          //> sign function - Oilplam cluster slider function - age
          function gtOilPalmCluster() {
            self.view.whenLayerView(palmoilclusterFL).then((layerView) => {
              const field = "ageInt";
              oilPalmClusterSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtOilPalmCluster();

          //< sign function - Oilplam cluster slider function - age
          function ltOilPalmCluster() {
            self.view.whenLayerView(palmoilclusterFL).then((layerView) => {
              const field = "ageInt";
              oilPalmClusterSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //> sign function - Oil summary layer slider function
          function gtP1LGASummary() {
            self.view.whenLayerView(lgaOilPalm).then((layerView) => {
              const field = "avgPricePe";
              p1LGASummarySlider.on(["thumb-change", "thumb-drag"], (event) => {
                layerView.filter = {
                  where: field + " >= " + event.value,
                };
              });
            });
          }

          gtP1LGASummary();

          //< sign function - Oil summary layer slider function
          function ltP1LGASummary() {
            self.view.whenLayerView(lgaOilPalm).then((layerView) => {
              const field = "avgPricePe";
              p1LGASummarySlider.on(["thumb-change", "thumb-drag"], (event) => {
                layerView.filter = {
                  where: field + " <= " + event.value,
                };
              });
            });
          }

          //> Building footprints layer slider function
          function gtBuidingFootprint() {
            self.view.whenLayerView(buildingFP).then((layerView) => {
              const field = "area_in_me";
              buidingFootprintSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtBuidingFootprint();

          //< Building footprints layer slider function
          function ltBuidingFootprint() {
            self.view.whenLayerView(buildingFP).then((layerView) => {
              const field = "area_in_me";
              buidingFootprintSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //Reset function for CPO tagged mills
          function cpoTaggedMillsFilterReset() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "daysFromLastDiscovery";
              layerView.filter = {
                where: field + " <> " + 1,
              };
            });
          }

          //> sign daysFromLastDiscovery -cpo mills tagged by releaf layer slider function
          function gtDaysSinceLastDiscovery() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "daysFromLastDiscovery";
              daysSinceLastDiscoverySlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtDaysSinceLastDiscovery();

          //< sign daysFromLastDiscovery -cpo mills tagged by releaf layer slider function
          function ltDaysSinceLastDiscovery() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "daysFromLastDiscovery";
              daysSinceLastDiscoverySlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //> sign tonsAmount cpo mills tagged by releaf layer slider function
          function gtTonnesBoughtTaggedMills() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "tonsAmount";
              tonnesBoughtTaggedMillsSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " >= " + event.value,
                  };
                }
              );
            });
          }

          gtTonnesBoughtTaggedMills();

          //< sign tonsAmount cpo mills tagged by releaf layer slider function
          function ltTonnesBoughtTaggedMills() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "tonsAmount";
              tonnesBoughtTaggedMillsSlider.on(
                ["thumb-change", "thumb-drag"],
                (event) => {
                  layerView.filter = {
                    where: field + " <= " + event.value,
                  };
                }
              );
            });
          }

          //> noOfTransactions - cpo mills tagged by releaf layer slider function
          function gtNumberOfTxn() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "noOfTransactions";
              numberOfTxnSlider.on(["thumb-change", "thumb-drag"], (event) => {
                layerView.filter = {
                  where: field + " >= " + event.value,
                };
              });
            });
          }

          gtNumberOfTxn();

          //< noOfTransactions - cpo mills tagged by releaf layer slider function
          function ltNumberOfTxn() {
            self.view.whenLayerView(RFTaggedMills).then((layerView) => {
              const field = "noOfTransactions";
              numberOfTxnSlider.on(["thumb-change", "thumb-drag"], (event) => {
                layerView.filter = {
                  where: field + " <= " + event.value,
                };
              });
            });
          }

          //> AveP1PerMill - cpo clustered mills tagged by releaf layer slider function
          function gtAveP1PerMill() {
            self.view
              .whenLayerView(RFTaggedMillsClustered)
              .then((layerView) => {
                const field = "aveP1PMill";
                aveP1PerMillSlider.on(
                  ["thumb-change", "thumb-drag"],
                  (event) => {
                    layerView.filter = {
                      where: field + " >= " + event.value,
                    };
                  }
                );
              });
          }

          gtAveP1PerMill();

          //< AveP1PerMill - cpo clustered mills tagged by releaf layer slider function
          function ltAveP1PerMill() {
            self.view
              .whenLayerView(RFTaggedMillsClustered)
              .then((layerView) => {
                const field = "aveP1PMill";
                aveP1PerMillSlider.on(
                  ["thumb-change", "thumb-drag"],
                  (event) => {
                    layerView.filter = {
                      where: field + " <= " + event.value,
                    };
                  }
                );
              });
          }

          //Scale Bar
          let scaleBar = new ScaleBar({
            view: this.view,
          });

          //service area implementation starts here
          function createGraphic(point, view) {
            const graphic = new Graphic({
              geometry: point,
              symbol: {
                type: "simple-marker",
                color: "white",
                size: 8,
              },
            });
            view.graphics.add(graphic);
            return graphic;
          }

          this.view.on("click", (event) => {
            // for reverse geocoding
            //for putting long lat in coordinate form
            const serviceUrl = reverseGeocoding;

            const params = {
              location: event.mapPoint,
            };
            locator.locationToAddress(serviceUrl, params).then(
              (response) => {
                const address = response.address;
                const locationXY = {
                  x: response.location.longitude,
                  y: response.location.latitude,
                };
                this.$store.dispatch("serviceArea/setServiceAreaLGA", address);
                this.$store.dispatch(
                  "serviceArea/setServiceAreaXY",
                  locationXY
                );
              },
              function (err) {
                // Show no address found
                console.log(err.message);
              }
            );
          });
          this.view.on("click", (event) => {
            if (this.saToggle) {
              this.showModal();
              this.showServiceAreaModal = this.saToggle;
              const locationGraphic = createGraphic(event.mapPoint, this.view);
              this.serviceArea = serviceArea;
              this.serviceAreaParams = ServiceAreaParams;
              this.locationGraphic = locationGraphic;
              this.outSpatialReference = this.view.spatialReference;
              this.FeatureSet = FeatureSet;
              this.serviceAreaStartingPoint = {
                latitude: event.mapPoint.latitude,
                longitude: event.mapPoint.longitude,
              };
            }
          });

          this.view.ui.add(homeWidget, "top-left");
          this.view.ui.add(searchWidget, "top-left");
          this.view.ui.add(basemapGalleryExpand, "top-left");
          this.view.ui.add(layerListExpand, "top-left");
          this.view.ui.add(
            new Expand({
              view: this.view,
              content: routeDirection,
              expandIconClass: "esri-icon-north-navigation",
              expanded: false,
              expandTooltip: "Route and Directions",
            }),
            "top-left"
          );

          this.view.ui.add(basemapToggle, "bottom-right");
          this.view.ui.add(scaleBar, {
            position: "bottom-left",
          });
        }
      );
    },

    async fetchMillsAggregatedPurchases() {
      await getMillsAggregatedPurchases();
    },

    async fetchCurrentPrices() {
      await this.$store.dispatch("mills/fetchCurrentP1Prices");
    },
  },
  watch: {
    markers(newValue) {
      if (newValue) {
        console.log(newValue);
        this.loadMap();
      }
    },

    deleteAssetPromptStatus(newValue) {
      console.log(newValue);
      this.showDeleteAssetPrompt = newValue;
    },

    updatedMills(newValue) {
      this.view.graphics.removeAll();
      if (newValue) {
        const params = {
          mills: newValue,
          view: this.view,
        };
        this.$store.dispatch("mills/renderedFilteredMills", params);
      }
    },
    millsFilterStatus(newValue) {
      if (newValue) {
        this.view.graphics.removeAll();
      }
    },

    serviceAreaStartingPoint(newValue) {
      if (this.saToggle) {
        this.serviceAreaArgs = {
          divideServiceAreaValue: this.divideServiceAreaValue,
          outSpatialReference: this.outSpatialReference,
          view: this.view,
          serviceArea: this.serviceArea,
          locationGraphic: this.locationGraphic,
          serviceAreaStartingPoint: newValue,
        };
      }
    },
  },
};
</script>

<style scoped>
div .sa-list p.sa {
  font-weight: bold;
  font-size: 16px;
}

.esri-view .esri-directions {
  right: 15px;
  max-height: calc(100% - 45px) !important;
}

.btn-group .button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 10px;
}

.btn-group .button:hover {
  background-color: #3e8e41;
}

.date {
  margin-top: 10px;
}

.dateForm {
  width: 200px;
  padding: 10px;
  padding-bottom: 20px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgb(0 0 0 / 80%);
}

.dateForm .exit {
  cursor: pointer;
  text-align: right;
  margin-bottom: 5px;
}

input[type="date"] {
  border: 2px solid green;
  border-radius: 4px;
}

.setDate {
  background-color: #4caf50; /* Green */
  border: solid 2px #3e8e41;
  color: white;
  padding: 8px 25px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
}
</style>
