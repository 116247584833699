<template>
  <div class="dropdown" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
      tabindex="1"
    >
      <input
        type="text"
        name=""
        id=""
        v-model="selected"
        :placeholder="dropdownTitle"
      />
    </div>
    <div
      :class="[{ selectHide: !open }, location ? 'location' : 'items']"
      @mouseleave="closeOptions"
    >
      <div
        v-for="(option, i) of dropdownOptions"
        :key="i"
        @click="handleSelect(option, dropdownTitle)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { camelize } from "../../helpers/index";

export default {
  name: "SelectDropdown",

  props: {
    options: {
      type: Array,
      required: true,
    },
    dropdownTitle: {
      type: String,
      required: true,
      // default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputValue: {
      type: String,
      required: false,
      default: "",
    },
    isLocation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.inputValue,
      open: false,
      isReset: this.reset,
      dropdownOptions: this.options,
      location: this.isLocation,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },

  methods: {
    handleSelect(option) {
      this.isReset = false;
      this.selected = option;
      this.open = false;

      // emit selected option to the form
      this.$emit("onInput", {
        [camelize(this.dropdownTitle.toLowerCase())]: this.selected,
      });

      // emit updated reset value to the form
      this.$emit("onReset", this.isReset);
    },

    closeOptions() {
      this.open = false;
    },
  },

  watch: {
    reset(newValue) {
      if (newValue) this.selected = null;
    },

    selected(newValue) {
      if (newValue) {
        this.dropdownOptions = this.dropdownOptions.filter(
          (el) => el.toLowerCase().search(newValue.toLowerCase()) !== -1
        );
      } else {
        this.$emit("onInput", {
          [camelize(this.dropdownTitle.toLowerCase())]: this.selected,
        });
        this.dropdownOptions = this.options;
      }
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff;
  border-radius: 6px;
  border: 1.5px solid #669933;
  color: #669933;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  width: 100%;
  line-height: 35px;
  font-size: 0.8rem;
  position: relative;
}

input {
  border: none;
  outline: none;
  width: 95%;
}

.selected.open {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 17px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #669933 transparent transparent transparent;
}

.selected:focus p {
  /* position: absolute; */
  top: -0.5rem;
  left: 0.8rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 10;
}

.items,
.location {
  color: rgb(196, 196, 196);
  border-radius: 0px 0px 6px 6px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #669933;
  border-left: 1px solid #669933;
  border-bottom: 1px solid #669933;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0.4rem;
}

.location {
  height: 80px;
}

.items {
  height: auto;
}

.items div {
  color: #000;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  border-bottom: 1px solid #669933;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.items div:last-of-type {
  border-bottom: 0px;
}

.items div:hover {
  background-color: #fff;
}

.location div {
  color: #000;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  border-bottom: 1px solid #669933;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.location div:last-of-type {
  border-bottom: 0px;
}

.location div:hover {
  background-color: #fff;
}

.selectHide {
  display: none;
}
</style>
