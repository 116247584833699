import firebase from "../../config/firebase";
import router from "../../router";
import axios from 'axios';
import {ID_SERVICE_BASE_URL} from "@/config/api-config";

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  userId: "", // this should be changed to an empty string
  uid: "", //  config uid for currently logged in user
  currentUser: null,

  loading: false,
  error: false,
  authenticated: "",
  isSignoutPrompt: false,
  agents: [], // The array to store agents' names

};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.authenticated,
  currentUser: (state) => state.currentUser,
  signoutPrompt: (state) => state.isSignoutPrompt,
  getAgents: (state) => state.agents,
};

const mutations = {
  setUser(state, data) {
    state.currentUser = data;
  },

  setSignoutPromptStatus(state, status) {
    state.isSignoutPrompt = status;
  },

  SET_AGENTS(state, agents) {
    state.agents = agents;
  },
};

const actions = {
  setCurrentUser({ commit }, user) {
    if (user) {
      commit("setUser", user);
    } else {
      commit("setUser", null);
    }
  },

  setSignoutPromptStatus({ commit }, status) {
    commit("setSignoutPromptStatus", status);
  },

  async logout() {
    try {
      await firebase.auth().signOut();
    } catch (e) {
      console.log("Error while logging out:", e.message);
    }
    if (router.currentRoute.name !== "Login") {
      return router.push({ name: "Login" });
    } else {
      return router.go(0);
    }
  },

  async resetPassword(_, email) {
    
    // console.log("resetPassword", email);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        console.log(res, "res")
        
      })
      .catch((err) => console.log(err));
    
  },

  async fetchAgents({ commit }) {
    try {
      const response = await axios.get(ID_SERVICE_BASE_URL + "/user/agents");
      const agents = response.data.data.map((agent) => agent.first_name + " " + agent.last_name);
      agents.sort();
      console.log("Agents1", agents);
      commit('SET_AGENTS', agents);
      return response;
    } catch (error) {
      throw error;
    }
  }


};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
