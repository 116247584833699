<template>
  <div>
    <div>

      <b-modal id="bv-modal-example" hide-footer>
        <div class="d-block text-center">
          <h3>
            Click on the grey colored features on the map for more details
          </h3>
        </div>
        <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
          >Close Me</b-button
        >
      </b-modal>
    </div>

    <!-- map -->
    <div ref="map" id="map"></div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";

import { APIKeys } from "../../config/keys";

export default {
  name: "MyMap",
  components: {

  },
  data() {
    return {
      list: [],
      view: null,
      map: null,
      serviceArea: null,
      serviceAreaStartingPoint: {},
      serviceAreaParams: null,
      locationGraphic: null,
      outSpatialReference: null,
      FeatureSet: null,
      driveTimeCutoffs: [0, 0, 0],
      saToggle: false,
      toggleFilterForm: false,
      serviceAreaValue: 60,
      params: {},
      me: true,
      loading: false,
      mills: [],
      oilpalmClusters: [],
      cluster: {},
      serviceAreaArgs: {},
      showServiceAreaModal: false,
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "Welcome!",
          },
          content: `Explore Site in easy steps <br><strong>Take a quick tour.</strong>`,
        },
        {
          target: '[data-v-step="1"]',
          content: "Switch between map views.",
        },
        {
          target: '[data-v-step="2"]',
          content: "Explore areas to setup your factory.",
        },
        {
          target: '[data-v-step="3"]',
          content: "Switch between commodities.",
        },
        {
          target: '[data-v-step="5"]',
          content: "Slide to explore yield data.",
        },
        {
          target: '[data-v-step="6"]',
          content: "Edit assets with pen tool.",
        },
        {
          target: '[data-v-step="7"]',
          content: "Filter tagged mills with properties",
        },
      ],
    };
  },
  mounted() {
    this.loadMap();

  },
  updated() {},



  computed: {
    updatedMills() {
      return this.$store.getters["mills/getFilteredMills"];
    },
    millsFilterStatus() {
      return this.$store.getters["mills/getFilterStatus"];
    },
    divideServiceAreaValue() {
      return Math.round(Number(this.serviceAreaValue));
    },
  },
  methods: {


    showModal() {
      this.$refs["bv-modal-example"].show();
    },
    hideModal() {
      this.$refs["bv-modal-example"].hide();
    },

    loadMap() {
      loadModules(
        [
          "esri/config",
          "esri/Map",
          "esri/views/MapView",
          "esri/rest/serviceArea",
          "esri/Graphic",
          "esri/rest/support/FeatureSet",
          "esri/rest/support/ServiceAreaParameters",
          "esri/widgets/Home",
          "esri/widgets/Search",
          "esri/widgets/LayerList",
          "esri/layers/FeatureLayer",
          "esri/widgets/BasemapToggle",
          "esri/widgets/ScaleBar",
          "esri/widgets/Legend",
        ],
        {
          css: true,
        }
      ).then(
        ([
          esriConfig,
          ArcGISMap,
          MapView,
          serviceArea,
          Graphic,
          FeatureSet,
          ServiceAreaParams,
          Home,
          Search,
          LayerList,
          FeatureLayer,
          BasemapToggle,
          ScaleBar,
        ]) => {
          esriConfig.apiKey = APIKeys;

          //OilPalmAll Cluster
          const protectedAreas = new FeatureLayer({
            title: "Protected Areas",
            url: "https://services7.arcgis.com/BUm6cUlpmELYL3L9/arcgis/rest/services/protectedAreasWA/FeatureServer",
            opacity: 0.5,
            copyright: "",
            geometryType: "polygon",
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-fill",
                // size: 15,
                color: "#8d99ae",
                outline: {
                  color: "rgba(10, 41, 37, 10%)",
                  width: 0.2,
                },
              },
            },
            popupTemplate: {
              title: "Protected Areas - {NAME}",
              // symbol: symbol,
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "NAME",
                      label: "Name",
                      visible: true,
                    },
                    {
                      fieldName: "DESIG_ENG",
                      label: "Designation",
                      visible: true,
                    },
                    {
                      fieldName: "DESIG_TYPE",
                      label: "Designation Type",
                      visible: true,
                    },
                    {
                      fieldName: "STATUS",
                      label: "Status",
                      visible: true,
                    },
                    {
                      fieldName: "GOV_TYPE",
                      label: "Governemnt Type",
                      visible: true,
                    },
                    {
                      fieldName: "OWN_TYPE",
                      label: "Owner",
                      visible: true,
                    },
                  ],
                },
              ],
            },
          });

          // create 2D map with the given options
          this.map = new ArcGISMap({
            basemap: "arcgis-nova",
            ground: "world-elevation",
            layers: [protectedAreas],
          });

          // assign map to this view
          this.view = new MapView({
            container: this.$el,
            map: this.map,
            center: [6.535123, 6.153788], //Longitude, latitude
            zoom: 7.6,
          });

          //Adding basemap toggle
          const basemapToggle = new BasemapToggle({
            view: this.view,
            nextBasemap: "arcgis-imagery",
          });
          this.view.ui.add(basemapToggle, "bottom-right");

          //layerlist
          const layerList = new LayerList({
            view: this.view,
            listItemCreatedFunction: (event) => {
              const item = event.item;
              if (item.title === "Protected Areas") {
                // open the list item in the LayerList
                item.visible = true;
              }
              if (item.layer.type != "group") {
                // don't show legend twice
                item.panel = {
                  content: "legend",
                  open: false,
                  visible: true,
                };
              }
            },
          });

          //Add home button widget here
          let homeWidget = new Home({
            view: this.view,
          });
          this.view.ui.add(homeWidget, "top-left");
          //Add Search bar
          let searchWidget = new Search({ view: this.view });
          this.view.ui.add(searchWidget, "top-left");


          this.view.ui.add(layerList, {
            position: "bottom-left",
          });

          //Scale Bar
          let scaleBar = new ScaleBar({
            view: this.view,
          });
          this.view.ui.add(scaleBar, {
            position: "bottom-left",
          });

          //service area implementation starts here
          function createGraphic(point, view) {
            view.graphics.removeAll();
            const graphic = new Graphic({
              geometry: point,
              symbol: {
                type: "simple-marker",
                color: "white",
                size: 8,
              },
            });
            view.graphics.add(graphic);
            return graphic;
          }

          setTimeout(() => {
            this.$bvModal.show("bv-modal-example");
          }, 3000);

          // this.view.on("click", (event) => {
          if (!this.saToggle) {
            this.showServiceAreaModal = this.saToggle;
            let locationGraphic = createGraphic(event.mapPoint, this.view);

            this.serviceArea = serviceArea;
            this.serviceAreaParams = ServiceAreaParams;
            this.locationGraphic = locationGraphic;
            this.outSpatialReference = this.view.spatialReference;
            this.FeatureSet = FeatureSet;
            this.serviceAreaStartingPoint = {
              latitude: event.mapPoint.latitude,
              longitude: event.mapPoint.longitude,
            };
            this.params["divideServiceAreaValue"] = this.divideServiceAreaValue;
          }
          // });

        }
      );
    },


  },
  watch: {
    serviceAreaStartingPoint(newValue) {
      if (!this.saToggle) {
        this.serviceAreaArgs = {
          divideServiceAreaValue: this.divideServiceAreaValue,
          outSpatialReference: this.outSpatialReference,
          view: this.view,
          serviceArea: this.serviceArea,
          locationGraphic: this.locationGraphic,
          serviceAreaStartingPoint: newValue,
        };
      }
    },

    serviceAreaArgs(newValue) {
      this.params = newValue;
      console.log(newValue);
      this.loading = true;

      // this.params["closeModal"] = this.hideModal;
      this.$store.dispatch("serviceAreaWidget/drawServiceArea", this.params);
    },


  },
};
</script>
<style scoped>

div .sa-list p.sa {
  font-weight: bold;
  font-size: 16px;
}

.esri-view .esri-directions {
  right: 15px;
  max-height: calc(100% - 45px) !important;
}


.btn-group .button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  /* position: absolute; */
  margin-left: 20px;
  margin-top: 10px;
  /* float: left; */
}

.btn-group .button:hover {
  background-color: #3e8e41;
}
</style>
