import {arcgisURL} from "@/config/api-config";

const protectedArea = {
    title: "Deforestation Protected Areas",
    url: arcgisURL + "protectedAreasWA/FeatureServer",
    opacity: 0.5,
    copyright: "",

    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            color: "#8d99ae",
            outline: {
                color: "rgba(10, 41, 37, 10%)",
                width: 0.2,
            },
        },
    },
    popupTemplate: {
        title: "Deforestation Protected Areas - {NAME}",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "NAME",
                        label: "Name",
                        visible: true,
                    },
                    {
                        fieldName: "DESIG_ENG",
                        label: "Designation",
                        visible: true,
                    },
                    {
                        fieldName: "DESIG_TYPE",
                        label: "Designation Type",
                        visible: true,
                    },
                    {
                        fieldName: "STATUS",
                        label: "Status",
                        visible: true,
                    },
                    {
                        fieldName: "GOV_TYPE",
                        label: "Governemnt Type",
                        visible: true,
                    },
                    {
                        fieldName: "OWN_TYPE",
                        label: "Owner",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default protectedArea;

