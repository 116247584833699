import {tmsd} from "@/views/mills/data/featureLayersDetails";
import {arcgisURL} from "@/config/api-config"
import {clusterConfig} from "@/views/mills/clusterConfig";

const CPOMillsCluster = {
    title: "PIND Mills",
    url: arcgisURL + "taggedMillsClus/FeatureServer",
    renderer: tmsd.renderer,
    featureReduction: clusterConfig,
    popupTemplate: {
        title: "{communitie}",
        expressionInfos: [
            {
                name: "averageP1Mill",
                title: "Average P1/Mill",
                expression: `
                  function calcAverageP1Mill(){
                    var averageP1Mill = $feature.totalTons / $feature["no_of_mill"]
                    return averageP1Mill
                  }
                  calcAverageP1Mill();
                `
            },
        ],
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "communitie",
                        label: "Mills Within",
                        visible: true,
                    },
                    {
                        fieldName: "no_of_mill",
                        label: "Number of Mills",
                        visible: true,
                    },
                    {
                        fieldName: "totalPrice",
                        label: "Total Amount Spent (₦)",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                    {
                        fieldName: "totalTons",
                        label: "Tonnes Bought",
                        visible: true,
                    },
                    {
                        fieldName: "no_of_tran",
                        label: "Number of Transactions",
                        visible: true,
                    },
                    {
                        fieldName: "expression/averageP1Mill",
                        visible: true,
                        format: {
                            places: 2,
                            digitSeparator: true,
                        },
                    },
                ],
            },
        ],
    },
}


export default CPOMillsCluster;


export function showCPOMillClusters(eventItem, aveP1PerMillSlider, Slider, gtAveP1PerMill, ltAveP1PerMill, handleClusteredMillSummary) {
    eventItem.visible = false;

    const divSummaryButton = document.createElement("div");
    divSummaryButton.className = "btn-summary";
    divSummaryButton.innerText = "Show Layer Summary";
    divSummaryButton.addEventListener("click", ()=>{
        handleClusteredMillSummary()
    })


    aveP1PerMillSlider = new Slider({
        min: 0,
        max: 50,
        values: [0],
        precision: 0,
        visibleElements: {
            labels: true,
            rangeLabels: true,
        },
    });

    //sign function - DaysSinceLastDiscovery
    let switch1 = document.createElement("label");
    let input1 = document.createElement("input");
    let span1 = document.createElement("span")
    switch1.className = "switch";
    input1.type = "checkbox";
    span1.className = "slider round"
    switch1.appendChild(input1);
    switch1.appendChild(span1);
    input1.checked = true;

    input1.addEventListener("change", (event) => {
        if (event.target.checked) {
            gtAveP1PerMill();
        } else {
            ltAveP1PerMill();
        }
    });
    eventItem.panel = {
        content: [
            "legend",
            "Toggle off the switch to reverse the slider function to '< less than function'.",
            "&nbsp;",
            "Average P1/Mill (T)",
            "&nbsp;",
            "&nbsp;",
            switch1,
            "&nbsp;",
            aveP1PerMillSlider,
            "&nbsp;",
            divSummaryButton
        ],
        className: "esri-icon-sliders-horizontal",
        title: "Layer Properties",
    };
    return aveP1PerMillSlider;
}