import {arcgisURL} from "@/config/api-config";

const lgaBoundaryLayer = {
    title: "Nigeria LGA Boundaries",
    url: arcgisURL + "ngnLGA/FeatureServer",
    opacity: 0.8,
    copyright: "",
    labelingInfo: [{  // autocasts as new LabelClass()
        symbol: {
            type: "text",
            color: "red",
            font: {
                family: "Orbitron",
                size: 12,
                weight: "bold"
            }
        },
        labelExpressionInfo: {
            expression: "$feature.ADM2_EN"
        },
        labelPlacement: "center-along",
        repeatLabel: true,
        repeatDistanceLabel: 100
    }],
    geometryType: "polygon",
    renderer: {
        type: "simple",
        symbol: {
            type: "simple-fill",
            color: "rgba(153, 153, 102, 0.6)",
            outline: {
                color: "rgba(255, 255, 255, 0.6)",
                width: 1.5,
            },
        },
    },
    popupTemplate: {
        title: "{ADM2_EN}, {ADM1_EN} State",
        content: [
            {
                type: "fields",
                fieldInfos: [
                    {
                        fieldName: "ADM2_EN",
                        label: "LGA",
                        visible: true,
                    },
                    {
                        fieldName: "comCoun",
                        label: "Number of Community",
                        visible: true,
                    },
                    {
                        fieldName: "wardCount",
                        label: "Number of Wards",
                        visible: true,
                    },
                ],
            },
        ],
    },
}

export default lgaBoundaryLayer;

